<template>
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0">Orders</h1>
          </div><!-- /.col -->
          <div class="col-sm-6"> 
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <section class="content text-sm">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                <!-- general form elements -->
                    <div class="card card-primary">
                        <div class="card-header">
                            <h3 class="card-title">Search</h3>
                        </div>
                        <form method="post" @submit.prevent="submitSearchForm()">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-2">
                                        <div class="form-group">
                                            <label for="">GROUP ORDER NO.</label>
                                            <input type="text" class="form-control form-control-sm" v-model="searchFrm.group_order_no">
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="form-group">
                                            <label for="">FOLIO NO.</label>
                                            <input type="text" class="form-control form-control-sm" v-model="searchFrm.folio_no">
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="form-group">
                                            <label for="">CAN NO.</label>
                                            <input type="text" class="form-control form-control-sm" v-model="searchFrm.can_no">
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="form-group">
                                            <label for="">ANY HOLDER'S PAN</label>
                                            <input type="text" class="form-control form-control-sm" v-model="searchFrm.pan_no">
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="form-group">
                                            <label for="">1ST HOLDER NAME</label>
                                            <input type="text" class="form-control form-control-sm" v-model="searchFrm.primary_holders_name">
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="form-group">
                                            <label for="">1ST HOLDER EMAIL</label>
                                            <input type="text" class="form-control form-control-sm" v-model="searchFrm.primary_holders_email">
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="form-group">
                                            <label for="">START DATE</label>
                                            <input type="date" class="form-control form-control-sm" v-model="searchFrm.start_date">
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="form-group">
                                            <label for="">END DATE</label>
                                            <input type="date" class="form-control form-control-sm" v-model="searchFrm.end_date">
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="form-group">
                                            <label for="">TXN. TYPE</label>
                                            
                                            <select class="form-control form-control-sm" v-model="searchFrm.txn_type">
                                                <option value="">All</option>
                                                <option value="B">Purchase</option>
                                                <option value="R">Redeem</option>
                                                <option value="S">Switch</option>
                                                <option value="V">SIP</option>
                                                <option value="E">STP</option>
                                                <option value="J">SWP</option>
                                            </select>



                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="form-group">
                                            <label for="">ORDER STATUS</label>
                                             
                                            <select class="form-control form-control-sm" v-model="searchFrm.order_status">
                                                <option value="">All</option>
                                                <option value="RQ">Order created</option>
                                                <option value="OA">Order Accepted</option>
                                                <option value="CL">Order Cancelled</option>
                                                <option value="OR">Order Rejected </option>
                                                <option value="SA">Sent to RTA for Accept</option>
                                                <option value="SR">Sent to RTA as Cancelled</option>
                                                <option value="RA">RTA Accepted</option>
                                                <option value="RP">RTA Processed</option>
                                                <option value="RR">RTA Rejected</option>
                                                <option value="CMFD">Cancelled By MFD</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer">
                                <button class="btn btn-primary btn-sm mr-2">Search</button>
                                <button class="btn btn-warning btn-sm" type="button" @click="resetsearch()">Reset</button>
                            </div>
                        </form>
                        
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="card card-primary">
                        <div class="card-header">
                            <h3 class="card-title">
                                List 
                            </h3>
                            <div class="float-right">Total: {{totalItems}}</div>

                        </div>
                        <CSSLoader :showLoader="showLoader" />
                        <div class="table-responsive">
                            <table class="table table-bordered table-striped" v-if="!showLoader">
                                <tr>  
                                    <th class="text-center">ITRN</th>	
                                    <th class="text-center">Folio No.</th>	
                                    <th class="text-center">Scheme</th>	
                                    <th class="text-center">Dividend Opt.</th>	
                                    <th class="text-center">Txn. Type</th>	
                                    <th class="text-center">Amount/Units</th>
                                    <th class="text-center">Order Status</th>
                                    <th class="text-center">Action</th>
                                </tr>
                                <tr v-if="orders.length == 0 && !showLoader">
                                    <td colspan="8" class="text-center">
                                        <h4>No Order Found</h4>
                                    </td>
                                </tr>
                                
                                <tbody v-for="(o, index) in orders" :key="o.oid" :class="{'odd': (index%2==0), 'even': (index%2!=0)}">
                                    <tr>
                                        <td class="text-left" colspan="8">
                                             Group Order No.: {{o.group_order_no}}, Req. Date: {{o.req_date}}, TS: {{o.req_ts}}, CAN: {{o.can_no}}, CAN Name: {{o.primary_holders_name}}, EUIN Code: {{o.euin_code}}, 
                                             <!-- Status: <span :style="o.is_cancelled == 1 ? 'color: red; font-weight:bold;':'font-weight:bold;'">{{ o.is_cancelled == 1 ? 'Cancelled': 'Approved'}}</span> -->
                                              Status: <span style="color: green; font-weight: bold;" v-if="o.order_status_color == 'green'">{{ o.order_status }}</span>
                                              <span style="color: #fd7e14 !important; font-weight: bold;" v-if="o.order_status_color == 'orange'">{{ o.order_status }}</span>
                                              <span style="color: #dc3545 !important; font-weight: bold;" v-if="o.order_status_color == 'red'">{{ o.order_status }}</span>
                                        </td>
                                    </tr>
                                    <tr v-for="(item, ikey) in o.txnorderitems" :key="ikey" class="" :class="{'text-danger text-bold': (item?.mfu_response?.transaction_status == 'OR' || item?.mfu_response?.transaction_status == 'RR' || item?.mfu_response?.transaction_status == 'CL' || o.order_status == 'Cancelled'), 'text-warning text-bold': o.order_status == 'Pending'}"> 
                                        <td class="text-center">{{item.itrn}}</td>	
                                        <td class="text-center">{{item.folio_acc_no}}</td>	
                                        <td class="text-center">{{item.scheme_name}}</td>	
                                        <td class="text-center">{{item.dividend_option_display}}</td>	
                                        <td class="text-center">
                                            {{item.transaction_type_name}}
                                            <div v-if="o.order_type == 'sip'">
                                            <hr>
                                            Installment Date - {{ item.scheme_detail.day }} <br>
                                            Frequency - {{ getFrequencyDetail(item.scheme_detail.frequency) }} <br>
                                            Period - {{ item.scheme_detail.start_Month }}/{{ item.scheme_detail.start_Year }} to {{ item.scheme_detail.end_Month }}/{{ item.scheme_detail.end_Year }}<br>
                                            </div>
                                        </td>	
                                        <td class="text-center">{{item.txn_volume}}</td>
                                        <td class="text-center">{{item.transaction_status_desc}}
                                            
                                        </td>
                                        <td class="text-center" v-if="ikey == 0" :rowspan="(o.txnorderitems.length > 1 ? o.txnorderitems.length:'')">
                                            
                                            <div v-if="o.cancelLoader"><i class="fa fa-spinner fa-spin"></i></div>
                                            <div v-if="!o.cancelLoader">
                                                <a class="btn btn-sm btn-danger" style="padding: 2px 5px 2px 5px; font-size: 10px; line-height: normal; font-weight: bold; width: 100%;" :href="o.otp_link" target="_blank" type="button" v-if="o.otp_link && o.otp_link != '' && o.is_cancelled == 0" ng-cloak>MFU OTP Link</a>
                                                
                                                <!-- C/W/T   -->
                                                <div class="mb-2 mt-2" v-if="o.transaction_type != 'C' && o.transaction_type != 'W' && o.transaction_type != 'T'">
                                                    <a :href="`#/` + o.what + `?can=` + o.can_no + `&dupid=` + o.oid" class="btn btn-warning btn-sm">Duplicate Order</a>
                                                </div> 

                                                <div v-if="o.group_order_no == '' && o.is_cancelled == 0">
                                                    <a href="" class="btn btn-sm btn-info" @click.prevent="cancelOrder(o)">Cancel Order</a>
                                                </div> 
                                            </div>

                                        </td>
                                    </tr>
                                </tbody>
                                 
                                 
                                
                            </table>
                        </div>
                        <div class="card-footer"> 
                            <pagination v-model="page" v-bind:records="totalItems" :per-page="perPage" @paginate="pgCallback"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Pagination from 'v-pagination-3'
import EventService from '@/services/EventService.js'
import { ref, reactive } from 'vue'
import { useRouter, useRoute } from 'vue-router'
export default {
    name: 'Orders',
    components: {
        Pagination
    },
    setup() {
        const router = useRouter()
        const route = useRoute() 

        const page = ref(route.params.page)
        const totalItems = ref(0)
        const perPage = ref(5)
        
        const showLoader = ref(false)
        const searchFrm = reactive({
            group_order_no: (route.query.group_order_no || ''),
            folio_no: (route.query.folio_no || ''),
            can_no: (route.query.can_no || ''),
            pan_no: (route.query.pan_no || ''),
            primary_holders_name: (route.query.primary_holders_name || ''),
            primary_holders_email: (route.query.primary_holders_email || ''),
            start_date: (route.query.start_date || ''),
            end_date: (route.query.end_date || ''),
            txn_type: (route.query.txn_type || ''),
            order_status: (route.query.order_status || '')
        })

        const orders = ref([])

        function submitSearchForm(pgno) {
            router.push({ name: 'Orders', params: { page: (pgno || 1) }, query: searchFrm })
        }

        function search() { 
            let dataToSend = Object.assign(searchFrm, {page: page.value})
            
            showLoader.value = true
            EventService.getPostData("post", "mfu/orders", dataToSend)
            .then(response => {
                console.log(response.data)
                showLoader.value = false
                orders.value = response.data.data.map((order) => {
                    if(order.what == 'switchfund') {
                        order.what = 'switch';
                    }
                    
                    return order
                })
                totalItems.value = response.data.totalItems 
            })
            .catch(error => { 
                showLoader.value = false
                console.log("This iwas an error!", error)
            })
        }

        function resetsearch() {
            searchFrm.can_no = ''
            searchFrm.pan_no = ''
            searchFrm.primary_holders_name = ''
            searchFrm.primary_holders_email = ''
            searchFrm.group_order_no = ''

            searchFrm.folio_no = ''
            searchFrm.start_date = ''
            searchFrm.end_date = ''
            searchFrm.txn_type = ''
            searchFrm.order_status = ''
 
            submitSearchForm(1)
        }

        function pgCallback() {
            // console.log("Inside callback ===" + page.value)

            submitSearchForm(page.value)
        }

        search()


        function cancelOrder(order) {
            let c = confirm("Are you sure you wish to cancel the order?");

            if(c) {
                order.cancelLoader = true;
                EventService.getPostData("post", "mfu/orders/cancel", {oid: order.oid})
                .then(response => {
                    console.log(response.data)
                    order.cancelLoader = false;

                    alert(response.data.message);
                    
                    if(response.data.success == 1) {
                        order.is_cancelled = 1;
                    }

                })
                .catch(error => { 
                    order.cancelLoader = false;
                    console.log("This iwas an error!", error)
                })
            }
        }

        function getFrequencyDetail(f) {
            if(f == 'M') {
                return "Monthly";
            } else if(f == 'W') {
                return "Weekly";
            } else if(f == 'D') {
                return "Daily";
            } else if(f == 'Q') {
                return "Quarterly";
            }
        }

        return { 
            search, resetsearch, pgCallback, submitSearchForm,
            searchFrm, showLoader, orders, page, perPage, totalItems, cancelOrder,
            getFrequencyDetail
        }
    }
}
</script>
<style>
.text-warning {
    color: #fd7e14 !important;
}
</style>