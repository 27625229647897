<template>
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0">Payeezz Registration Status</h1>
          </div><!-- /.col -->
          <div class="col-sm-6"> 
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <section class="content text-sm">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                <!-- general form elements -->
                    <div class="card card-primary">
                        <div class="card-header">
                            <h3 class="card-title">Search</h3>
                        </div>
                        <form method="post" @submit.prevent="submitSearchForm()">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-2">
                                        <div class="form-group">
                                            <label for="">CAN NO.</label>
                                            <input type="text" class="form-control form-control-sm" v-model="searchFrm.can_no">
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="form-group">
                                            <label for="">ANY HOLDER'S PAN</label>
                                            <input type="text" class="form-control form-control-sm" v-model="searchFrm.pan_no">
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="form-group">
                                            <label for="">1ST HOLDER NAME</label>
                                            <input type="text" class="form-control form-control-sm" v-model="searchFrm.primary_holders_name">
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="">1ST HOLDER EMAIL</label>
                                            <input type="text" class="form-control form-control-sm" v-model="searchFrm.primary_holders_email">
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="">MMRN</label>
                                            <input type="text" class="form-control form-control-sm" v-model="searchFrm.mmrn">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer">
                                <button class="btn btn-primary btn-sm mr-2">Search</button>
                                <button class="btn btn-warning btn-sm" type="button" @click="resetsearch()">Reset</button>
                            </div>
                        </form>
                        
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="card card-primary">
                        <div class="card-header">
                            <h3 class="card-title">
                                List 
                            </h3>
                            <div class="float-right">Total: {{totalItems}}</div>

                        </div>
                        <CSSLoader :showLoader="showLoader" />
                        <div class="table-responsive">
                            <table class="table table-bordered table-striped" v-if="!showLoader">
                                <tr> 
                                    <th class="text-center">CAN</th>
                                    <th>Holders Name</th>	
                                    <th>Bank / A/C</th>	
                                    <th>MMRN</th>	
                                    <th>PRN</th>	
                                    <th>Maximum Amount</th>	
                                    <th class="text-center">Start/End Date</th>
                                    <th class="text-center">Added On</th>
                                    <th class="text-center">Registration Status</th>
                                </tr>
                                <tr v-if="payeezz.length == 0 && !showLoader">
                                    <td colspan="9" class="text-center">
                                        <h4>No Payeezz Found</h4>
                                    </td>
                                </tr>
                                <tr v-for="(p, index) in payeezz" :key="p.prn_reg_id" :class="{'odd': (index%2==0), 'even': (index%2!=0)}">
                                    <td class="text-center">{{p.can_no}}</td>
                                    <td>{{p.primary_holders_name}}</td>	
                                    <td>{{p.bank.bank_name}}<br />{{p.account_no}}</td>	
                                    <td>{{p.mmrn}}</td>	
                                    <td>{{p.prn}}</td>	
                                    <td>{{p.maximum_amount}}</td>	
                                    <td class="text-center">
                                        {{p.start_date}} <span v-if="p.end_date">{{p.end_date}}</span>
                                    </td>
                                    <td class="text-center">{{p.add_time}}</td>
                                    <td class="text-center">{{p.prnstatus}}</td>
                                </tr>
                                 
                                 
                                
                            </table>
                        </div>
                        <div class="card-footer"> 
                            <pagination v-model="page" v-bind:records="totalItems" :per-page="perPage" @paginate="pgCallback"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Pagination from 'v-pagination-3'
import EventService from '@/services/EventService.js'
import { ref, reactive } from 'vue'
import { useRouter, useRoute } from 'vue-router'

export default {
    name: 'ListPayeezzReg',
    components: {
        Pagination
    },
    setup() {
        const router = useRouter()
        const route = useRoute() 

        const page = ref(route.params.page)
        const totalItems = ref(0)
        const perPage = ref(50)
        
        const showLoader = ref(false)
        const searchFrm = reactive({
            can_no: (route.query.can_no || ''),
            pan_no: (route.query.pan_no || ''),
            primary_holders_name: (route.query.primary_holders_name || ''),
            primary_holders_email: (route.query.primary_holders_email || ''),
            mmrn: (route.query.mmrn || '')
        })

        const payeezz = ref([])

        function submitSearchForm(pgno) {
            router.push({ name: 'ListPayeezzReg', params: { page: (pgno || 1) }, query: searchFrm })
        }

        function search() { 
            let dataToSend = Object.assign(searchFrm, {page: page.value})
            
            showLoader.value = true
            EventService.getPostData("post", "mfu/payeezz/listAllReg", dataToSend)
            .then(response => {
                console.log(response.data)
                showLoader.value = false
                payeezz.value = response.data.data
                totalItems.value = response.data.totalItems 
            })
            .catch(error => { 
                showLoader.value = false
                console.log("This iwas an error!", error)
            })
        }

        function resetsearch() {
            searchFrm.can_no = ''
            searchFrm.pan_no = ''
            searchFrm.primary_holders_name = ''
            searchFrm.primary_holders_email = ''
            searchFrm.mmrn = ''

            submitSearchForm(1)
        }

        function pgCallback() {
            // console.log("Inside callback ===" + page.value)

            submitSearchForm(page.value)
        }

        search()

        return { 
            search, resetsearch, pgCallback, submitSearchForm,
            searchFrm, showLoader, payeezz, page, perPage, totalItems 
        }
    }
}
</script>
 