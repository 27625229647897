import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Login from '@/components/Login.vue'
import SubUser from '@/components/SubUser.vue'
import ManageSubUsers from '@/components/ManageSubUsers.vue'
import ListPayeezzReg from '@/components/ListPayeezzReg.vue'
import Orders from '@/components/Orders.vue'
import ListSmartInvesting from '@/components/ListSmartInvesting.vue'
import ListValueStp from '@/components/ListValueStp.vue'
import ListECan from '@/components/ListECan.vue'
import AddECan from '@/components/AddECan.vue'
import ListEpayeezz from '@/components/ListEpayeezz.vue'

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/addSubUser',
    name: 'SubUser',
    component: SubUser
  },
  {
    path: '/addSubUser/:id',
    name: 'ModifySubUser',
    component: SubUser
  },
  {
    path: '/manageSubUsers/:page',
    name: 'ManageSubUsers',
    component: ManageSubUsers
  },
  {
    path: '/list-payeezz-reg/:page',
    name: 'ListPayeezzReg',
    component: ListPayeezzReg
  },
  {
    path: '/orders/:page',
    name: 'Orders',
    component: Orders
  },
  {
    path: '/list-smart-investing/:page',
    name: 'ListSmartInvesting',
    component: ListSmartInvesting
  },
  {
    path: '/list-value-stp/:page',
    name: 'ListValueStp',
    component: ListValueStp
  },
  {
    path: '/add-ecan',
    name: 'AddECan',
    component: AddECan
  },
  {
    path: '/add-ecan/:id',
    name: 'ModifyECan',
    component: AddECan
  },
  {
    path: '/list-ecan/:page',
    name: 'ListECan',
    component: ListECan
  },
  {
    path: '/epayeezz/:canid',
    name: 'ListEpayeezz',
    component: ListEpayeezz
  },
  {
    path: '/smart-investing',
    name: 'SmartInvesting',
    component: () => import(/* webpackChunkName: "about" */ '../components/SmartInvesting.vue')
  },
  {
    path: '/smart-investing/:id',
    name: 'SmartInvestingModify',
    component: () => import(/* webpackChunkName: "about" */ '../components/SmartInvesting.vue')
  },
  {
    path: '/value-stp',
    name: 'ValueStp',
    component: () => import(/* webpackChunkName: "about" */ '../components/ValueStp.vue')
  },
  {
    path: '/value-stp/:id',
    name: 'ValueStpModify',
    component: () => import(/* webpackChunkName: "about" */ '../components/ValueStp.vue')
  },
  /// normal txns
  {
    path: '/purchase',
    name: 'Purchase',
    component: () => import(/* webpackChunkName: "about" */ '../components/Purchase.vue')
  },
  {
    path: '/redeem',
    name: 'Redeem',
    component: () => import(/* webpackChunkName: "about" */ '../components/Redeem.vue')
  },
  {
    path: '/switch',
    name: 'SwitchFund',
    component: () => import(/* webpackChunkName: "about" */ '../components/SwitchFund.vue')
  },
  {
    path: '/sip',
    name: 'Sip',
    component: () => import(/* webpackChunkName: "about" */ '../components/Sip.vue')
  },
  {
    path: '/stp',
    name: 'Stp',
    component: () => import(/* webpackChunkName: "about" */ '../components/Stp.vue')
  },
  {
    path: '/swp',
    name: 'Swp',
    component: () => import(/* webpackChunkName: "about" */ '../components/Swp.vue')
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/list-unprocessed-transactions',
    name: 'ListUnprocessedTransactions',
    component: () => import(/* webpackChunkName: "about" */ '../components/ListUnprocessedTransactions.vue')
  },
  {
    path: '/list-unprocessed-transactions-monthly',
    name: 'ListUnprocessedTransactionsMonthly',
    component: () => import(/* webpackChunkName: "about" */ '../components/ListUnprocessedTransactionsMonthly.vue')
  },

  {
    path: '/batch-upload',
    name: 'BatchUpload',
    component: () => import(/* webpackChunkName: "about" */ '../components/BatchUpload.vue')
  },

  {
    path: '/list-ecan-batch/:page',
    name: 'ListECanBatch', 
    component: () => import(/* webpackChunkName: "about" */ '../components/ListECanBatch.vue')
  },
  {
    path: '/ecan-batch/:id',
    name: 'ECanBatch',
    component: () => import(/* webpackChunkName: "about" */ '../components/ECanBatch.vue')
  },
  {
    path: '/systematic-cancellation',
    name: 'SystematicCancellation',
    component: () => import(/* webpackChunkName: "about" */ '../components/SystematicCancellation.vue')
  },
  {
    path: '/convert-vstp-to-normal-stp',
    name: 'ConvertVstpToNormalStp',
    component: () => import(/* webpackChunkName: "about" */ '../components/ConvertVstpToNormalStp.vue')
  },
  {
    path: '/smart-investing-approvals/:page',
    name: 'SmartInvestingApprovals',
    component: () => import(/* webpackChunkName: "about" */ '../components/SmartInvestingApprovals.vue')
  },
  {
    path: '/smart-investing-approvals-with-purchase/:page',
    name: 'SmartInvestingApprovalsWithPurchase',
    component: () => import(/* webpackChunkName: "about" */ '../components/SmartInvestingApprovalsWithPurchase.vue')
  },

  {
    path: '/multicap-vstp',
    name: 'MultiCapVStp',
    component: () => import(/* webpackChunkName: "about" */ '../components/MultiCapVStp.vue')
  },
  {
    path: '/multicap-vstp/:id',
    name: 'MultiCapVStpModify',
    component: () => import(/* webpackChunkName: "about" */ '../components/MultiCapVStp.vue')
  },

]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.name !== 'Login' && !localStorage.getItem('vue_mb_mfu_token') ) next({ name: 'Login' })
  else if (to.name == 'Login' && localStorage.getItem('vue_mb_mfu_token') ) next({ name: 'Home' })
  else next()
})

export default router
