<template>
    <div class="modal fade show" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">IMPORTANT NOTICE</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="closePopup()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body text-justify"> 
                    <div>
                        <p>
                        MisterBond MFU Transaction Platform (mfu.misterbond.in) will remain unavailable on 20 Dec 23 and 21 Dec 23 (Wednesday and Thursday) due to API changes.
                        </p>
                        <p>Scheduled Smart Investments will be executed as follows</p>
                        
                        <p>   
                        6 AM on 20 Dec 23 (Wednesday)<br/>
                        9 PM on 21 Dec 23 (Thursday)
                        </p>
                        <p>
                        Email Alerts and eCAN opening will also remain suspended for these 2 days.
                        </p>
                        <p>
                        Kindly schedule your workflow accordingly.
                        </p>
                        <p>
                        MisterBond.in and MisterBond BSE Transaction Platform (bse.misterbond.in) will work as usual.
                        </p>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-lg btn-outline-warning" data-dismiss="modal" @click="closePopup()">OK</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'UnderMaintenanceDisclosure', 
    setup(props, context) {
        function closePopup() { 
            context.emit("closePopup")
        }
 
        return {
            closePopup
        }
    }
}
</script>  

<style scoped> 
.modal {
    display: block;
}

.modal-content {
    text-align: left; 
}
 

code {
    font-size: 11px;
}

.loader {
  left: 50%;
  top: 50%;
  position: absolute;
}
 
</style>