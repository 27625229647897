<template>
    <div class="modal fade show" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Modify Contact Details <code>(Note: Any modification made in this page will not be submitted to MFU)</code></h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="closePopup()" :disabled="submitloader">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <form name="frmContactDetail" @submit.prevent="">
                    <div class="modal-body">
                        <div class="loader" v-if="dloader">
                            <CSSLoader :showLoader="dloader" />   
                        </div>
                        <table class="table table-striped" style="font-size: 12px;">
                            <tr>
                                <th width="20%">CAN No.</th>
                                <td width="20%">{{data.can_no}}</td>
                                <th width="20%">Primary Holder's PAN No.</th>
                                <td width="20%">{{data.pan_no}}</td>
                                <th width="20%"></th>
                            </tr>
                            
                            <!-- <tr>
                                <th>CAN Email</th>
                                <th colspan="2"><input type="email" name="can_email" v-model="data.can_email" class="form-control form-control-sm" required=""></th>
                                
                                <th>&nbsp;</th>
                            </tr> -->
                            <tr>
                                <th>Holder</th>
                                <th colspan="2">Email</th>
                                <th>Mobile</th>
                                <th></th>
                            </tr>

                            <tr>
                                <th>#1</th>
                                <th colspan="2">
                                    <input type="email" class="form-control form-control-sm" v-model="data.primary_holders_email" required>
                                </th>
                                <th>
                                    <input type="text" class="form-control form-control-sm" v-model="data.primary_holders_mobile" required>
                                </th>
                                <th> 
                                    <div class="input-group">
                                        <input type="text" class="form-control  form-control-sm" placeholder="OTP" v-model="data.otp1" @keypress="onlyNumber" maxlength="5" required>
                                        <div class="input-group-append">
                                            <button class="btn btn-primary btn-sm" type="button" @click="submitData(data, 1)" :disabled="!data.otp1 || data.otp1.length < 5 || dloader">Save</button>
                                        </div>
                                    </div>
                                    <a href="" @click.prevent="sendOTPForContactChange(data, 1)">Send OTP</a> 
                                </th>
                            </tr>

                            <tr v-if="data.TOTAL_HOLDERS == 2 || data.TOTAL_HOLDERS == 3">
                                <th>#2</th>
                                <th colspan="2">
                                    <input type="email" class="form-control form-control-sm" v-model="data.second_holders_email" required>
                                </th>
                                <th>
                                    <input type="text" class="form-control form-control-sm" v-model="data.second_holders_mobile" required>
                                </th>
                                <th> 
                                    <div class="input-group">
                                        <input type="text" class="form-control  form-control-sm" placeholder="OTP" v-model="data.otp2" @keypress="onlyNumber" maxlength="5" required>
                                        <div class="input-group-append">
                                            <button class="btn btn-primary btn-sm" type="button" @click="submitData(data, 2)" :disabled="!data.otp2 || data.otp2.length < 5 || dloader">Save</button>
                                        </div>
                                    </div>
                                    <a href=""  @click.prevent="sendOTPForContactChange(data, 2)">Send OTP</a> 
                                </th>
                            </tr>

                            <tr v-if="data.TOTAL_HOLDERS == 3">
                                <th>#3</th>
                                <th colspan="2">
                                    <input type="email" class="form-control form-control-sm" v-model="data.third_holders_email" required>
                                </th>
                                <th>
                                    <input type="text" class="form-control form-control-sm" v-model="data.third_holders_mobile" required>
                                </th>
                                <th> 
                                    <div class="input-group">
                                        <input type="text" class="form-control  form-control-sm" placeholder="OTP" v-model="data.otp3" maxlength="5" @keypress="onlyNumber" required>
                                        <div class="input-group-append">
                                            <button class="btn btn-primary btn-sm" type="button" @click="submitData(data, 3)" :disabled="!data.otp3 || data.otp3.length < 5 || dloader">Save</button>
                                        </div>
                                    </div>
                                    <a href=""  @click.prevent="sendOTPForContactChange(data, 3)">Send OTP</a> 
                                </th>
                            </tr>
                            
                        </table>
        
                    </div>
                    <!-- <div class="modal-footer">
                        <button type="submit" class="btn btn-primary" :disabled="submitloader"><i class="fa fa-spinner fa-spin" v-if="submitloader"></i> Save changes</button>
                        <button type="button" class="btn btn-secondary" :disabled="submitloader" data-dismiss="modal" @click="closePopup()">Close</button>
                    </div> -->
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { notify } from '@kyvg/vue3-notification'
import { ref, reactive } from 'vue'
import EventService from '@/services/EventService.js'
export default {
    name: 'CanContact',
    props: ["canid"],
    setup(props, context) {
        const canid = ref(props.canid)
        const dloader = ref(false)
        const submitloader = ref(false)
        const data = ref({

        })
 

        function closePopup() { 
            context.emit("closePopup")
        }

        function getData() {
            dloader.value = true
            EventService.getPostData("post", "mfu/listECAN", {id: canid.value})
            .then(response => {
                console.log(response.data)
                dloader.value = false
                data.value = response.data.data[0] 
            })
            .catch(error => { 
                dloader.value = false
                console.log("This iwas an error!", error)
                notify({
                    title: "Error",
                    text: error,
                    type: 'error'
                });
            })
        }

        getData()

        function sendOTPForContactChange(data, holder_no) {
            console.log(data, holder_no, data.id)

            dloader.value = true

            EventService.getPostData("post", "mfu/sendOTPForContactChange", {id: data.id, holder_no: holder_no})
            .then(response => {
                // console.log(response.data)
                dloader.value = false 

                if(response.data.success == 1) {
                    data['otpkey' + holder_no] = response.data.otpkey

                    notify({
                        title: "Success",
                        text: response.data.msg,
                        type: 'success'
                    });
                } else {
                    notify({
                        title: "Error",
                        text: response.data.msg,
                        type: 'error'
                    });
                }
            })
            .catch(error => { 
                dloader.value = false
                console.log("This iwas an error!", error)
                notify({
                    title: "Error",
                    text: error,
                    type: 'error'
                });
            })
        }

        function submitData(data, holder_no) {
            console.log("dd", data)
            // submitloader.value = true 
            let dataToSend = {
                id: data.id,
                can_email: data.can_email,
                primary_holders_email: data.primary_holders_email,
                second_holders_email: data.second_holders_email,
                third_holders_email: data.third_holders_email,
                primary_holders_mobile: data.primary_holders_mobile,
                second_holders_mobile: data.second_holders_mobile,
                third_holders_mobile: data.third_holders_mobile,
                holder_no: holder_no,
                otpkey: data["otpkey" + holder_no],
                otp: data["otp" + holder_no]
            }


            // console.log('all ok', dataToSend)
            // return false

            dloader.value = true
            EventService.getPostData("post", "mfu/updateECanContactDetail", dataToSend)
            .then(response => {
                console.log(response.data)
                dloader.value = false
                 
                if(response.data.success == 1) {
                    notify({
                        title: "Success",
                        text: response.data.msg,
                        type: 'success'
                    });

                    data['otpkey' + holder_no] = ""
                    data['otp' + holder_no] = ""

                    context.emit("reloadCan")

                } else {
                    notify({
                        title: "Error",
                        text: response.data.msg,
                        type: 'error'
                    });
                }
            })
            .catch(error => { 
                dloader.value = false
                console.log("This iwas an error!", error)

                notify({
                    title: "Error",
                    text: error,
                    type: 'error'
                });
            })
        }

        function onlyNumber($event) {
            //console.log($event.keyCode); //keyCodes value
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if ((keyCode < 48 || keyCode > 57)) { // 46 is dot
                $event.preventDefault();
            }
        }
        

        return { canid, closePopup, dloader, data, submitData, submitloader, sendOTPForContactChange, onlyNumber }
    }
}
</script>

<style scoped> 
.modal {
    display: block;
}

.modal-content {
    text-align: left; 
}
 

code {
    font-size: 11px;
}

.loader {
  left: 50%;
  top: 50%;
  position: absolute;
}
 
</style>