<template>
    <div class="modal fade show" tabindex="-1" role="dialog">
        <div class="modal-dialog-scrollable modal-dialog modal-xl" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Manage Payeezz &nbsp;&nbsp;&nbsp;<a href="" @click.prevent="listPRN()"><i class="fas fa-sync" :class="{'fa-spin': dloader}"></i></a></h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="closePopup()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <div class="modal-body">
                    <!-- <div class="loader" v-if="dloader">
                        <CSSLoader :showLoader="dloader" />   
                    </div>  -->
                    <div class="table-responsive">
                        <table class="table table-bordered table-striped table-dark">
                            <thead>
                                <tr>
                                    <th>MMRN</th>
                                    <th>PRN</th>
                                    <th>Maximum Amount</th>
                                    <th>Perpetual</th>
                                    <th>Start/End Date</th>
                                    <th>Added On</th>
                                    <th>Status</th>
                                    <th class="text-center">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="prn in payeezzrow" :key="prn.id">
                                    <td>{{prn.mmrn}}</td>
                                    <td>{{prn.prn}}</td>
                                    <td>{{prn.maximum_amount}}</td>
                                    <td>{{prn.perpetual_flag}}</td>
                                    <td>
                                        {{prn.start_date_disp}}
                                        <span v-if="prn.end_date"> / {{prn.end_date_disp}}</span> 
                                    </td>
                                    <td>{{prn.add_time_disp}}</td>
                                    <td>{{prn.prnstatus}}</td>
                                    <td class="text-center">
                                        <a href="" @click.prevent="deletePRN(prn)" v-if="!prn.delLoader"><i class="fa fa-trash"></i></a>

                                        <i class="fa fa-spinner fa-spin" v-if="prn.delLoader"></i>
                                    </td>
                                </tr> 

                                <tr v-if="payeezzrow.length == 0 && !dloader">
                                    <td colspan="8" class="text-center">No PRN Exists</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>


                </div>
            </div>
        </div>
    </div>
</template>


<script> 
import { notify } from '@kyvg/vue3-notification'
import { ref, reactive } from 'vue'
import EventService from '@/services/EventService.js' 
export default {
    name: 'ManagePayeezz',
    props: ["canid", "bankdetail"],
    setup(props, context) {
        const dloader = ref(false)
        const payeezzrow = ref([]) 

        function listPRN() {
            dloader.value = true
            EventService.getPostData("post", "mfu/listPRN", {ref_id: props.bankdetail.id})
            .then(response => {
                dloader.value = false
                console.log(response.data)  
                
                payeezzrow.value = response.data
                
            })
            .catch(error => { 
                dloader.value = false 
                console.log("This iwas an error!", error)
                notify({
                    title: "Error",
                    text: error,
                    type: 'error'
                });
            })
        }

        listPRN()

        function deletePRN(prn) {
            console.log(prn)

            let c = confirm("Are you sure you wish to delete this?")

            if(c) {

                prn.delLoader = true

                EventService.getPostData("post", "mfu/deletePRN", {id: prn.id, prn_type: prn.prn_type})
                .then(response => {
                    prn.delLoader = false
                    console.log(response.data)  
                    
                    if(response.data.success == 1) {
                        notify({
                            title: "Success",
                            text: response.data.msg,
                            type: 'success'
                        });

                        listPRN()
                    } else {
                        notify({
                            title: "Error",
                            text: response.data.msg,
                            type: 'error'
                        });
                    }
                })
                .catch(error => { 
                    prn.delLoader = false 
                    console.log("This iwas an error!", error)
                    notify({
                        title: "Error",
                        text: error,
                        type: 'error'
                    });
                })
            }
        }
 
        function closePopup() { 
            context.emit("closePopup")
        }

        return {
            dloader,
            payeezzrow,
            listPRN,
            closePopup,
            deletePRN
        } 


    },
}
</script>

<style scoped> 
.modal {
    display: block;
}

.modal-content {
    text-align: left; 
}
 

code {
    font-size: 11px;
}

.loader {
  left: 50%;
  top: 50%;
  position: absolute;
}
 
</style>