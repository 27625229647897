<template>
    <div class="modal fade show" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">CAN/Nominee/Contact Verification - <span class="text-danger">{{canno}}</span></h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="closePopup()" :disabled="submitloader">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body" style="min-height: 200px;">
                    <div class="" v-if="dloader">
                        <CSSLoader :showLoader="dloader" />   
                    </div>
                    <div class="alert alert-info text-center" v-if="errmsg">
                        {{errmsg}}
                    </div>
                    <table class="table table-bordered table-striped" v-if="!dloader && !errmsg">
                        <tr>
                            <th width="20%">Holder #</th>
                            <th width="20%">Name</th>
                            <!-- <th width="10%">Verified</th> -->
                            <th width="40%">Verification Link</th>  
                            <th width="10%" class="text-center">Send Link To Investor</th>  
                        </tr>  
                        <tr v-for="(h,hkey) in holders" :key="h">
                            <td>Holder {{hkey+1}}</td>
                            <td>{{h.NAME}}</td>
                            <!-- <td>{{h.verified}}</td> -->
                            <td>
                                <div>
                                    <span v-if="h.nomlink != ''">
                                        <a :href="h.nomlink" target="_blank" style="overflow-wrap: anywhere;">{{h.nomlink}}</a>
                                    </span>    
                                    <span v-else></span> 
                                </div>
                            </td>  
                            <td class="text-center">
                                <button v-if="h.nomlink != ''" type="button" class="btn btn-primary" @click.prevent="sendLinkToInvestor(h, hkey)" :disabled="h.showloader"><i v-if="h.showloader" class="fa fa-spinner fa-spin"></i> Send</button>  
                            </td>  
                        </tr>
                    </table>   
                    <div class="text-center"  v-if="!errmsg && !dloader && !only_nom_verify"> <!-- v-if="!dloader"-->
                        <!-- <a href="" @click.prevent="checkNomineeVerificationDetail()"><i class="fas fa-sync-alt fa-2x"></i></a> -->
                         <!-- <a href="" class="btn btn-primary btn-sm">EDIT</a> -->

                         <router-link class="btn btn-primary btn-circle btn-circle-sm m-1" :to="{ name: 'ModifyECan', params: { id: canid }}"><i class="fas fa-edit"></i></router-link>

                         <br><br>
                         
                    </div>
                    <div  v-if="!errmsg && !dloader && !only_nom_verify">
                        <small>
                            <code>** If the link has expired or been rejected by the client, please click the edit button above, make the necessary changes, 
                                and submit again. If there are no changes and the link is still not functioning, click the edit button above and just submit again. 
                                Return here for the NEW link.</code>
                         </small>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { notify } from '@kyvg/vue3-notification'
import { ref, reactive } from 'vue'
import EventService from '@/services/EventService.js'
export default {
    name: 'NomineeVerification',
    props: ["canid", "canno"],
    setup(props, context) {
        const canid = ref(props.canid)
        const canno = ref(props.canno)
        const dloader = ref(false)
        const errmsg = ref('')
        const holders = ref({

        })
        const only_nom_verify = ref(false)

        function closePopup() { 
            context.emit("closePopup")
        }

        function checkNomineeVerificationDetail() {
            errmsg.value = ''
            dloader.value = true
            EventService.getPostData("post", "mfu/checkNomineeVerificationDetail", {id: canid.value})
            .then(response => {
                console.log(response.data)
                dloader.value = false 
                if(response.data.success == 1) {
                    holders.value = response.data.holders

                    if(response.data.only_nom_verify) {
                        only_nom_verify.value = true;
                    }

                } else {
                    errmsg.value = response.data.errors
                }
                
            })
            .catch(error => { 
                dloader.value = false
                console.log("This iwas an error!", error)
                notify({
                    title: "Error",
                    text: error,
                    type: 'error'
                });
            })
        }

        checkNomineeVerificationDetail()

        function sendLinkToInvestor(h, hkey) {
            h.showloader = true

            EventService.getPostData("post", "mfu/sendNomineeVerificationLink", {id: canid.value, hkey: hkey})
            .then(response => {
                console.log(response.data)
                h.showloader = false

                if(response.data.success == 1) {
                    notify({
                        title: "Success",
                        text: response.data.message,
                        type: 'success'
                    });
                } else {
                    notify({
                        title: "Error",
                        text: response.data.message,
                        type: 'error'
                    });
                }
                
            })
            .catch(error => { 
                h.showloader = false
                console.log("This iwas an error!", error)
                notify({
                    title: "Error",
                    text: error,
                    type: 'error'
                });
            })


        }

        return { canid, canno, closePopup, dloader, checkNomineeVerificationDetail, holders, errmsg, sendLinkToInvestor, only_nom_verify }
    }
}
</script>
 


<style scoped> 
.modal {
    display: block;
}

.modal-content {
    text-align: left; 
}
    

code {
    font-size: 11px;
}

.loader {
    left: 50%;
    top: 50%;
    position: absolute;
}
    
</style>