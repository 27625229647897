import axios from 'axios'
import NProgress from 'nprogress'
import { notify } from "@kyvg/vue3-notification";
// let ajaxbaseURL = 'http://localhost:8000/api' 
let ajaxbaseURL = 'https://txn.misterbond.in/api'
const apiClient = axios.create({
    baseURL: ajaxbaseURL,
    // baseURL: 'https://api.misterbond.in/api',
    withCredentials: false,
    headers:{
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('vue_mb_mfu_token')
    }
})

let gloader = false;

const setGLoader = (x) => {
    gloader = x
}

apiClient.interceptors.request.use(config => {
    setGLoader(true)
    NProgress.start() 

    return config
})

apiClient.interceptors.response.use(config => {
    setGLoader(false)
    NProgress.done() 

    return config
})

// doing something with the response
apiClient.interceptors.response.use(
    (response) => {
       // all 2xx/3xx responses will end here
    //    console.log("aaaaa22",response.status)
       return response;
    },
    (error) => {
        // all 4xx/5xx responses will end here
        // console.log("aaaaa11",error.response.status)

        if(parseInt(error.response.status) == parseInt(401)) {
            // alert("Something went Wrong. Login again");

            notify({
                title: "Error",
                text: "Something went Wrong. Login again",
                type: 'error'
            });
        }
        
        // localStorage.removeItem('vue_mb_mfu_token') 
        // this.$router.push({name: 'Login'}) 
        // setTimeout(() => window.location.reload(), 3000)
        



       return Promise.reject(error);
    }
  );

export default {  
    checkGlobalLoader() {
        return gloader;
    },
    getMfuFileUploadConfig() {
        return {
            url: ajaxbaseURL + "/mfu/upload-file",
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.getItem('vue_mb_mfu_token')
            }
        }
    },
    login(loginData) {
        return apiClient.post("login", loginData)
    },
    logout() {
        return apiClient.post("logout") 
    },
    getLoggedInUserDetail() {
        return apiClient.get("user") 
    },
 
    ///// single point ajax ===============
    getPostData(type, url, data = {}) {
        if(type == 'get') {
            return apiClient.get(url)
        } else if(type == 'post') {
            return apiClient.post(url, data)
        }
    }
}