<template>
    <FullPageLoader :showLoader="showFullPageLoader" />
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0">PayEezz</h1>
          </div><!-- /.col -->
          <div class="col-sm-6 text-right">  
              <router-link class="btn btn-warning" :to="{ name: 'ListECan', params: { page: 1 }}">&#8592; List CAN</router-link>
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <section class="content text-sm">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                <!-- general form elements -->
                    <div class="card card-primary">
                        <div class="card-body">
                            <table class="table table-dark">
                                <tr>
                                    <td><strong>CAN No.</strong></td>
                                    <td>{{candetail.can_no}}</td>
                                    <td><strong>Primary Holder's Name</strong></td>
                                    <td>{{candetail.primary_holders_name}}</td>
                                    <td><strong>Primary Holder's PAN No.</strong></td>
                                    <td>{{candetail.pan_no}}</td>
                                </tr>
                            </table>
                            	 

                        </div>

                    </div>
                    <div class="card card-primary">
                        <div class="card-body">
                            <table class="table table-striped">
                                <thead>
                                    <tr>
                                        <th>A/C No.</th>	
                                        <th>Account Type</th>	
                                        <th>Bank</th>	
                                        <th>IFSC Code</th>	
                                        <th>MICR Code</th>	
                                        <th class="text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody> 
                                    <tr v-for="bnk in banklist" :key="bnk.id">
                                        <td>{{bnk.account_no}}</td>
                                        <td>{{bnk.account_type_desc}}</td>
                                        <td>{{bnk.bank_name}}</td>
                                        <td>{{bnk.ifsc_code}}</td>
                                        <td>{{bnk.micr_code}}</td>
                                        <td class="text-center">
                                            <div class="btn-group">
                                                <button type="button" class="btn btn-primary btn-sm" @click="bnk.showaddform = true">Add</button>
                                                <button type="button" class="btn btn-danger btn-sm" @click="bnk.showlist = true">Manage</button>
                                                <button type="button" class="btn btn-warning btn-sm" @click="createPayeezzFilledForm(bnk)" :disabled="bnk.dloader">
                                                    <i class="fa fa-spinner fa-spin" v-if="bnk.dloader"></i> 
                                                    Download Payeezz Form
                                                </button>
                                            </div>
 
                                            <AddPayeezz :canid="canid" v-if="bnk.showaddform" :bankdetail="bnk" @closePopup="bnk.showaddform = false" />
                                            <ManagePayeezz :canid="canid" v-if="bnk.showlist" :bankdetail="bnk" @closePopup="bnk.showlist = false" />
                                        </td>
                                    </tr> 
                                </tbody>
                            </table>
                            	 

                        </div>

                    </div>
                </div>
            </div>
        </div>
    </section>
 
</template>

<script>
import { notify } from '@kyvg/vue3-notification' 
import EventService from '@/services/EventService.js'
import { ref, reactive, watch, computed } from 'vue'
import { useRouter, useRoute } from 'vue-router'

import AddPayeezz from '@/components/modals/AddPayeezz.vue'
import ManagePayeezz from '@/components/modals/ManagePayeezz.vue'

export default {
    name: 'ListEpayeezz',
    components: {
        AddPayeezz,
        ManagePayeezz
    },
    setup() {
        const router = useRouter()
        const route = useRoute() 
        const canid = ref(0)
        canid.value = Number.parseInt(route.params.canid)
 

        const showFullPageLoader = ref(false)
        const listLoader = ref(false)
        const submitLoader = ref(false)
        const candetail = ref({})
        const banklist = ref([])

        function getECanDetail() {
            showFullPageLoader.value = true
            EventService.getPostData("post", "mfu/listECAN", {id: canid.value})
            .then(response => {
                console.log(response.data)
                showFullPageLoader.value = false 
                candetail.value = response.data.data[0] 
            })
            .catch(error => { 
                showFullPageLoader.value = false
                console.log("This iwas an error!", error)
                notify({
                    title: "Error",
                    text: error,
                    type: 'error'
                });
            })
        }

        function getAllBank() {
            listLoader.value = true 

            EventService.getPostData("post", "mfu/getECanBanks", {id: canid.value})
            .then(response => {
                console.log(response.data)
                listLoader.value = false 
                banklist.value = response.data 
            })
            .catch(error => { 
                listLoader.value = false
                console.log("This iwas an error!", error)
                notify({
                    title: "Error",
                    text: error,
                    type: 'error'
                });
            })
            
        }

        function createPayeezzFilledForm(bnk) {
            bnk.dLoader = true

            EventService.getPostData("post", "mfu/createPayeezzFilledForm", {id: bnk.id})
            .then(response => {
                bnk.dLoader = false
                console.log(response.data)  
                
                if(response.data.success == 1) { 
                    window.open(response.data.url, '_blank');
                } else {
                    notify({
                        title: "Error",
                        text: response.data.msg,
                        type: 'error'
                    });
                }
            })
            .catch(error => { 
                bnk.dLoader = false 
                console.log("This iwas an error!", error)
                notify({
                    title: "Error",
                    text: error,
                    type: 'error'
                });
            })
        }

        getECanDetail()
        getAllBank()

        return {
            canid, candetail, banklist, listLoader, submitLoader, showFullPageLoader, createPayeezzFilledForm
        }
    }
}
</script>
