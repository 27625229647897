<template>
<div class="login-page">
    <div class="login-box">
    <!-- /.login-logo -->
        <div class="card card-outline card-primary">
            <div class="card-header text-center">
                <img src="../assets/img/login-logo.png" alt="">
                
            </div>
            <div class="card-body">
                <p class="login-box-msg">Login To Access Transaction Platform</p>

                <form name="frmLogin" id="frmLogin" method="post" @submit.prevent="submitLoginForm()">
                    <div class="input-group mb-3">
                        <input type="text" class="form-control" placeholder="Login ID" v-model="username" required>
                        <div class="input-group-append">
                        <div class="input-group-text">
                            <span class="fas fa-envelope"></span>
                        </div>
                        </div>
                    </div>
                    <div class="input-group mb-3">
                        <input type="password" class="form-control" placeholder="Password" v-model="password" required>
                        <div class="input-group-append">
                        <div class="input-group-text">
                            <span class="fas fa-lock"></span>
                        </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 text-left">
                            <div class="icheck-primary">
                                <input type="checkbox" id="remember" v-model="is_subuser"> 
                                <label for="remember">
                                If Sub User, check this and enter ARN No
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="input-group mb-3 mt-3" v-if="is_subuser">
                        <input type="text" class="form-control" placeholder="ARN No" v-model="arn_no">
                        <div class="input-group-append">
                        <div class="input-group-text">
                            <span class="fas fa-envelope"></span>
                        </div>
                        </div>
                    </div>
                    <div class="row">
                        <!-- /.col -->
                        <div class="col-12 mt-2">
                            <button type="submit" class="btn btn-primary btn-block">Sign In</button>
                        </div>
                        <div class="col-12 mt-2 text-center" style="padding-top: 10px;"> 
                            <a href="https://bse.misterbond.in" target="_blank" style="background-color: green; color: #ffffff; padding: 10px; width: 100%;">Click here to login to BSE</a>
                        </div>
                        <!-- /.col -->
                    </div>
                </form>

                
            </div>
        <!-- /.card-body -->
            <div class="card-footer"><small>Copyright &copy; {{copyrightYear}} MisterBond. All rights reserved.</small></div>
        </div>
    <!-- /.card -->
    </div>
</div>
</template>

<script> 
import { notify } from '@kyvg/vue3-notification'
import EventService from '@/services/EventService.js'
export default {
    name: 'Login',
    data() {
        return {
            copyrightYear: (new Date()).getFullYear(),
            loginError: null,
            username: '',
            password: '',
            arn_no: '',
            is_subuser: false
        }
    },
    methods: {
        submitLoginForm() {
            console.log("inside form submit")

            
            const loginData = { username: this.username, password: this.password, arn_no: this.arn_no, is_subuser: this.is_subuser }
            EventService.login(loginData)
            .then(response => {
                console.log(response.data)
                localStorage.setItem('vue_mb_mfu_token', response.data.data.token)
                this.$router.push({name: 'Home'}) 
                window.location.reload()
            })
            .catch(error => {
                this.loginError = error.message
                console.log("This iwas an error!", error.response.data.data.error)

                notify({
                    title: "Error",
                    text: error.response.data.data.error,
                    type: 'error'
                });
            })

        }
    }

}
</script>


<style scoped>
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700&display=fallback');
@import url('../assets/adminlte3/plugins/fontawesome-free/css/all.min.css'); 
@import url('../assets/adminlte3/plugins/icheck-bootstrap/icheck-bootstrap.min.css'); 
@import url('../assets/adminlte3/dist/css/adminlte.min.css');

.login-box {
    /* width: 500px; */
}

.form-control {
    -webkit-text-fill-color:black !important;
}
</style>  