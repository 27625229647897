<template>
    <div class="modal fade show" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Bank Details <code>(Note: Any modification made in this page will not be submitted to MFU)</code></h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="closePopup()" :disabled="submitloader">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <form name="frmContactDetail" @submit.prevent="submitData()">
                    <div class="modal-body">
                        <div class="loader" v-if="dloader">
                            <CSSLoader :showLoader="dloader" />   
                        </div> 
                        <table class="table table-striped" style="font-size: 12px;">
                            <tr>
                                <th width="25%">CAN No.</th>
                                <td width="25%">{{canno}}</td>
                                <th width="25%">Primary Holder's PAN No.</th>
                                <td width="25%">{{panno}}</td>
                            </tr>
                        </table>

                        <table class="table table-striped" style="font-size: 12px;">
                            <tr>
                                <th width="">A/C No.</th>
                                <td width="">Account Type</td>
                                <th width="">Bank</th>
                                <td width="">IFSC Code</td>
                                <td width="">MICR Code</td>
                                <td class="text-center">Action</td>
                            </tr>
                            <tr v-for="eb in ecanbanks" :key="eb.id">
                                 
                                <td>{{eb.account_no}}</td>
                                <td>{{eb.account_type_desc}}</td>
                                <td>{{eb.bank_name}}</td>
                                <td>{{eb.ifsc_code}}</td>
                                <td>{{eb.micr_code}}</td>
                                <td class="text-center"> 
                                    <a title="Modity" class="btn btn-primary btn-circle btn-circle-xs m-1" @click.prevent="modifyBank(eb)"><i class="fas fa-edit"></i></a>
                                    <a title="Delete" class="btn btn-danger btn-circle btn-circle-xs m-1" @click.prevent="deleteBank(eb)"><i class="fas fa-trash"></i></a>  
                                </td>
                            </tr>
                        </table>
                        <hr />
                        <h5>Add New Bank</h5>  

                        <table class="table table-bordered" style="font-size: 12px;">
                            <tr>
                                <th>A/C No. <span style="color: red;">*</span></th>
                                <td><input type="text" class="form-control form-control-sm" v-model="dataFrm.account_no" required="" :disabled="dataFrm.bid"></td>
                                <th>Account Type <span style="color: red;">*</span></th>
                                <td>
                                    <select class="form-control form-control-sm" v-model="dataFrm.account_type" required="" :disabled="dataFrm.bid">
                                        <option value="">--Select Account Type --</option>
                                        <option value="SB">Savings Bank Account</option>
                                        <option value="CA">Current Account</option>
                                        <option value="CC">Cash Credit Account</option>
                                        <option value="OD">Overdraft Account</option>
                                        <option value="NRE">Non Resident External Account</option>
                                        <option value="NRO">Non Resident Ordinary Account</option>
                                        <option value="FCNR">Foreign Currency Non Resident Account</option>
                                        <option value="NRSR">Special Non Resident Rupee Account</option>
                                        <option value="OTH">Other type of Account</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <th>IFSC Code <span style="color: red;">*</span></th>
                                <td><input type="text" class="form-control form-control-sm" v-model="dataFrm.ifsc_code" maxlength="11" required=""></td>
                                <th>MICR Code <span style="color: red;">*</span></th>
                                <td><input type="text" class="form-control form-control-sm" v-model="dataFrm.micr_code" maxlength="9" required=""></td>
                            </tr>
                            <tr>
                                <th>Bank <span style="color: red;">*</span></th>
                                <td colspan="4">
                                    <select class="form-control form-control-sm" v-model="dataFrm.bank_id" required="">
                                        <option value="">--Select Bank --</option>
                                        <option :value="item.bank_id" v-for="item in bankmaster" :key="item.bank_id">
                                            {{item.bank_name}}
                                        </option>
                                    </select>

                                </td>
                                
                            </tr>
                        </table>
 

 
                    </div>
                   
                    <div class="modal-footer">
                        <button type="submit" class="btn btn-primary" :disabled="submitloader"><i class="fa fa-spinner fa-spin" v-if="submitloader"></i> Save changes</button>
                        <button type="button" class="btn btn-secondary" :disabled="submitloader" data-dismiss="modal" @click="closePopup()">Close</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { notify } from '@kyvg/vue3-notification'
import { ref, reactive } from 'vue'
import EventService from '@/services/EventService.js' 
export default {
    name: 'CanBank',
    props: ["canid", "canno", "panno"],
    setup(props, context) {
        const canid = ref(props.canid)
        const dloader = ref(false)
        const submitloader = ref(false)
        const ecanbanks = ref([])
        const bankmaster = ref([])

        const dataFrm = reactive({ 
            bid: null,
            account_no: '',
            account_type: '',
            ifsc_code: '',
            micr_code: '',
            bank_id: ''

        })

        function closePopup() { 
            context.emit("closePopup")
        }

        

        function getECanBanks() {
            dloader.value = true
            EventService.getPostData("post", "mfu/getECanBanks", {id: canid.value})
            .then(response => {
                console.log(response.data)
                dloader.value = false
                ecanbanks.value = response.data
            })
            .catch(error => { 
                dloader.value = false
                console.log("This iwas an error!", error)
                notify({
                    title: "Error",
                    text: error,
                    type: 'error'
                });
            })
        }

        function getAllBanks() {
            dloader.value = true
            EventService.getPostData("post", "mfu/getAllBanks", {})
            .then(response => {
                console.log(response.data)
                dloader.value = false
                bankmaster.value = response.data
            })
            .catch(error => { 
                dloader.value = false
                console.log("This iwas an error!", error)
                notify({
                    title: "Error",
                    text: error,
                    type: 'error'
                });
            })
        }

        getECanBanks() 
        getAllBanks()

        function resetForm() {  
            dataFrm.bid = null
            dataFrm.account_no = ''
            dataFrm.account_type = ''
            dataFrm.ifsc_code = ''
            dataFrm.micr_code = ''
            dataFrm.bank_id = ''
        }

        function modifyBank({ id, account_no, account_type, ifsc_code, micr_code, bank_id, can_no}) {  
            dataFrm.bid = id
            dataFrm.account_no = account_no
            dataFrm.account_type = account_type
            dataFrm.ifsc_code = ifsc_code
            dataFrm.micr_code = micr_code
            dataFrm.bank_id = bank_id
        }

        function deleteBank({ id }) {

            let c = confirm("Are you sure you wish to delete this?")

            if(c) {
                dloader.value = true
                EventService.getPostData("post", "mfu/deleteECanBank", {id: id})
                .then(response => {
                    console.log(response.data)
                    dloader.value = false 

                    if(response.data.success == 1) {
                        resetForm()
                        getECanBanks() 
                        notify({
                            title: "Success",
                            text: response.data.msg,
                            type: 'success'
                        });

                    } else {
                        notify({
                            title: "Error",
                            text: response.data.msg,
                            type: 'error'
                        });
                    }
                })
                .catch(error => { 
                    dloader.value = false
                    console.log("This iwas an error!", error)

                    notify({
                        title: "Error",
                        text: error,
                        type: 'error'
                    });
                })
            }
 
        }

        function submitData() {
            dloader.value = true
            let dataToSend = Object.assign(dataFrm, {can_no: props.canno})
            EventService.getPostData("post", "mfu/saveECanBankDetails", dataToSend)
            .then(response => {
                console.log(response.data)
                dloader.value = false 

                if(response.data.success == 1) {
                    resetForm()
                    getECanBanks() 
                    notify({
                        title: "Success",
                        text: response.data.msg,
                        type: 'success'
                    });

                } else {
                    notify({
                        title: "Error",
                        text: response.data.msg,
                        type: 'error'
                    });
                }
            })
            .catch(error => { 
                dloader.value = false
                console.log("This iwas an error!", error)

                notify({
                    title: "Error",
                    text: error,
                    type: 'error'
                });
            })
        }


        return { 
                    canid, closePopup, dloader, submitloader, ecanbanks, submitData, dataFrm, 
                    bankmaster, modifyBank, deleteBank 
                }
    }
}
</script>

<style scoped> 
.modal {
    display: block;
}

.modal-content {
    text-align: left; 
}
 

code {
    font-size: 11px;
}

.loader {
  left: 50%;
  top: 50%;
  position: absolute;
}
 
</style>