import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import 'nprogress/nprogress.css'
import CSSLoader from '@/views/CSSLoader.vue' 
import FullPageLoader from '@/views/FullPageLoader.vue' 
import Notifications from '@kyvg/vue3-notification'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import DropZone from 'dropzone-vue';
import 'dropzone-vue/dist/dropzone-vue.common.css'; 
import Popper from "vue3-popper";
import "./assets/css/popper.css"; 

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
// import { SetupCalendar } from 'v-calendar';


// createApp(App).use(router).mount('#app')

const app = createApp(App)
app.use(router).use(Notifications).use(DropZone).use(VueSweetalert2) //.use(SetupCalendar, {})
app.component('CSSLoader', CSSLoader).component('v-select', vSelect).component('FullPageLoader', FullPageLoader).component("Popper", Popper)
app.mount('#app')

