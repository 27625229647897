<template>
    <div>
        <input type="file" ref="inputFile" @change="select" :accept="fileext" class="form-control">
        <progress :value="progress" max="100" style="width: 100%"></progress>
 
        <a href="" @click.prevent="resetInput()" v-if="progress >= 100">Reset</a>
    </div>
</template>

<script>
import axios from 'axios'
import EventService from '@/services/EventService.js'
export default {
    name: 'FileUpload',
    props: ["filetype", "fileext"],
    data() {
        return {
            file: null,
            chunks: [],
            uploaded: 0,
            uploadConfig: EventService.getMfuFileUploadConfig()
        };
    },
    computed: {

        progress() {
            let x = 0;
            if(this.file) {
                x = this.file.size
            } else {
                return 0
            }

            // console.log("progress", this.uploaded, x)

            return Math.floor((this.uploaded * 100) / x);
        },
        formData() {
            let formData = new FormData;

            if(this.chunks.length > 0) {
                formData.set('is_last', this.chunks.length === 1);
                formData.set('file', this.chunks[0], `${this.file.name}.part`);
            }

            formData.set('filetype', this.filetype);

            return formData;
        },
        config() {
            return {
                method: 'POST',
                data: this.formData,
                url: this.uploadConfig.url,
                headers: this.uploadConfig.headers,
                onUploadProgress: event => {
                    this.uploaded += event.loaded;
                }
            };
        }
    },

    methods: {
        select(event) {
            console.log('at select')
            this.chunks = []
            this.uploaded = 0
            this.file = event.target.files.item(0);
            this.createChunks();
        },
        upload() {
            console.log('at upload')
            if(this.chunks.length > 0) {
                axios(this.config).then(response => {
                    this.chunks.shift();
 
                    if(response.data.uploaded_file_name) {
                        // alert(response.data.uploaded_file_name)
                        this.$emit('fileuploaded', response.data.uploaded_file_name)
                    }

                }).catch(error => {});
            }
        },
        createChunks() {
            console.log('at chunk')
            let size = 2097152, chunks = Math.ceil(this.file.size / size);
  
            for (let i = 0; i < chunks; i++) {
                this.chunks.push(this.file.slice(
                    i * size, Math.min(i * size + size, this.file.size), this.file.type
                ));
            }

            // console.log(this.chunks)
            
        },
        resetInput() {
            const input = this.$refs.inputFile
            input.type = 'text'
            input.type = 'file'
            this.uploaded = 0
            // alert(this.filetype)
            this.$emit('resetfileupload')
        }
    },
    watch: {
        chunks: {
            handler(val, oldVal) {
                //console.log('book list changed')
                this.upload()
            },
            deep: true
        },
         

    },
}
</script>