<template>
    <div class="modal fade show" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Triggers Log</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="closePopup()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <div class="modal-body">
                    <div class="loader" v-if="dloader">
                        <CSSLoader :showLoader="dloader" />   
                    </div> 
                  
                    <div class="">
                        <table class="table table-bordered table-striped table-dark">
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Trigger Type</th>
                                    <th>Purchase In</th>
                                    <th>{{sitype == 'vstp' ? 'Switch/STP From' : 'Switch From'}}</th>
                                    <th>{{sitype == 'vstp' ? 'Switch/STP To' : 'Switch To'}}</th>
                                    <th>Amount / Units</th>
                                    <th>Status</th>
                                    <th class="text-center">Error</th>
                                </tr>
                            </thead>
                            <tbody>
                                
                                <tr v-for="(value, key) in logrow" :key="key">
                                    <td nowrap="">{{value.trigger_date}}</td>
                                    <td>{{value.trigger_type_disp}}</td>
                                    <td>{{value.purchase_in}}</td>
                                    <td>{{value.switched_from}}</td>
                                    <td>{{value.switched_to}}</td>
                                    <td>
                                        <span v-if="value.trigger_type == 'purchase' || value.trigger_type == 'stpfund'" v-cloak>{{value.purchase_amount}}</span>
                                        <span v-if="value.trigger_type == 'switchfund'" v-cloak>All Units</span>
                                    </td>
                                    <td>{{value.transaction_status}}</td>
                                    <td>
                                        <label v-for="(v, k) in value.mfu_response" :key="k">{{v.MSG}},</label>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>


                </div>
            </div>
        </div>
    </div>
</template>


<script>  
import { notify } from '@kyvg/vue3-notification'
import { ref, reactive } from 'vue'
import EventService from '@/services/EventService.js' 
export default {
    name: 'SiTriggersLog',
    props: ["auto_trigger_id", "sitype"],
    setup(props, context) {
        const dloader = ref(false)
        const logrow = ref([]) 

        let ajax_url = ''
        if(props.sitype == 'vstp') {
            ajax_url = "mfu/value-stp/triggersLog"
        } else {
            ajax_url = "mfu/smart-investing/triggersLog"
        }

        function listLog() {
            dloader.value = true
            EventService.getPostData("post", ajax_url, {auto_trigger_id: props.auto_trigger_id})
            .then(response => {
                dloader.value = false
                console.log(response.data.DATA)  
                
                logrow.value = response.data.DATA 
            })
            .catch(error => { 
                dloader.value = false 
                console.log("This iwas an error!", error)
                notify({
                    title: "Error",
                    text: error,
                    type: 'error'
                });
            })
        }

        listLog()

         
 
        function closePopup() { 
            context.emit("closePopup")
        }

        return {
            dloader,
            logrow, 
            closePopup,
        } 


    },
}
</script>

<style scoped> 
.modal {
    display: block;
}

.modal-content {
    text-align: left; 
}
 

code {
    font-size: 11px;
}

.loader {
  left: 50%;
  top: 50%;
  position: absolute;
}

.modal{
    display: block !important; /* I added this to see the modal, you don't need this */
}

/* Important part */
.modal-dialog{
    overflow-y: initial !important
}
.modal-body{
    height: 80vh;
    overflow-y: auto;
}
 
</style>