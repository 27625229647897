<template>
    
    <form method="post" @submit.prevent="submit()" enctype="multipart/form-data">
        <div class="modal fade show" tabindex="-1" role="dialog">
            <div class="modal-dialog modal" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Nominee Upload - <span class="text-danger">{{canno}}</span></h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="closePopup()" :disabled="dloader">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body" style="min-height: 200px;">
                        <div class="" v-if="dloader">
                            <CSSLoader :showLoader="dloader" />   
                        </div>
                        <div class="alert alert-info text-center" v-if="errmsg">
                            {{errmsg}}
                        </div>
                        <div v-show="!dloader"> 
                            <FileUpload :filetype="'nom_file'" @resetfileupload="dataFrm.nom_file = null" @fileuploaded="fileuploaded($event, 'nom_file')" :fileext="'.jpg,.jpeg,.gif,.png'" />
                        </div>

                    </div>
                    <div class="modal-footer"> 
                        <button class="btn btn-primary btn-sm" v-if="!dloader">Submit</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import { notify } from '@kyvg/vue3-notification'
import { ref, reactive } from 'vue'
import EventService from '@/services/EventService.js'
import FileUpload from '@/components/modals/FileUpload.vue'
export default {
    name: 'NomineeUpload',
    props: ["canid", "canno"],
    components: {
        FileUpload
    },
    setup(props, context) {
        const canid = ref(props.canid)
        const canno = ref(props.canno)
        const dloader = ref(false)
        const errmsg = ref('')

        const dataFrm = reactive({
			canid: canid,
			nom_file: null
		})

        function closePopup() { 
            context.emit("closePopup")
        }

        function fileuploaded(c,d) {
			console.log("c",c)
			console.log("d",d)
            dataFrm.nom_file = c
		}

        // uploadNomineeProofToMfu

        function submit() {
            if(!dataFrm.nom_file) {
				notify({
					title: "Error",
					text: 'Please upload a file',
					type: 'error'
				});

				return false
			}
            dloader.value = true
			EventService.getPostData("post", "mfu/uploadNomineeProofToMfu", dataFrm).then(function(response){
				dloader.value = false
				if(response.data.success == 1) {
                    console.log(response)
                    notify({
						title: "Success",
						text: response.data.message,
						type: 'success'
					});

                    context.emit("closePopup")

				} else {
					notify({
						title: "Error",
						text: response.data.message,
						type: 'error'
					});
				}

			}).catch(error => { 
                dloader.value = false
                console.log("This iwas an error!", error)

                notify({
                    title: "Error",
                    text: error,
                    type: 'error'
                });
            })



        }

        return {
            canid, canno, closePopup, dloader, errmsg, fileuploaded, dataFrm, submit
        }
    }
}
</script>

<style scoped> 
.modal {
    display: block;
}

.modal-content {
    text-align: left; 
}
 

code {
    font-size: 11px;
}

.loader {
  left: 50%;
  top: 50%;
  position: absolute;
}
 
</style>