<template>
    <notifications position="top center" />
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->

	<div class="wrapper"> 
		<div class="loading" v-if="showFullPageLoader">
			<CSSLoader :showLoader="showFullPageLoader" />
		</div>

		<LeftMenu v-if="loggedIn" :isdesktop="isdesktop" :sidebar="sidebar" @updateSidebar="updateSidebar()" :userDetail="userDetail" @logout="logout()" />

		<div class="" :class="{'content-wrapper': loggedIn}">
        <div class="alert alert-danger mt-2 mb-2" v-if="expiryMessage != ''">
            <marquee><strong>{{expiryMessage}}</strong></marquee> 
        </div> 
    		<router-view :key="$route.fullPath"/>
		</div>

		<Footer v-if="loggedIn" :registration_arn="userDetail.registration_arn" :euin_code="userDetail.euin_code" />

    <!-- <UnderMaintenanceDisclosure v-if="umDisclosure"  @closePopup="umDisclosure = false" /> -->
	</div>
  
</template>

<script>
import EventService from '@/services/EventService.js'
import LeftMenu from '@/views/LeftMenu.vue'
import Footer from '@/views/Footer.vue'
import UnderMaintenanceDisclosure from './components/modals/UnderMaintenanceDisclosure.vue'
export default {
	name: 'App',
	components: { 
		LeftMenu,
		Footer,
    UnderMaintenanceDisclosure
	},
	data() {
		return {
      isdesktop: false,
			loggedIn: false,
			sidebar: false,
			userDetail: {},
			showFullPageLoader: true,
      expiryMessage: '',
      umDisclosure: true
		}
	},
	methods: {
		updateSidebar() {
			console.log("hhhhhhhhhh", this.sidebar)
      if(this.isdesktop) {
        return false
      }

			this.sidebar = !this.sidebar

			if(this.sidebar) {
				document.body.classList.add('sidebar-open')
        document.body.classList.remove('sidebar-closed', 'sidebar-collapse')
			} else {
				document.body.classList.remove('sidebar-open')
        document.body.classList.add('sidebar-closed', 'sidebar-collapse')

			}
		},
		logout() { 
			this.showFullPageLoader = true
			EventService.logout()
            .then(response => {
                console.log(response.data)
                localStorage.removeItem('vue_mb_mfu_token') 
                this.$router.push({name: 'Login'}) 
			        	this.loggedIn = false

				        this.showFullPageLoader = false
            })
            .catch(error => { 
				        this.showFullPageLoader = false
                console.log("There was an error!", error)
                alert(error)
            })
        },
        getUserDetail() {
            return EventService.getLoggedInUserDetail()
            .then(response => {

                return response.data
                // // console.log(response.data)
            })
            .catch(error => { 
                console.log("This iwas an error!", error)
            })
        },
        checkIfDesktop(){
          return window.innerWidth > 1000
        },
        myEventHandler(e) {
          // console.log(window.innerWidth);
          document.body.classList.remove('sidebar-mini', 'sidebar-closed', 'sidebar-collapse', 'sidebar-open')
          if(this.checkIfDesktop()) {
            this.isdesktop = true
            
            document.body.classList.add('sidebar-mini', 'sidebar-open', 'text-sm', 'layout-footer-fixed', 'layout-navbar-fixed', 'layout-fixed')
          } else {
            this.isdesktop = false
            document.body.classList.add('sidebar-mini', 'sidebar-closed', 'sidebar-collapse', 'text-sm', 'layout-footer-fixed', 'layout-navbar-fixed', 'layout-fixed')
          }
          // your code for handling resize...
        }
	},
	mounted () {
		// document.body.classList.add('dark-mode', 'sidebar-mini', 'layout-fixed', 'layout-navbar-fixed', 'layout-footer-fixed', 'sidebar-closed', 'sidebar-collapse')
    if(this.checkIfDesktop()) {
      this.isdesktop = true
      document.body.classList.add('sidebar-mini', 'sidebar-open', 'text-sm', 'layout-footer-fixed', 'layout-navbar-fixed', 'layout-fixed')
    } else {
      this.isdesktop = false
      document.body.classList.add('sidebar-mini', 'sidebar-closed', 'sidebar-collapse', 'text-sm', 'layout-footer-fixed', 'layout-navbar-fixed', 'layout-fixed')
    }
    
  
  },
	destroyed () {
		// document.body.classList.remove('dark-mode', 'sidebar-mini', 'layout-fixed', 'layout-navbar-fixed', 'layout-footer-fixed', 'sidebar-closed', 'sidebar-collapse')
    document.body.classList.remove('sidebar-mini', 'sidebar-closed', 'sidebar-collapse')
    window.removeEventListener("resize", this.myEventHandler);
  },

 
  async created() { 
    window.addEventListener("resize", this.myEventHandler);
 
        if(localStorage.getItem('vue_mb_mfu_token')) {

          let generalMessage = `
          IMPORTANT NOTICE!!

            MisterBond MFU Transaction Platform (mfu.misterbond.in) will remain unavailable on 20 Dec 23 and 21 Dec 23 (Wednesday and Thursday) due to API changes.

            Scheduled Smart Investments will be executed as follows

            6 AM on 20 Dec 23 (Wednesday)
            9 PM on 21 Dec 23 (Thursday)

            Email Alerts and eCAN opening will also remain suspended for these 2 days.

            Kindly schedule your workflow accordingly.

            MisterBond.in and MisterBond BSE Transaction Platform (bse.misterbond.in) will work as usual.`;


            this.loggedIn = true
            
            EventService.getPostData("post", "check-subscription-expiry", {})
            .then(response => {
                 
              // console.log(response)
              this.expiryMessage = response.data.message || ""
              // this.expiryMessage += generalMessage
                
            })
            .catch(error => {   
                console.log("This iwas an error!", error)
            })

            this.userDetail = await this.getUserDetail().then((result) => {
                // console.log(result)
				        this.showFullPageLoader = false
                
                return result;
            })


            




        } else {
			this.showFullPageLoader = false
		}
  }
}
</script> 
<style>

@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700&display=fallback');
@import url('./assets/adminlte3/plugins/fontawesome-free/css/all.min.css'); 
@import url('./assets/adminlte3/plugins/overlayScrollbars/css/OverlayScrollbars.min.css'); 
@import url('./assets/adminlte3/dist/css/adminlte.min.css');

/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
} */

#nav a.router-link-exact-active {
  color: #42b983;
}

.loading {
  position: fixed;
  z-index: 9999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
    background: radial-gradient(rgba(20, 20, 20,.8), rgba(0, 0, 0, .8));

  background: -webkit-radial-gradient(rgba(20, 20, 20,.8), rgba(0, 0, 0,.8));
}

.odd {
    background-color: #d5e7fa; /* #5e5959;*/
}


 
</style>
