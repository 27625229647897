<template>
    <FullPageLoader :showLoader="showFullPageLoader" />
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0">Manage Sub Users</h1>
          </div><!-- /.col -->
          <div class="col-sm-6"> 
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <section class="content text-sm">
      <div class="container-fluid">
        <!-- Info boxes -->
        <div class="row">
            <div class="col-md-8">
            <!-- general form elements -->
                <div class="card card-primary">
                    <div class="card-header">
                        <h3 class="card-title">Search</h3>
                    </div>
                    <form method="post" @submit.prevent="search()">
                        <div class="card-body">
                            <div class="row">
                                <div class="col">
                                    <div class="form-group">
                                        <label for="">Name</label>
                                        <input type="text" class="form-control form-control-sm" v-model="searchFrm.subuser_name">
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-group">
                                        <label for="">Email</label>
                                        <input type="email" class="form-control form-control-sm" v-model="searchFrm.subuser_email">
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-group">
                                        <label for="">EUIN Code</label>
                                        <input type="text" class="form-control form-control-sm" v-model="searchFrm.subuser_euin_code">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer">
                            <button class="btn btn-primary btn-sm mr-2">Search</button>
                            <button class="btn btn-warning btn-sm" type="button" @click="resetsearch()">Reset</button>
                        </div>
                    </form>
                    
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="card card-primary">
                    <div class="card-header">
                        <h3 class="card-title">List</h3>
                        <div class="float-right">Total: {{subusers.length}}</div>
                    </div>
                    <CSSLoader :showLoader="showLoader" /> 
                    <div class="table-responsive">
                        <table class="table table-bordered table-striped" v-if="!showLoader">
                            <tr> 
                                <th class="text-center">Status</th>
                                <th>Name</th>	
                                <th>Email</th>	
                                <th>Mobile</th>	
                                <th>EUIN Code</th>	
                                <th>Login</th>	
                                <th class="text-center">Action</th>
                            </tr>
                            <tr v-if="subusers.length == 0 && !showLoader">
                                <td colspan="7" class="text-center">
                                    <h4>No Sub User Found</h4>
                                </td>
                            </tr>
                            <tr v-for="(s, index) in subusers" :key="s.subuser_id" :class="{'odd': (index%2==0), 'even': (index%2!=0)}"> 
                                <td class="text-center">
                                    <a href="#" v-if="s.status == 0" @click.prevent="setStatus(s.subuser_id, 1, s)"><i class="fa fa-circle" style="color: red;"></i></a>
                                    <a href="#" v-if="s.status == 1" @click.prevent="setStatus(s.subuser_id, 0, s)"><i class="fa fa-circle" style="color: green;"></i></a>   
                                </td>
                                <td>
                                    {{s.subuser_firstname}} {{s.subuser_lastname}}
                                </td>	
                                <td>{{s.subuser_email}}</td>	
                                <td>{{s.subuser_mobile}}</td>	
                                <td>{{s.subuser_euin_code}}</td>	
                                <td>

                                    <i class="fas fa-user"></i> &nbsp;{{s.subuser_login}}<br />
                                    <i class="fas fa-key"></i> &nbsp;<code>{{s.subuser_password}}</code>
                                </td>	
                                <td class="text-center">
                                    <router-link :to="{ name: 'ModifySubUser', params: { id: s.subuser_id }}">
                                        <i class="fas fa-edit text-info"></i>
                                    </router-link>
                                    &nbsp;&nbsp;
                                    <a href="#" @click.prevent="sendCredentials(s.subuser_id)"><i class="fas fa-envelope text-warning"></i></a>
                                    &nbsp;&nbsp;
                                    <a href="#" @click.prevent="deleteSubUser(s.subuser_id)"><i class="fas fa-trash text-danger"></i></a>
                                    

                                </td>
                            </tr>
                            
                        </table>
                    </div>
                    
                </div>
            </div>
        </div>
      </div>
    </section> 


</template>

<script>

import { notify } from '@kyvg/vue3-notification'
import EventService from '@/services/EventService.js'
import { ref, reactive } from 'vue'
import { useRouter, useRoute } from 'vue-router'

export default {
    name: 'ManageSubUsers',
    
    setup() {
        const router = useRouter()
        const route = useRoute() 

        const searchFrm = reactive({
            subuser_name: '',
            subuser_email: '',
            subuser_euin_code: ''
        })

        const subusers = ref([])
        const showLoader = ref(false)
        const showFullPageLoader = ref(false)

        async function getSubUsers() {
            showLoader.value = true
            let subusers_ajax = await EventService.getPostData("post", "mfu/listSubUsers", searchFrm).then(response => {
                return response.data
            }) 
            .catch(error => { 
                console.log("This iwas an error!", error) 
                notify({
                    title: "Error",
                    text: error,
                    type: 'error'
                });
            })  
 
            subusers.value = subusers_ajax
            showLoader.value = false
 
        }

        getSubUsers()

        function search() {
            getSubUsers()
        }

        function resetsearch() {
            searchFrm.subuser_name = ''
            searchFrm.subuser_email = ''
            searchFrm.subuser_euin_code = ''

            getSubUsers()
        }

        function sendCredentials(id) {
            showFullPageLoader.value = true
            EventService.getPostData("post", "mfu/sendSubUserCredentials", {id: id}).then(response => {
                showFullPageLoader.value = false
                if(response.data.success == 1) { 
                    notify({
                        title: "Success",
                        text: response.data.msg,
                        type: 'success'
                    });
                } else {
                    notify({
                        title: "Error",
                        text: response.data.msg,
                        type: 'error'
                    });
                }
            }) 
            .catch(error => { 
                showFullPageLoader.value = false
                console.log("This iwas an error!", error) 
                notify({
                    title: "Error",
                    text: error,
                    type: 'error'
                });
            }) 
        }

        function deleteSubUser(id) {
            let c = confirm("Are you sure you wish to delete this?")
            if(c) {
                showFullPageLoader.value = true
                EventService.getPostData("post", "mfu/deleteSubUser", {id: id}).then(response => {
                    showFullPageLoader.value = false
                    if(response.data.success == 1) { 
                        notify({
                            title: "Success",
                            text: response.data.msg,
                            type: 'success'
                        });

                        setTimeout(() => {
                            resetsearch()
                        }, 3000);



                    } else { 
                        notify({
                            title: "Error",
                            text: response.data.msg,
                            type: 'error'
                        });
                    }
                }) 
                .catch(error => { 
                    showFullPageLoader.value = false
                    console.log("This iwas an error!", error) 
                    notify({
                        title: "Error",
                        text: error,
                        type: 'error'
                    });
                }) 
            }
        }

        function setStatus(id, st, s) {
            showFullPageLoader.value = true
            EventService.getPostData("post", "mfu/setSubUserStatus", {id: id, st: st}).then(response => {
                showFullPageLoader.value = false
                if(response.data.success == 1) {
                    s.status = st
                    notify({
                        title: "Success",
                        text: response.data.msg,
                        type: 'success'
                    });
                } else {
                    notify({
                        title: "Error",
                        text: response.data.msg,
                        type: 'error'
                    });
                }
            }) 
            .catch(error => { 
                showFullPageLoader.value = false
                console.log("This iwas an error!", error) 
                notify({
                    title: "Error",
                    text: error,
                    type: 'error'
                });
            })  
        }

        

        return { 
            searchFrm, subusers, showLoader, showFullPageLoader,
            search, resetsearch, sendCredentials, deleteSubUser, setStatus
        }
    }
}
</script>