<template>
    <ListSmartInvesting :sitype="'vstp'" :pagetitle="'Manage Value STP'" />
</template>

<script>
import ListSmartInvesting from '@/components/ListSmartInvesting.vue'
export default {
    name: 'ListValueStp',
    components: {
        ListSmartInvesting
    },
    setup() {

    }
}
</script>