<template>
    <div class="modal fade show" tabindex="-1" role="dialog">
        <div class="modal-dialog-scrollable modal-dialog modal-xl" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Add New Payeezz</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="closePopup()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <form @submit.prevent="savePRN()">
                    <div class="table-responsive">
                        <table class="table table-striped table-bordered">
                            <tr>
                                <th style="width:13%;"><strong>A/C No.</strong></th>
                                <td style="width:13%;">{{bankdetail.account_no}}</td>
                                <th style="width:15%;"><strong>Account Type</strong></th>
                                <td style="width:15%;">{{bankdetail.account_type_desc}}</td>
                                <th style="width:10%;"><strong>IFSC Code</strong></th>
                                <td style="width:10%;">{{bankdetail.ifsc_code}}</td>
                                <th style="width:10%;"><strong>MICR Code</strong></th>
                                <td style="width:10%;">{{bankdetail.micr_code}}</td>
                            </tr>
                            <tr>
                                <td><strong>Mode</strong></td>
                                <td colspan="3"> 
                                    <label><input type="radio" name="mode" value="OFFLINE" v-model="dataFrm.mode"> ALREADY REGISTERED</label> &nbsp;&nbsp; 
                                    <label><input type="radio" name="mode" value="ONLINE" v-model="dataFrm.mode"> CREATE NEW</label>  
                                    <div v-if="dataFrm.mode == 'OFFLINE'">
                                        <label><input type="checkbox" name="has_prn" id="has_prn" v-model="dataFrm.has_prn"> Check if you have PRN?</label>
                                    </div>

                                    <div v-if="v$.mode.$error" class="error">
                                        <!-- {{v$.mode.$errors[0].$message}} -->
                                        Please select a mode
                                    </div>
                                </td>
                                <td colspan="2" v-if="dataFrm.has_prn && dataFrm.mode == 'OFFLINE'"><strong>PRN <span class="text-danger">*</span></strong></td> 
                                <td colspan="2" v-if="dataFrm.has_prn && dataFrm.mode == 'OFFLINE'">
                                    <input type="text" name="prn" id="prn" class="form-control form-control-sm" v-model="dataFrm.prn">
                                    <div v-if="v$.prn.$error" class="error">
                                        {{v$.prn.$errors[0].$message}}
                                    </div>
                                </td>
                                <td colspan="2" v-if="!dataFrm.has_prn && dataFrm.mode == 'OFFLINE'"><strong>MMRN <span class="text-danger">*</span></strong></td>
                                <td colspan="2" v-if="!dataFrm.has_prn && dataFrm.mode == 'OFFLINE'">
                                    <input type="text" name="mmrn" id="mmrn" class="form-control form-control-sm" v-model="dataFrm.mmrn">

                                    <div v-if="v$.mmrn.$error" class="error">
                                        {{v$.mmrn.$errors[0].$message}}
                                    </div>
                                
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2">
                                    <strong>Max Amount for ePayEezz Registration <span class="text-danger">*</span></strong><br />
                                    <small class="text-danger">Maximum Amount for ePayEezz Registration<br />should be 1 Cr. Only and Minimum 500</small>
 
                                </td> 
                                <td colspan="2">
                                    <input type="text" name="max_amt" id="max_amt" class="form-control form-control-sm" v-model="dataFrm.max_amt">
                              
                                    <div v-if="v$.max_amt.$error" class="error">
                                        {{v$.max_amt.$errors[0].$message}}
                                    </div>
                                </td>
                                <td colspan="2"><strong>Perpetual Flag <span class="text-danger">*</span></strong></td>
                                <td colspan="2"> 
                                    <label><input type="radio" name="perpetual_flag" id="perpetual_flag" value="Y" v-model="dataFrm.perpetual_flag" :disabled="dataFrm.mode == 'ONLINE'"> Yes</label> &nbsp;&nbsp; 
                                    <label><input type="radio" name="perpetual_flag" id="perpetual_flag" value="N" v-model="dataFrm.perpetual_flag" :disabled="dataFrm.mode == 'ONLINE'"> No</label>  
                                
                                    <div v-if="v$.perpetual_flag.$error" class="error">
                                        {{v$.perpetual_flag.$errors[0].$message}}
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2">
                                    <strong>Registration start date <span class="text-danger">*</span></strong>
                                </td> 
                                <td colspan="2">
                                    <input type="date" name="start_date" id="start_date" class="form-control form-control-sm" v-model="dataFrm.start_date" :min="checkMinDate">

                                    <div v-if="v$.start_date.$error" class="error">
                                        <!-- {{v$.start_date.$errors[0].$message}} -->
                                        Please select a start date
                                    </div>

                                </td>
                                <td colspan="2"><strong>Registration end date <span class="text-danger" v-if="dataFrm.perpetual_flag == 'N'">*</span></strong></td>
                                <td colspan="2">
                                    <input type="date" name="end_date" id="end_date" class="form-control form-control-sm" v-model="dataFrm.end_date" :disabled="dataFrm.perpetual_flag == 'Y'" :max="checkMaxDate">

                                    <div v-if="v$.end_date.$error" class="error">
                                        <!-- {{v$.end_date.$errors[0].$message}} -->
                                        Please select an end date
                                    </div>
                                </td>
                            </tr>
                            <tr v-if="dataFrm.mode == 'ONLINE'">
                                <th colspan="2">Payment Aggregator Option <span class="text-danger">*</span></th>
                                <th colspan="2">
                                    <select class="form-control" name="reg_mode" v-model="dataFrm.reg_mode">
                                        <option value="">-- Select --</option>
                                        <option value="PN">Payment Net-banking Mode</option>
                                        <option value="PD">Payment Debit Card Mode</option>
                                    </select>

                                    <div v-if="v$.reg_mode.$error" class="error">
                                        {{v$.reg_mode.$errors[0].$message}}
                                    </div>
                                    
                                </th>
                                <th colspan="2"></th>
                                <th colspan="2"></th>
                            </tr>
                        </table>
                    </div>
                    <div class="modal-body">  
                        <small class="text-danger">#Note : Banks enabled by NPCI (please refer to https://www.mfuindia.com/ePayEezz-FAQ for list of banks ) are allowed for ePayEezz. The Maximum limit for ePayEezz is INR 50,00,000/-. Bank account types allowed are SB / CA / OTHER. If the customer A/c Type is SB-NRE & SB-NRO - it can be considered as SB-SAVINGS & for OD / CC – you can consider as OTHER.</small>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-primary btn-sm">Submit</button>
                    </div>
                </form>

            </div>
        </div>
    </div>
</template>

<script>
import { notify } from '@kyvg/vue3-notification'
import useVuelidate from "@vuelidate/core";
import { required, numeric, requiredIf, minValue, maxValue, helpers } from '@vuelidate/validators'
import { ref, reactive, watch, computed } from 'vue'
import EventService from '@/services/EventService.js'
import moment from 'moment'
export default {
    name: 'AddPayeezz',
    props: ["canid", "bankdetail"],
    setup(props, context) {  
        const dataFrm = reactive({
            mode: null,
            has_prn: null,
            prn: null,
            mmrn: null,
            max_amt: null,
            perpetual_flag: null,
            start_date: null,
            end_date: null,
            name: null,
            reg_mode: ''
        })
        

        const validationRules = computed(() => {
            const rules = {
                mode: { required },
                perpetual_flag: { required },
                max_amt: { required, numeric, minValueValue: minValue(500) }, 
                prn: {
                    requiredIfFoo: requiredIf(dataFrm.has_prn && dataFrm.mode == 'OFFLINE') 
                },
                mmrn: {
                    requiredIfFoo: requiredIf(!dataFrm.has_prn && dataFrm.mode == 'OFFLINE') 
                },
                start_date: { required },  
                end_date: {
                    requiredIfFoo: requiredIf(dataFrm.perpetual_flag == 'N') 
                },
                reg_mode: { 
                    requiredIfFoo: requiredIf(dataFrm.mode == 'ONLINE') 
                },
            }

            if(dataFrm.mode == 'ONLINE') {
                rules.max_amt.maxValueValue = maxValue(10000000)
            }

            

            return rules
        }) 

        const v$ = useVuelidate(validationRules, dataFrm)

        function savePRN() { 
            // this.v$.$touch() 
            this.v$.$validate()
            // console.log(this.v$)
            if(this.v$.$error) {
                notify({
                    title: "Error",
                    text: 'Please fill all mandatory fields with (*) mark',
                    type: 'error'
                }); 
                return false
            } 

            let dataToSend = dataFrm
            dataToSend.bid = props.bankdetail.id
            // dataToSend.max_amt = parseFloat(dataToSend.max_amt)

            EventService.getPostData("post", "mfu/submitPayEezz", dataToSend)
            .then(response => {
                console.log(response.data)  
                if(response.data.success == 1) {
                    notify({
                        title: "Success",
                        text: response.data.msg,
                        type: 'success'
                    });

                    closePopup()
                } else {
                    notify({
                        title: "Error",
                        text: response.data.msg,
                        type: 'error'
                    });
                }

                
            })
            .catch(error => {  
                console.log("This iwas an error!", error)
                notify({
                    title: "Error",
                    text: error,
                    type: 'error'
                });
            })
            


        }

        function closePopup() { 
            context.emit("closePopup")
        }

        // function showdetail() {
        //     console.log("bankdetail", props.bankdetail)
        // }

        // showdetail()
 

        watch(() => dataFrm.perpetual_flag, (currentValue, oldValue) => {
            // console.log(currentValue)      
            // console.log(oldValue)
            if(currentValue == 'Y') {
                dataFrm.end_date = null
            }
        })


        watch(() => dataFrm.mode, (currentValue, oldValue) => {
            // console.log(currentValue)      
            // console.log(oldValue)
            if(currentValue == 'ONLINE') {
                dataFrm.perpetual_flag = 'N'
                dataFrm.start_date = null
                dataFrm.end_date = null
            } else {
                dataFrm.perpetual_flag = null
            }
        })

        const checkMinDate = computed(() => {
            let minDate = '';
            if(dataFrm.mode == 'ONLINE') {
                minDate = moment(moment(), "YYYY-MM-DD").add(3, 'days').format("YYYY-MM-DD")
            } else {
                minDate = ''
            }
 
            return minDate
        })

        const checkMaxDate = computed(() => {
            let maxDate = '';
            if(dataFrm.mode == 'ONLINE') {
                maxDate = moment(moment(), "YYYY-MM-DD").add(40, 'year').format("YYYY-MM-DD")
            } else {
                maxDate = ''
            }
 
            return maxDate
        })

        return {
            closePopup, dataFrm, v$, savePRN, checkMinDate, checkMaxDate       
        }
    }
}
</script>

<style scoped> 
.error {
    color: red;
}
.modal {
    display: block;
}

.modal-content {
    text-align: left; 
}
 

code {
    font-size: 11px;
}

.loader {
  left: 50%;
  top: 50%;
  position: absolute;
}
 
</style>