<template>
    <FullPageLoader :showLoader="showFullPageLoader" />
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0">Sub User</h1>
          </div><!-- /.col -->
          <div class="col-sm-6"> 
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <!-- Main content -->
    <section class="content">
      <div class="container-fluid">
        <!-- Info boxes -->
        <div class="row">
            <div class="col-md-12">
            <!-- general form elements -->
                <div class="card card-primary">
                    <div class="card-header">
                        <h3 class="card-title">{{dataFrm.subuser_id > 0 ? 'Modify': 'Add'}}</h3>
                    </div>
                 
                    <form method="post" @submit.prevent="submit()">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label class="label-bold">First Name <span class="mandatory">&#42;</span></label>
                                        <input type="text" class="form-control form-control-sm" id="subuser_firstname" name="subuser_firstname" v-model="dataFrm.subuser_firstname" placeholder="" maxlength="255" required="" />

                                    </div>


                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label class="label-bold">Last Name <span class="mandatory">&#42;</span> </label>
                                        <input type="text" class="form-control form-control-sm" id="subuser_lastname" name="subuser_lastname" v-model="dataFrm.subuser_lastname" placeholder="" maxlength="255" required="" />

                                    </div>


                                </div>

                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label class="label-bold">Email <span class="mandatory">&#42;</span> </label>
                                        <input type="email" class="form-control form-control-sm" id="subuser_email" name="subuser_email" v-model="dataFrm.subuser_email" placeholder="" maxlength="255" required="" />

                                    </div>


                                </div>

                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label class="label-bold">Mobile <span class="mandatory">&#42;</span> </label>
                                        <input type="text" class="form-control  form-control-sm" id="subuser_mobile" name="subuser_mobile" v-model="dataFrm.subuser_mobile" placeholder="" maxlength="255" required="" />

                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label class="label-bold">EUIN Code <span class="mandatory">&#42;</span> </label>
                                        <input type="text" class="form-control  form-control-sm" id="subuser_euin_code" name="subuser_euin_code" v-model="dataFrm.subuser_euin_code" placeholder="" maxlength="255" required="" />

                                    </div>
                                </div>


                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="callout callout-info">
                                        Login Details <span class="mandatory">&#42;</span>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label class="label-bold">Username <span class="mandatory">&#42;</span> </label>
                                        <input type="text" class="form-control form-control-sm" id="subuser_login" name="subuser_login" v-model="dataFrm.subuser_login" placeholder="" maxlength="15" required="" :disabled="dataFrm.subuser_id" />

                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label class="label-bold">Password <span class="mandatory">&#42;</span> </label>
                                        <input type="text" class="form-control form-control-sm" id="subuser_password" name="subuser_password" v-model="dataFrm.subuser_password" placeholder="" maxlength="20" required="" />
                                         
                                    </div>
                                </div>


                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="callout callout-info">
                                        Status <span class="mandatory">&#42;</span>
                                    </div>
                                </div>

                                <div class="col-sm-6">

                                    <div class="form-group">
                                         
                                        <div class="row">
                                            <div class="col-auto">
                                                <div class="custom-control custom-radio">
                                                    <input type="radio" id="status_active" name="status" v-model="dataFrm.status" value="1" class="custom-control-input" required="">
                                                    <label class="custom-control-label text-uppercase" for="status_active">Active</label>
                                                </div>
                                            </div>
                                            <div class="col-auto">
                                                <div class="custom-control custom-radio">
                                                    <input type="radio" id="status_inactive" name="status" v-model="dataFrm.status" value="0" class="custom-control-input" required="">
                                                    <label class="custom-control-label text-uppercase" for="status_inactive">Inactive</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            
                            
                        </div>
                        <!-- /.card-body -->

                        <div class="card-footer">
                            <button type="submit" class="btn btn-primary mr-2" :disabled="submitLoader">
                                <i class="fa fa-spinner fa-spin" v-if="submitLoader"></i>   
                                Submit
                            </button>
                            <button class="btn btn-danger" type="button" :disabled="submitLoader" @click="cancel()">Cancel</button>
                        </div>
                    </form>
                </div>
                <!-- /.card -->
 

            
             
             

          </div>
             
        </div>
      </div>
    </section>
</template>

<script>
import { notify } from '@kyvg/vue3-notification'
import EventService from '@/services/EventService.js'
import { ref, reactive } from 'vue'
import { useRouter, useRoute } from 'vue-router'
export default {
    name: 'SubUser',
    setup() {
        const router = useRouter()
        const route = useRoute()
        const showFullPageLoader = ref(false)
        const submitLoader = ref(false)
        const dataFrm = reactive({
            subuser_id: 0,
            subuser_firstname: '',
            subuser_lastname: '',
            subuser_email: '',
            subuser_mobile: '',
            subuser_euin_code: '',
            subuser_login: '',
            subuser_password: '',
            status: 1
        })
 

        function submit() {
            submitLoader.value = true

            EventService.getPostData('post', 'mfu/saveSubUser', dataFrm).then(response => {
                // console.log(response.data) 
                submitLoader.value = false
                if(parseInt(response.data.success) == parseInt(0)) {
                    let { subuser_firstname, subuser_lastname, subuser_email, subuser_mobile, subuser_euin_code, subuser_login   } = response.data.errors 
                    
                    let submiterror = []
                    if(subuser_firstname) {
                        submiterror.push(...subuser_firstname)
                    }

                    if(subuser_lastname) {
                        submiterror.push(...subuser_lastname)
                    }

                    if(subuser_email) {
                        submiterror.push(...subuser_email)
                    }

                    if(subuser_mobile) {
                        submiterror.push(...subuser_mobile)
                    }

                    if(subuser_euin_code) {
                        submiterror.push(...subuser_euin_code)
                    }

                    if(subuser_login) {
                        submiterror.push(...subuser_login)
                    }
 
                    // console.log("sss", submiterror);
                    for(let i = 0; i < submiterror.length; i++) {
                        notify({ 
                            duration: 10000,
                            title: "Warning",
                            text: submiterror[i],
                            type: 'warn' 
                        });
                    }
                } else {
                    notify({
                        title: "Success",
                        text: response.data.msg,
                        type: 'success'
                    });

                    setTimeout(() => {
                        cancel()
                    }, 3000)
                }


            })
            .catch(error => { 
                console.log("This iwas an error!", error)
                submitLoader.value = false
                notify({
                    title: "Error",
                    text: error,
                    type: 'error'
                });
            })
        }

        function cancel() {
            // console.log("cccc")
            router.push({ name: 'ManageSubUsers', params: { page: 1 } })
        }

        // console.log(route.params.id)

        async function getSubUserDetails(id) {
            showFullPageLoader.value = true
            let subuserdetail = await EventService.getPostData('get', 'mfu/getSubUser/' + id, dataFrm).then(response => {
                // console.log(response);
                showFullPageLoader.value = false
                return response.data    
            }).catch(error => { 
                console.log("This iwas an error!", error)
                showFullPageLoader.value = false
                notify({
                    title: "Error",
                    text: error,
                    type: 'error'
                });
            })

            console.log("subuserdetail", subuserdetail)

            if(subuserdetail.success == 0) {
                notify({
                    duration: 10000,
                    title: "Error",
                    text: subuserdetail.msg,
                    type: 'error'
                });

                setTimeout(() => {
                        cancel()
                }, 3000)
            } else {
                dataFrm.subuser_id = subuserdetail.data.subuser_id
                dataFrm.subuser_firstname = subuserdetail.data.subuser_firstname
                dataFrm.subuser_lastname = subuserdetail.data.subuser_lastname
                dataFrm.subuser_email = subuserdetail.data.subuser_email
                dataFrm.subuser_mobile = subuserdetail.data.subuser_mobile
                dataFrm.subuser_euin_code = subuserdetail.data.subuser_euin_code
                dataFrm.subuser_login = subuserdetail.data.subuser_login
                dataFrm.subuser_password = subuserdetail.data.subuser_password
                dataFrm.status = subuserdetail.data.status
                
            }
        }

        if(route.params.id) {
            getSubUserDetails(route.params.id)
        }

        return { dataFrm, submitLoader, submit, cancel, showFullPageLoader }
    }
}
</script>

<style scoped>
.mandatory {
    color: red;
}
</style>
