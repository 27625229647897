<template>
    <FullPageLoader :showLoader="showFullPageLoader" />
    <div class="content-header">
      <div class="container-fluid d-flex justify-content-center">
        <div class="row mb-2">
          <div class="col-sm-12 text-center">
            <h1 class="m-0 mb-3">
                Transactions
                <span v-if="for_date && for_date != ''"> for <u>{{getHumanDate(for_date)}}</u></span>
                
            </h1>
            <div class="float-end"><input type="date" class="form-control" v-model="for_date" @change="setForDate()"></div>
          </div><!-- /.col -->
          <!--<div class="col-sm-12 text-right"> 
            
          </div>--><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <section class="content text-sm">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <div class="alert alert-info">If the page refreshed or you received an error, please check with MFU or Contact us before Re-Initiating again</div> 
                    <div class="alert alert-warning">** Transactions done from here will not be executed again for the same month. Contact Jay in case of clarity</div> 
                </div>
                <div class="col-md-12">
                    <div class="row" v-if="!showFullPageLoader && for_date && for_date != ''">
                        <div class="col-md-12">

                            <div class="row" v-if="tabfor=='all'">
                                <div class="col-lg-3 col-6" > <!-- v-if="tab_counter.UNTRIGGERED_TXN_DATES[for_date] > 0" -->
                                    <div class="small-box bg-info">
                                        <div class="inner">
                                            <h3>{{tab_counter.UNTRIGGERED_TXN_DATES[for_date] || 0}}</h3>
                                            <p>{{checkIfFutureDate(for_date) ? 'Future':'Untriggered'}} SIP's</p>
                                        </div>
                                        <div class="icon">
                                            <i class="ion ion-bag"></i>
                                        </div>
                                        <a :href="'#/list-unprocessed-transactions?tabfor=untriggered-sip&for_date=' + for_date" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a>
                                    </div>
                                </div>

                                <div class="col-lg-3 col-6" v-if="tab_counter.FAILED_TXN_DATES[for_date] > 0"> 
                                    <div class="small-box bg-info">
                                        <div class="inner">
                                            <h3>{{tab_counter.FAILED_TXN_DATES[for_date]}}</h3>
                                            <p>Failed SIP's</p>
                                        </div>
                                        <div class="icon">
                                            <i class="ion ion-bag"></i>
                                        </div>
                                        <a :href="'#/list-unprocessed-transactions?tabfor=failed-sip&for_date=' + for_date" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a>
                                    </div>
                                </div>

                                <div class="col-lg-3 col-6"> <!--  v-if="tab_counter.UNTRIGGERED_SI_SWITCH_TXN_DATES[for_date] > 0" -->
                                    <div class="small-box bg-info">
                                        <div class="inner">
                                            <h3>{{tab_counter.UNTRIGGERED_SI_SWITCH_TXN_DATES[for_date] || 0}}</h3>
                                            <p>{{checkIfFutureDate(for_date) ? 'Future':'Untriggered'}} SIP/Lumpsum SI Switch</p>
                                        </div>
                                        <div class="icon">
                                            <i class="ion ion-bag"></i>
                                        </div>
                                        <a :href="'#/list-unprocessed-transactions?tabfor=untriggered-si-switch&for_date=' + for_date" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a>
                                    </div>
                                </div>

                                <div class="col-lg-3 col-6" v-if="tab_counter.FAILED_SI_SWITCH_TXN_DATES[for_date] > 0"> 
                                    <div class="small-box bg-warning">
                                        <div class="inner">
                                            <h3>{{tab_counter.FAILED_SI_SWITCH_TXN_DATES[for_date]}}</h3>
                                            <p>Failed SI Switch</p>
                                        </div>
                                        <div class="icon">
                                            <i class="ion ion-bag"></i>
                                        </div>
                                        <a :href="'#/list-unprocessed-transactions?tabfor=failed-si-switch&for_date=' + for_date" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a>
                                    </div>
                                </div>

                                <div class="col-lg-3 col-6"> <!--  v-if="tab_counter.UNTRIGGERED_VSTP_TXN_DATES[for_date] > 0" --> 
                                    <div class="small-box bg-danger">
                                        <div class="inner">
                                            <h3>{{tab_counter.UNTRIGGERED_VSTP_TXN_DATES[for_date] || 0}}</h3>
                                            <p>{{checkIfFutureDate(for_date) ? 'Future':'Untriggered'}} Value STP</p>
                                        </div>
                                        <div class="icon">
                                            <i class="ion ion-bag"></i>
                                        </div>
                                        <a :href="'#/list-unprocessed-transactions?tabfor=untriggered-vstp&for_date=' + for_date" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a>
                                    </div>
                                </div>

                                <div class="col-lg-3 col-6" v-if="tab_counter.FAILED_VSTP_TXN_DATES[for_date] > 0"> 
                                    <div class="small-box bg-info">
                                        <div class="inner">
                                            <h3>{{tab_counter.FAILED_VSTP_TXN_DATES[for_date]}}</h3>
                                            <p>Failed Value STP</p>
                                        </div>
                                        <div class="icon">
                                            <i class="ion ion-bag"></i>
                                        </div>
                                        <a :href="'#/list-unprocessed-transactions?tabfor=failed-vstp&for_date=' + for_date" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a>
                                    </div>
                                </div>

                                <div class="col-lg-3 col-6"> <!--  v-if="tab_counter.UNTRIGGERED_VSTP_SWITCH_TXN_DATES[for_date] > 0" --> 
                                    <div class="small-box bg-warning">
                                        <div class="inner">
                                            <h3>{{tab_counter.UNTRIGGERED_VSTP_SWITCH_TXN_DATES[for_date] || 0}}</h3>
                                            <p>{{checkIfFutureDate(for_date) ? 'Future':'Untriggered'}} Value STP SI Switch</p>
                                        </div>
                                        <div class="icon">
                                            <i class="ion ion-bag"></i>
                                        </div>
                                        <a :href="'#/list-unprocessed-transactions?tabfor=untriggered-vstp-switch&for_date=' + for_date" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a>
                                    </div>
                                </div>

                                <div class="col-lg-3 col-6" v-if="tab_counter.FAILED_VSTP_SWITCH_TXN_DATES[for_date] > 0"> 
                                    <div class="small-box bg-danger">
                                        <div class="inner">
                                            <h3>{{tab_counter.FAILED_VSTP_SWITCH_TXN_DATES[for_date]}}</h3>
                                            <p>Failed Value STP Switch</p>
                                        </div>
                                        <div class="icon">
                                            <i class="ion ion-bag"></i>
                                        </div>
                                        <a :href="'#/list-unprocessed-transactions?tabfor=failed-vstp-switch&for_date=' + for_date" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a>
                                    </div>
                                </div>

                                <div class="col-lg-3 col-6" style="display: none;"><!--  v-if="tab_counter.SUCCESS_TXN_DATES[for_date] > 0" -->  
                                    <div class="small-box bg-success">
                                        <div class="inner">
                                            <h3>{{tab_counter.SUCCESS_TXN_DATES[for_date] || 0}}</h3>
                                            <p>Successful Txn.</p>
                                        </div>
                                        <div class="icon">
                                            <i class="ion ion-bag"></i>
                                        </div>
                                        <a :href="'#/list-unprocessed-transactions?tabfor=success-txn&for_date=' + for_date" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a>
                                    </div>
                                </div>

                                <div class="col-lg-3 col-6">  
                                    <div class="small-box bg-secondary">
                                        <div class="inner">
                                            <h3>{{tab_counter.SUCCESS_OTP_PENDING_TXN_DATES[for_date] || 0}}</h3>
                                            <p>Successfully Created</p> <!--  - OTP Pending/Failed -->
                                        </div>
                                        <div class="icon">
                                            <i class="ion ion-bag"></i>
                                        </div>
                                        <a :href="'#/list-unprocessed-transactions?tabfor=success-otp-pending-txn&for_date=' + for_date" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-6" style="display: none;">  
                                    <div class="small-box bg-success">
                                        <div class="inner">
                                            <h3>{{tab_counter.SUCCESS_OTP_SUBMITTED_TXN_DATES[for_date] || 0}}</h3>
                                            <p>Successful - OTP Submitted</p>
                                        </div>
                                        <div class="icon">
                                            <i class="ion ion-bag"></i>
                                        </div>
                                        <a :href="'#/list-unprocessed-transactions?tabfor=success-otp-submitted-txn&for_date=' + for_date" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a>
                                    </div>
                                </div>

                                <div class="col-lg-3 col-6"> 
                                    <div class="small-box bg-info">
                                        <div class="inner">
                                            <h3>{{tab_counter.PAUSED_COUNT}}</h3>
                                            <p>Paused SI</p>
                                        </div>
                                        <div class="icon">
                                            <i class="ion ion-bag"></i>
                                        </div>
                                        <a :href="'#/list-unprocessed-transactions?tabfor=paused-triggers&for_date=' + for_date" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a>
                                    </div>
                                </div>

                                <div class="col-lg-3 col-6"> 
                                    <div class="small-box bg-warning">
                                        <div class="inner">
                                            <h3>{{tab_counter.UNPAUSED_COUNT}}</h3>
                                            <p>Unpaused SI</p>
                                        </div>
                                        <div class="icon">
                                            <i class="ion ion-bag"></i>
                                        </div>
                                        <a :href="'#/list-unprocessed-transactions?tabfor=unpaused-triggers&for_date=' + for_date" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a>
                                    </div>
                                </div>

                                <div class="col-lg-3 col-6"> 
                                    <div class="small-box bg-danger">
                                        <div class="inner">
                                            <h3>{{tab_counter.FOLIO_UNMAPPED_COUNT}}</h3>
                                            <p>Paused SI - Folio Not Mapped</p>
                                        </div>
                                        <div class="icon">
                                            <i class="ion ion-bag"></i>
                                        </div>
                                        <a :href="'#/list-unprocessed-transactions?tabfor=folio-unmapped-triggers&for_date=' + for_date" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a>
                                    </div>
                                </div>


 


                            </div> 

                            <ul class="nav nav-tabs" style="font-size: 12px;" v-if="tabfor!='all'">
                                <!-- <li class="nav-item">
                                    <a class="nav-link"  :class="{ active: (tabfor=='all') }" :href="'#/list-unprocessed-transactions?tabfor=all&for_date=' + for_date">All ({{getAllCount()}})</a>
                                </li> -->
                                <li class="nav-item" v-if="tab_counter.UNTRIGGERED_TXN_DATES[for_date] > 0">
                                    <a class="nav-link" :class="{ active: (tabfor=='untriggered-sip') }" :href="'#/list-unprocessed-transactions?tabfor=untriggered-sip&for_date=' + for_date">{{checkIfFutureDate(for_date) ? 'Future':'Untriggered'}} SIP's ({{tab_counter.UNTRIGGERED_TXN_DATES[for_date]}})</a>
                                </li>
                                <li class="nav-item" v-if="tab_counter.FAILED_TXN_DATES[for_date] > 0">
                                    <a class="nav-link" :class="{ active: (tabfor=='failed-sip') }" :href="'#/list-unprocessed-transactions?tabfor=failed-sip&for_date=' + for_date">Failed SIP's ({{tab_counter.FAILED_TXN_DATES[for_date]}})</a>
                                </li>

                                <li class="nav-item" v-if="tab_counter.UNTRIGGERED_SI_SWITCH_TXN_DATES[for_date] > 0">
                                    <a class="nav-link" :class="{ active: (tabfor=='untriggered-si-switch') }" :href="'#/list-unprocessed-transactions?tabfor=untriggered-si-switch&for_date=' + for_date">{{checkIfFutureDate(for_date) ? 'Future':'Untriggered'}} SIP/Lumpsum SI Switch ({{tab_counter.UNTRIGGERED_SI_SWITCH_TXN_DATES[for_date]}})</a>
                                </li>
                                <li class="nav-item" v-if="tab_counter.FAILED_SI_SWITCH_TXN_DATES[for_date] > 0">
                                    <a class="nav-link" :class="{ active: (tabfor=='failed-si-switch') }" :href="'#/list-unprocessed-transactions?tabfor=failed-si-switch&for_date=' + for_date">Failed SI Switch ({{tab_counter.FAILED_SI_SWITCH_TXN_DATES[for_date]}})</a>
                                </li>

                                <li class="nav-item" v-if="tab_counter.UNTRIGGERED_VSTP_TXN_DATES[for_date] > 0">
                                    <a class="nav-link " :class="{ active: (tabfor=='untriggered-vstp') }" :href="'#/list-unprocessed-transactions?tabfor=untriggered-vstp&for_date=' + for_date">{{checkIfFutureDate(for_date) ? 'Future':'Untriggered'}} Value STP ({{tab_counter.UNTRIGGERED_VSTP_TXN_DATES[for_date]}})</a>
                                </li>
                                <li class="nav-item" v-if="tab_counter.FAILED_VSTP_TXN_DATES[for_date] > 0">
                                    <a class="nav-link" :class="{ active: (tabfor=='failed-vstp') }" :href="'#/list-unprocessed-transactions?tabfor=failed-vstp&for_date=' + for_date">Failed Value STP ({{tab_counter.FAILED_VSTP_TXN_DATES[for_date]}})</a>
                                </li>

                                <li class="nav-item" v-if="tab_counter.UNTRIGGERED_VSTP_SWITCH_TXN_DATES[for_date] > 0">
                                    <a class="nav-link" :class="{ active: (tabfor=='untriggered-vstp-switch') }" :href="'#/list-unprocessed-transactions?tabfor=untriggered-vstp-switch&for_date=' + for_date">{{checkIfFutureDate(for_date) ? 'Future':'Untriggered'}} Value STP SI Switch ({{tab_counter.UNTRIGGERED_VSTP_SWITCH_TXN_DATES[for_date]}})</a>
                                </li>
                                <li class="nav-item" v-if="tab_counter.FAILED_VSTP_SWITCH_TXN_DATES[for_date] > 0">
                                    <a class="nav-link" :class="{ active: (tabfor=='failed-vstp-switch') }" :href="'#/list-unprocessed-transactions?tabfor=failed-vstp-switch&for_date=' + for_date">Failed Value STP Switch ({{tab_counter.FAILED_VSTP_SWITCH_TXN_DATES[for_date]}})</a>
                                </li>     
                                <li class="nav-item" v-if="tab_counter.SUCCESS_TXN_DATES[for_date] > 0" style="display: none;">
                                    <a class="nav-link" :class="{ active: (tabfor=='success-txn') }" :href="'#/list-unprocessed-transactions?tabfor=success-txn&for_date=' + for_date"> Successful Txn. ({{tab_counter.SUCCESS_TXN_DATES[for_date]}})</a>
                                </li>

                                <li class="nav-item" v-if="tab_counter.SUCCESS_OTP_PENDING_TXN_DATES[for_date] > 0">
                                    <a class="nav-link" :class="{ active: (tabfor=='success-otp-pending-txn') }" :href="'#/list-unprocessed-transactions?tabfor=success-otp-pending-txn&for_date=' + for_date"> Successfully Created ({{tab_counter.SUCCESS_OTP_PENDING_TXN_DATES[for_date]}})</a> <!--  - OTP Pending/Failed -->
                                </li>

                                <li class="nav-item" v-if="tab_counter.SUCCESS_OTP_SUBMITTED_TXN_DATES[for_date] > 0">
                                    <a class="nav-link" :class="{ active: (tabfor=='success-otp-submitted-txn') }" :href="'#/list-unprocessed-transactions?tabfor=success-otp-submitted-txn&for_date=' + for_date"> Successful - OTP Submitted ({{tab_counter.SUCCESS_OTP_SUBMITTED_TXN_DATES[for_date]}})</a>
                                </li>


                                <li class="nav-item">
                                    <a class="nav-link" :class="{ active: (tabfor=='paused-triggers') }" :href="'#/list-unprocessed-transactions?tabfor=paused-triggers&for_date=' + for_date"> Paused SI ({{tab_counter.PAUSED_COUNT}})</a>
                                </li>  
                                <li class="nav-item">
                                    <a class="nav-link" :class="{ active: (tabfor=='unpaused-triggers') }" :href="'#/list-unprocessed-transactions?tabfor=unpaused-triggers&for_date=' + for_date"> Unpaused SI ({{tab_counter.UNPAUSED_COUNT}})</a>
                                </li>   
                                <li class="nav-item">
                                    <a class="nav-link " :class="{ active: (tabfor=='folio-unmapped-triggers') }" :href="'#/list-unprocessed-transactions?tabfor=folio-unmapped-triggers&for_date=' + for_date"> Paused SI - Folio Not Mapped ({{tab_counter.FOLIO_UNMAPPED_COUNT}})</a>
                                </li>                
                            </ul>
                        </div>
                    </div>


                </div>
                <div class="col-md-12" v-if="!showFullPageLoader && for_date && for_date != ''">
                    <!-- <div v-if="tabfor=='all'"> 
 
                        <table class="table table-striped table-bordered" style="font-size: 11px;">
                            <tr>
                                <th>Status</th>
                                <th>CAN No.</th>
                                <th>Folio No.</th>
                                <th>CAN Name</th>
                                <th>Scheme</th>
                                <th>Amount/Units</th>
                                <th>Errors</th>
                                <th>Action</th>
                            </tr>
                            <tr v-for="o in allArray" :key="o">
                                <td>
                                    {{o.tstatus}}<br />
                                    {{o.otxn_type}}
                                </td>
                                <td>{{o.can}}</td>
                                <td>{{o.folio_no}}</td>
                                <td>
                                    {{o.can_name}}
                                    <div>
                                        <span v-for="(value_inn, key_inn) in o.other_holders" :key="key_inn" style="font-size: 10px; color: orange;">{{value_inn}}<br /></span> 
                                    </div>
                                </td>
                                <td>
                                    <span v-if="o.tstatus == 'Future SIP' || o.tstatus == 'Untriggered SIP' || o.tstatus == 'Failed SIP' || (o.otrigger_type == 'purchase' && o.tstatus == 'Successful')">{{o.scheme_to_purchase}}</span>

                                    <span v-if="o.tstatus == 'Future SI Switch' || o.tstatus == 'Untriggered SI Switch' || o.tstatus == 'Failed SI Switch' || (o.otrigger_type == 'switchfund' && o.tstatus == 'Successful')">
                                        <strong>Switch From:</strong> {{o.scheme_from}}<br />
                                        <strong>Switch To:</strong> {{o.scheme_to}}
                                    </span>


                                    <span v-if="o.tstatus == 'Future Value STP' || o.tstatus == 'Untriggered Value STP' || o.tstatus == 'Failed Value STP' || (o.otrigger_type == 'stpfund' && o.tstatus == 'Successful')">
                                        <strong>STP From:</strong> {{o.scheme_from}}<br />
                                        <strong>STP To:</strong> {{o.scheme_to}}
                                    </span>


                                    <span v-if="o.tstatus == 'Future Value STP Switch' || o.tstatus == 'Untriggered Value STP Switch' || o.tstatus == 'Failed Value STP Switch'">
                                        <strong>Switch From:</strong> {{o.scheme_from}}<br />
                                        <strong>Switch To:</strong> {{o.scheme_to}}
                                    </span>

                                    <div v-if="checkIfFutureDate(for_date)">
                                        <small style="color: #ff0000;">*Subject to Actual Algo</small>
                                    </div>

                                </td>
                                <td>
                                    <span v-if="o.tstatus == 'Future SIP' || o.tstatus == 'Untriggered SIP' || o.tstatus == 'Failed SIP'">{{Math.round(o.initial_amount)}}</span>
                                    <span v-if="o.tstatus == 'Future SI Switch' || o.tstatus == 'Untriggered SI Switch' || o.tstatus == 'Failed SI Switch' || o.tstatus == 'Untriggered Value STP Switch' || o.tstatus == 'Failed Value STP Switch'">All Units</span>
                                    <span v-if="o.tstatus == 'Future Value STP' || o.tstatus == 'Untriggered Value STP' || o.tstatus == 'Failed Value STP'">
                                        {{Math.round(o.stp_amount)}}
                                    </span>

                                    <span v-if="o.tstatus == 'Successful'">
                                        {{Math.round(o.purchase_amount)}}
                                    </span>
                                </td>
                                <td> 
                                    <span v-if="o.mfu_response && o.mfu_response.length > 0">
                                        {{o.mfu_response}}
                                    </span>
                                    <span v-if="!o.mfu_response">
                                        {{o.tstatus}}
                                    </span>
                                </td>
                                <td>
                                    --
                                </td>
                            </tr>
                        </table>
                    </div> -->
                    <div v-if="tabfor=='untriggered-sip'" > 
                        <table class="table table-striped table-bordered" style="font-size: 11px;">
                            <tr>
                                <th v-if="allowRetrigger()" >
                                    <input type="checkbox" v-model="checkall.untriggered_sip" @click="checkallFunc('untriggered_sip', untriggeredSIPsArrayFiltered)" value="1" :disabled="usis_search_by_can || usis_search_by_folio || usis_search_by_name" >
                                </th>
                                <th>CAN No.</th>
                                <th>Folio No.</th>
                                <th>CAN Name</th>
                                
                                <th>Scheme</th>
                                <th>SIP Amount</th>
                                <th>Action</th>
                            </tr>
                            <tr v-if="allowRetrigger()" >
                                <th></th>
                                <th><input type="text" class="form-control" v-model="usis_search_by_can" placeholder="Enter CAN No." style="padding: 5px; font-size: 10px;"></th>
                                <th><input type="text" class="form-control" v-model="usis_search_by_folio" placeholder="Enter Folio No." style="padding: 5px; font-size: 10px;"></th>
                                <th><input type="text" class="form-control" v-model="usis_search_by_name" placeholder="Enter Name" style="padding: 5px; font-size: 10px;"></th>
                                <th></th>
                                <th></th>
                                <th></th>
                            </tr>
                            <tr v-for="o in untriggeredSIPsArrayFiltered" :key="o" :style="{'background-color': (o.selected ? 'grey':'')}">
                                <td v-if="allowRetrigger()" ><input type="checkbox" v-model="o.selected" value="1" :disabled="o.is_paused == 1"></td>
                                <td>{{o.can}}</td>
                                <td>{{o.folio_no}}</td>
                                <td>{{o.can_name}}
                                    <div>
                                        <span v-for="(value_inn) in o.other_holders" :key="value_inn" style="font-size: 10px; color: orange;">&bull; {{value_inn}}<br /></span> 
                                    </div>
                                </td>
                                
                                <td>
                                    {{o.scheme_to_purchase}}
                                    <div v-if="checkIfFutureDate(for_date)" >
                                        <small style="color: #ff0000;">*Subject to Actual Algo</small>
                                    </div>
                                </td>
                                <td>{{Math.round(o.initial_amount)}}</td>
                                <td>--</td>
                            </tr>
                        </table>
 
                        <div v-if="checkIfSelected(untriggeredSIPsArrayFiltered)" >
                            <ul class="list-group" style="font-size: 12px;">
                                <li class="list-group-item"><input type="radio" name="switch_setting" value="equity" v-model="x.switch_setting"> SIP to Equity</li>
                                <li class="list-group-item"><input type="radio" name="switch_setting" value="dynamicaa" v-model="x.switch_setting"> SIP to Asset Allocation</li>
                            </ul>
                            <br />
                            <button type="button" class="btn btn-primary"  :disabled="!x.switch_setting" @click="showConfirmBox('untriggered_sip', untriggeredSIPsArrayFiltered)">
                                Re-Trigger
                            </button>
                        </div>
                    </div>
                    <div v-if="tabfor=='failed-sip'" ng-cloak> 
                        <table class="table table-striped table-bordered" style="font-size: 11px;">
                            <tr>
                                <th v-if="allowRetrigger()" ng-cloak>
                                    <input type="checkbox" v-model="checkall.failed_sip" @click="checkallFunc('failed_sip', failedSIPsArrayFiltered)" value="1" :disabled="usis_search_by_can || usis_search_by_folio || usis_search_by_name" ng-cloak>
                                </th>
                                <th>CAN No.</th>
                                <th>Folio No.</th>
                                <th>CAN Name</th>
                                
                                <th>Scheme</th>
                                <th>SIP Amount</th>
                                <th>Error</th>
                                <th>Action</th>
                            </tr>
                            <tr v-if="allowRetrigger()" ng-cloak>
                                <th></th>
                                <th><input type="text" class="form-control" v-model="usis_search_by_can" placeholder="Enter CAN No." style="padding: 5px; font-size: 10px;"></th>
                                <th><input type="text" class="form-control" v-model="usis_search_by_folio" placeholder="Enter Folio No." style="padding: 5px; font-size: 10px;"></th>
                                <th><input type="text" class="form-control" v-model="usis_search_by_name" placeholder="Enter Name" style="padding: 5px; font-size: 10px;"></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                            </tr>
                            <tr  v-for="o in failedSIPsArrayFiltered" :key="o" :style="{'background-color': (o.selected ? 'grey':'')}">
                                <td v-if="allowRetrigger()" ng-cloak><input type="checkbox" v-model="o.selected" value="1"  :disabled="o.is_paused == 1"></td>
                                <td>{{o.can}}</td>
                                <td>{{o.folio_no}}</td>
                                <td>{{o.can_name}}
                                    <div>
                                        <span v-for="(value_inn, key_inn) in o.other_holders" :key="key_inn" style="font-size: 10px; color: orange;">&bull; {{value_inn}}<br /></span> 
                                    </div>
                                </td>
                                
                                <td>
                                    {{o.scheme_to_purchase}}

                                </td>
                                <td>{{Math.round(o.initial_amount)}}</td>
                                <td>{{o.mfu_response}}</td>
                                <td>--</td>
                            </tr>
                        </table>
                        <div v-if="checkIfSelected(failedSIPsArrayFiltered)" ng-cloak>
                            <ul class="list-group" style="font-size: 12px;">
                                <li class="list-group-item"><input type="radio" name="switch_setting" value="equity" v-model="x.switch_setting"> SIP to Equity</li>
                                <li class="list-group-item"><input type="radio" name="switch_setting" value="dynamicaa" v-model="x.switch_setting"> SIP to Asset Allocation</li>
                            </ul>
                            <br />
                            <button type="button" class="btn btn-primary" :disabled="!x.switch_setting" ng-cloak @click="showConfirmBox('failed_sip', failedSIPsArrayFiltered)">
                                Re-Trigger
                            </button>
                        </div>
                    </div>
                    
                    <div v-if="tabfor=='untriggered-si-switch'" ng-cloak> 
                        <table class="table table-striped table-bordered" style="font-size: 11px;">
                            <tr>
                                <th v-if="allowRetrigger()" ng-cloak>
                                    <input type="checkbox" v-model="checkall.untriggered_si_switch" @click="checkallFunc('untriggered_si_switch', untriggeredSISwitchArrayFiltered)" value="1" ng-hide="usis_search_by_can || usis_search_by_folio || usis_search_by_name" ng-cloak>
                                </th>
                                <th>CAN No.</th>
                                <th>Folio No.</th>
                                <th>CAN Name</th>
                                
                                <th>Switch From</th>
                                <th>Switch To</th>
                                <th>Units</th>
                                <th>Action</th>
                            </tr>
                            <tr v-if="allowRetrigger()" ng-cloak>
                                <th></th>
                                <th><input type="text" class="form-control" v-model="usis_search_by_can" placeholder="Enter CAN No." style="padding: 5px; font-size: 10px;"></th>
                                <th><input type="text" class="form-control" v-model="usis_search_by_folio" placeholder="Enter Folio No." style="padding: 5px; font-size: 10px;"></th>
                                <th><input type="text" class="form-control" v-model="usis_search_by_name" placeholder="Enter Name" style="padding: 5px; font-size: 10px;"></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                            </tr>
                            <tr  v-for="o in untriggeredSISwitchArrayFiltered" :key="o" :style="{'background-color': (o.selected ? 'grey':'')}">
                                <td v-if="allowRetrigger()" ng-cloak><input type="checkbox" v-model="o.selected" value="1" :disabled="o.is_paused == 1"></td>
                                <td>{{o.can}}</td>
                                <td>{{o.folio_no}}</td>
                                <td>{{o.can_name}}
                                    <div>
                                        <span v-for="(value_inn, key_inn) in o.other_holders" :key="key_inn" style="font-size: 10px; color: orange;">&bull; {{value_inn}}<br /></span> 
                                    </div>
                                </td>
                                
                                <td>
                                    {{o.scheme_from}}
                                    <div v-if="checkIfFutureDate(for_date)" ng-cloak>
                                        <small style="color: #ff0000;">*Subject to Actual Algo</small>
                                    </div>
                                </td>
                                <td>{{o.scheme_to}}</td>
                                <td>All Units</td>
                                <td>--</td>
                            </tr>
                        </table>
                        <div v-if="checkIfSelected(untriggeredSISwitchArrayFiltered)" ng-cloak>
                            <ul class="list-group" style="font-size: 12px;"> 

                                <li class="list-group-item"><input type="radio" name="switch_setting" value="dynamicaa_to_equity" v-model="x.switch_setting"> Asset Allocation to Equity</li>
                                <li class="list-group-item"><input type="radio" name="switch_setting" value="equity_to_dynamicaa" v-model="x.switch_setting"> Equity To Asset Allocation</li>
                            </ul>
                            <br />
                             
                            <button type="button" class="btn btn-primary" :disabled="!x.switch_setting" @click="showConfirmBox('untriggered_si_switch', untriggeredSISwitchArrayFiltered)">
                                Re-Trigger
                            </button>
                        </div>
                    </div>
                    <div v-if="tabfor=='failed-si-switch'" ng-cloak> 
                        <table class="table table-striped table-bordered" style="font-size: 11px;">
                            <tr>
                                <th v-if="allowRetrigger()" ng-cloak>
                                    <input type="checkbox" v-model="checkall.failed_si_switch" @click="checkallFunc('failed_si_switch', failedSISwitchArrayFiltered)" value="1" :disabled="usis_search_by_can || usis_search_by_folio || usis_search_by_name" ng-cloak>
                                </th>
                                <th>CAN No.</th>
                                <th>Folio No.</th>
                                <th>CAN Name</th>
                                
                                <th>Switch From</th>
                                <th>Switch To</th>
                                <th>Units</th>
                                <th>Error</th>
                                <th>Action</th>
                            </tr>
                            <tr v-if="allowRetrigger()" ng-cloak>
                                <th></th>
                                <th><input type="text" class="form-control" v-model="usis_search_by_can" placeholder="Enter CAN No." style="padding: 5px; font-size: 10px;"></th>
                                <th><input type="text" class="form-control" v-model="usis_search_by_folio" placeholder="Enter Folio No." style="padding: 5px; font-size: 10px;"></th>
                                <th><input type="text" class="form-control" v-model="usis_search_by_name" placeholder="Enter Name" style="padding: 5px; font-size: 10px;"></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                            </tr>
                            <tr  v-for="o in failedSISwitchArrayFiltered" :key="o" :style="{'background-color': (o.selected ? 'grey':'')}">
                                <td v-if="allowRetrigger()" ng-cloak><input type="checkbox" v-model="o.selected" value="1" :disabled="o.is_paused == 1"></td>
                                
                                <td>{{o.can}}</td>
                                <td>{{o.folio_no}}</td>
                                <td>{{o.can_name}}
                                    <div>
                                        <span v-for="(value_inn, key_inn) in o.other_holders" :key="key_inn" style="font-size: 10px; color: orange;">&bull; {{value_inn}}<br /></span> 
                                    </div>
                                </td>
                                
                                <td>{{o.scheme_from}}

                                </td>
                                <td>{{o.scheme_to}}</td>
                                <td>All Units</td>
                                <td>{{o.mfu_response}}</td>
                                <td>--</td>
                            </tr>
                        </table>

                        <div v-if="checkIfSelected(failedSISwitchArrayFiltered)" ng-cloak>
                            <ul class="list-group" style="font-size: 12px;">
                                

                                <li class="list-group-item"><input type="radio" name="switch_setting" value="dynamicaa_to_equity" v-model="x.switch_setting"> Asset Allocation to Equity</li>
                                <li class="list-group-item"><input type="radio" name="switch_setting" value="equity_to_dynamicaa" v-model="x.switch_setting"> Equity To Asset Allocation</li>
                            </ul>
                            <br />
                            <button type="button" class="btn btn-primary"  :disabled="!x.switch_setting" @click="showConfirmBox('failed_si_switch', failedSISwitchArrayFiltered)">
                                Re-Trigger
                            </button>
                        </div>
                    </div>
                    <div v-if="tabfor=='untriggered-vstp-switch'" ng-cloak> 
                        <table class="table table-striped table-bordered" style="font-size: 11px;">
                            <tr>
                                <th v-if="allowRetrigger()" ng-cloak>
                                    <input type="checkbox" v-model="checkall.untriggered_vstp_switch" @click="checkallFunc('untriggered_vstp_switch', untriggeredVSTPSwitchArrayFiltered)" value="1" :disabled="usis_search_by_can || usis_search_by_folio || usis_search_by_name" ng-cloak>
                                </th>
                                <th>CAN No.</th>
                                <th>Folio No.</th>
                                <th>CAN Name</th>
                                
                                <th>Switch From</th>
                                <th>Switch To</th>
                                <th>Units</th>
                                <th>Action</th>
                            </tr>
                            <tr v-if="allowRetrigger()" ng-cloak>
                                <th></th>
                                <th><input type="text" class="form-control" v-model="usis_search_by_can" placeholder="Enter CAN No." style="padding: 5px; font-size: 10px;"></th>
                                <th><input type="text" class="form-control" v-model="usis_search_by_folio" placeholder="Enter Folio No." style="padding: 5px; font-size: 10px;"></th>
                                <th><input type="text" class="form-control" v-model="usis_search_by_name" placeholder="Enter Name" style="padding: 5px; font-size: 10px;"></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                            </tr>
                            <tr  v-for="o in untriggeredVSTPSwitchArrayFiltered" :key="o"  :style="{'background-color': (o.selected ? 'grey':'')}">
                                <td v-if="allowRetrigger()" ng-cloak><input type="checkbox" v-model="o.selected" value="1" :disabled="o.is_paused == 1"></td>
                                <td>{{o.can}}</td>
                                <td>{{o.folio_no}}</td>
                                <td>{{o.can_name}}
                                    <div>
                                        <span v-for="(value_inn, key_inn) in o.other_holders" :key="key_inn" style="font-size: 10px; color: orange;">&bull; {{value_inn}}<br /></span> 
                                    </div>
                                </td>
                                
                                <td>{{o.scheme_from}}
                                    <div v-if="checkIfFutureDate(for_date)" ng-cloak>
                                        <small style="color: #ff0000;">*Subject to Actual Algo</small>
                                    </div>
                                </td>
                                <td>{{o.scheme_to}}</td>
                                <td>All Units</td>
                                <td>--</td>
                            </tr>
                        </table>
                        <div v-if="checkIfSelected(untriggeredVSTPSwitchArrayFiltered)" ng-cloak>
                            <ul class="list-group" style="font-size: 12px;">
                                
                                <li class="list-group-item"><input type="radio" name="switch_setting" value="dynamicaa_to_equity" v-model="x.switch_setting"> Asset Allocation to Equity</li>
                                <li class="list-group-item"><input type="radio" name="switch_setting" value="equity_to_dynamicaa" v-model="x.switch_setting"> Equity To Asset Allocation</li>
                            </ul>
                            <br /> 

                            <button type="button" class="btn btn-primary"  :disabled="!x.switch_setting" @click="showConfirmBox('untriggered_vstp_switch', untriggeredVSTPSwitchArrayFiltered)">
                                Re-Trigger
                            </button>
                        </div>
                    </div>

                    <div v-if="tabfor=='failed-vstp-switch'" ng-cloak>
                        <table class="table table-striped table-bordered" style="font-size: 11px;">
                            <tr>
                                <th v-if="allowRetrigger()" ng-cloak>
                                    <input type="checkbox" v-model="checkall.failed_vstp_switch" @click="checkallFunc('failed_vstp_switch', failedVSTPSwitchArrayFiltered)" value="1" :disabled="usis_search_by_can || usis_search_by_folio || usis_search_by_name" ng-cloak>
                                </th>
                                <th>CAN No.</th>
                                <th>Folio No.</th>
                                <th>CAN Name</th>
                                
                                <th>Switch From</th>
                                <th>Switch To</th>
                                <th>Units</th>
                                <th>Error</th>
                                <th>Action</th>
                            </tr>
                            <tr v-if="allowRetrigger()" ng-cloak>
                                <th></th>
                                <th><input type="text" class="form-control" v-model="usis_search_by_can" placeholder="Enter CAN No." style="padding: 5px; font-size: 10px;"></th>
                                <th><input type="text" class="form-control" v-model="usis_search_by_folio" placeholder="Enter Folio No." style="padding: 5px; font-size: 10px;"></th>
                                <th><input type="text" class="form-control" v-model="usis_search_by_name" placeholder="Enter Name" style="padding: 5px; font-size: 10px;"></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                            </tr>
                            <tr  v-for="o in failedVSTPSwitchArrayFiltered" :key="o"  :style="{'background-color': (o.selected ? 'grey':'')}">
                                <td v-if="allowRetrigger()" ng-cloak><input type="checkbox" v-model="o.selected" value="1" :disabled="o.is_paused == 1"></td>
                                <td>{{o.can}}</td>
                                <td>{{o.folio_no}}</td>
                                <td>{{o.can_name}}
                                    <div>
                                        <span v-for="(value_inn, key_inn) in o.other_holders" :key="key_inn" style="font-size: 10px; color: orange;">&bull; {{value_inn}}<br /></span> 
                                    </div>
                                </td>
                                
                                <td>{{o.scheme_from}}</td>
                                <td>{{o.scheme_to}}</td>
                                <td>All Units</td>
                                <td>{{o.mfu_response}}</td>
                                <td>--</td>
                            </tr>
                        </table>
                        <div v-if="checkIfSelected(failedVSTPSwitchArrayFiltered)" ng-cloak>
                            <ul class="list-group" style="font-size: 12px;">
                            
                                <li class="list-group-item"><input type="radio" name="switch_setting" value="dynamicaa_to_equity" v-model="x.switch_setting"> Asset Allocation to Equity</li>
                                <li class="list-group-item"><input type="radio" name="switch_setting" value="equity_to_dynamicaa" v-model="x.switch_setting"> Equity To Asset Allocation</li>
                            </ul>
                            <br />
                            <button type="button" class="btn btn-primary"  :disabled="!x.switch_setting" @click="showConfirmBox('failed_vstp_switch', failedVSTPSwitchArrayFiltered)">
                                Re-Trigger
                            </button>
                        </div>
                    </div>

                    <div v-if="tabfor=='untriggered-vstp'" ng-cloak>
                         <table class="table table-striped table-bordered" style="font-size: 11px;">
                            <tr>
                                <th v-if="allowRetrigger()" ng-cloak>
                                    <input type="checkbox" v-model="checkall.untriggered_vstp" @click="checkallFunc('untriggered_vstp', untriggeredVSTPArrayFiltered)" value="1" :disabled="usis_search_by_can || usis_search_by_folio || usis_search_by_name" ng-cloak>
                                </th>
                                <th>CAN No.</th>
                                <th>Folio No.</th>
                                <th>CAN Name</th>
                                
                                <th>STP From</th>
                                <th>STP To</th>
                                <th>Amount</th>
                                <th>Action</th>
                            </tr>
                            <tr v-if="allowRetrigger()" ng-cloak>
                                <th></th>
                                <th><input type="text" class="form-control" v-model="usis_search_by_can" placeholder="Enter CAN No." style="padding: 5px; font-size: 10px;"></th>
                                <th><input type="text" class="form-control" v-model="usis_search_by_folio" placeholder="Enter Folio No." style="padding: 5px; font-size: 10px;"></th>
                                <th><input type="text" class="form-control" v-model="usis_search_by_name" placeholder="Enter Name" style="padding: 5px; font-size: 10px;"></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                            </tr>
                            <tr  v-for="o in untriggeredVSTPArrayFiltered" :key="o"  :style="{'background-color': (o.selected ? 'grey':'')}">
                                <td v-if="allowRetrigger()" ng-cloak><input type="checkbox" v-model="o.selected" value="1" :disabled="o.is_paused == 1"></td>
                                <td>{{o.can}}</td>
                                <td>{{o.folio_no}}</td>
                                <td>{{o.can_name}}
                                    <div>
                                        <span v-for="(value_inn, key_inn) in o.other_holders" :key="key_inn" style="font-size: 10px; color: orange;">&bull; {{value_inn}}<br /></span> 
                                    </div>
                                </td>
                                
                                <td>{{o.scheme_from}}
                                    <div v-if="checkIfFutureDate(for_date)" ng-cloak>
                                        <small style="color: #ff0000;">*Subject to Actual Algo</small>
                                    </div>
                                </td>
                                <td>{{o.scheme_to}}</td>
                                <td>{{Math.round(o.stp_amount)}}</td>
                                <td>--</td>
                            </tr>
                        </table>
                        <div v-if="checkIfSelected(untriggeredVSTPArrayFiltered)" ng-cloak>
                            <ul class="list-group" style="font-size: 12px;">
                            
                                <li class="list-group-item"><input type="radio" name="switch_setting" value="equity_income_to_equity" v-model="x.switch_setting"> Equity Income to Equity</li>
                                <li class="list-group-item"><input type="radio" name="switch_setting" value="equity_income_to_dynamicaa" v-model="x.switch_setting"> Equity Income To Asset Allocation</li>
                            </ul>
                            <div class="row mt-2" style="max-width: 200px;">
                                <div class="col-auto">STP X =</div>
                                <div class="col">
                                    <select class="form-control" v-model="equityIncomeX">
                                        <option value="1">1</option>  
                                        <option value="3">3</option>  
                                        <option value="5">5</option>   
                                        <!-- <option value="1000">All Units</option>    -->
                                    </select>
                                </div>
                            </div>
                            
                            <br />
                            <button type="button" class="btn btn-primary"  :disabled="!x.switch_setting" @click="showConfirmBox('untriggered_vstp', untriggeredVSTPArrayFiltered)">
                                Re-Trigger
                            </button>
                        </div>
                    </div>


                    <div v-if="tabfor=='failed-vstp'" ng-cloak>
                        <table class="table table-striped table-bordered" style="font-size: 11px;">
                            <tr>
                                <th v-if="allowRetrigger()" ng-cloak>
                                    <input type="checkbox" v-model="checkall.failed_vstp" @click="checkallFunc('failed_vstp', failedVSTPArrayFiltered)" value="1" :disabled="usis_search_by_can || usis_search_by_folio || usis_search_by_name" ng-cloak>
                                </th>
                                <th>CAN No.</th>
                                <th>Folio No.</th>
                                <th>CAN Name</th>
                                
                                <th>STP From</th>
                                <th>STP To</th>
                                <th>Amount</th>
                                <th>Error</th>
                                <th>Action</th>
                            </tr>
                            <tr v-if="allowRetrigger()" ng-cloak>
                                <th></th>
                                <th><input type="text" class="form-control" v-model="usis_search_by_can" placeholder="Enter CAN No." style="padding: 5px; font-size: 10px;"></th>
                                <th><input type="text" class="form-control" v-model="usis_search_by_folio" placeholder="Enter Folio No." style="padding: 5px; font-size: 10px;"></th>
                                <th><input type="text" class="form-control" v-model="usis_search_by_name" placeholder="Enter Name" style="padding: 5px; font-size: 10px;"></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                            </tr>
                            <tr v-for="o in failedVSTPArrayFiltered" :key="o"  :style="{'background-color': (o.selected ? 'grey':'')}">
                                <td v-if="allowRetrigger()" ng-cloak><input type="checkbox" v-model="o.selected" value="1" :disabled="o.is_paused == 1"></td>
                                <td>{{o.can}}</td>
                                <td>{{o.folio_no}}</td>
                                <td>{{o.can_name}}
                                    <div>
                                        <span v-for="(value_inn, key_inn) in o.other_holders" :key="key_inn" style="font-size: 10px; color: orange;">&bull; {{value_inn}}<br /></span> 
                                    </div>
                                </td>
                                
                                <td>{{o.scheme_from}}</td>
                                <td>{{o.scheme_to}}</td>
                                <td>{{Math.round(o.stp_amount)}}</td>
                                <td>{{o.mfu_response}}</td>
                                <td>--</td>
                            </tr>
                        </table>
                        <div v-if="checkIfSelected(failedVSTPArrayFiltered)" ng-cloak>
                            <ul class="list-group" style="font-size: 12px;">
                            
                                <li class="list-group-item"><input type="radio" name="switch_setting" value="equity_income_to_equity" v-model="x.switch_setting"> Equity Income to Equity</li>
                                <li class="list-group-item"><input type="radio" name="switch_setting" value="equity_income_to_dynamicaa" v-model="x.switch_setting"> Equity Income To Asset Allocation</li>
                            </ul>
                            <div class="row mt-2" style="max-width: 200px;">
                                <div class="col-auto">STP X =</div>
                                <div class="col">
                                    <select class="form-control" v-model="equityIncomeX">
                                        <option value="1">1</option>  
                                        <option value="3">3</option>  
                                        <option value="5">5</option>   
                                        <!-- <option value="1000">All Units</option>   -->
                                    </select>
                                </div>
                            </div>
                            <br />
                            <button type="button" class="btn btn-primary"  :disabled="!x.switch_setting" @click="showConfirmBox('failed_vstp', failedVSTPArrayFiltered)">
                                Re-Trigger
                            </button>
                        </div>
                    </div>

                    <div v-if="tabfor=='success-txn'" ng-cloak>
                        <select class="form-control" v-model="otxn_type_for_search" @change="resetAllSuccessfullSelected(otxn_type_for_search, arr)">
                            <option value="">All (To Re-trigger Select)</option>
                            <option value="Purchase/SIP">Purchase / SIP CAST</option>
                            <option value="Smart Investing Switch">Smart Investing Switch (15th)</option>
                            <option value="Value STP Switch">Value STP Switch (15th)</option>
                            <option value="STP">Value STP (7th)</option>
                        </select>
                        <table class="table table-striped table-bordered" style="font-size: 11px;">
                            <tr>
                                <th v-if="allowRetrigger() && otxn_type_for_search && otxn_type_for_search != ''" ng-cloak>
                                    <input type="checkbox" v-model="checkall.successful_txn" @click="checkallFunc('successful_txn', successArrayFiltered)" value="1" :disabled="!otxn_type_for_search || usis_search_by_can || usis_search_by_folio || usis_search_by_name" ng-cloak>
                                </th>
                                <th>Txn. Type</th>
                                <th>CAN No.</th>
                                <th>Folio No.</th>
                                <th>CAN Name/Email</th>
                                <th>Scheme</th>
                                <th>Amount/Units</th>
                                <th>Status</th>
                                <!-- <th>Action</th> -->
                            </tr>
                            <tr v-if="allowRetrigger() && otxn_type_for_search && otxn_type_for_search != ''" ng-cloak>
                                <th></th>
                                <th></th>
                                <th><input type="text" class="form-control" v-model="usis_search_by_can" placeholder="Enter CAN No." style="padding: 5px; font-size: 10px;"></th>
                                <th><input type="text" class="form-control" v-model="usis_search_by_folio" placeholder="Enter Folio No." style="padding: 5px; font-size: 10px;"></th>
                                <th><input type="text" class="form-control" v-model="usis_search_by_name" placeholder="Enter Name" style="padding: 5px; font-size: 10px;"></th>
                                <th></th>
                                <th></th>
                                <th></th>
                            </tr>
                            <tr v-for="o in successArrayFiltered" :key="o" :style="{'background-color': (o.selected ? 'grey':'')}">
                                <td v-if="allowRetrigger() && otxn_type_for_search && otxn_type_for_search != ''" ng-cloak><input type="checkbox" v-model="o.selected" value="1" :disabled="o.is_paused == 1"></td>
                                <td>{{o.otxn_type}}</td>
                                <td>{{o.can}}</td>
                                <td>{{o.folio_no}}</td>
                                <td>{{o.can_name}}
                                    <div>
                                        <span v-for="(value_inn, key_inn) in o.other_holders" :key="key_inn" style="font-size: 10px; color: orange;">&bull; {{value_inn}}<br /></span> 
                                    </div>
                                </td>
                                <td>
                                    <span v-if="o.otrigger_type == 'purchase'" ng-cloak>{{o.scheme_to_purchase}}</span>

                                    <span v-if="o.otrigger_type == 'switchfund'" ng-cloak>
                                        <strong>Switch From:</strong> {{o.scheme_from}}<br />
                                        <strong>Switch To:</strong> {{o.scheme_to}}
                                    </span>
                                    <span v-if="o.otrigger_type == 'stpfund'" ng-cloak>
                                        <strong>STP From:</strong> {{o.scheme_from}}<br />
                                        <strong>STP To:</strong> {{o.scheme_to}}
                                    </span>
                                </td>
                                <td>
                                    {{Math.round(o.purchase_amount)}}
                                </td>
                                <td>
                                    <span v-if="o.mfu_response && o.mfu_response.length > 0" ng-cloak>
                                        {{o.mfu_response}}
                                    </span>
                                    <span v-if="!o.mfu_response" ng-cloak>
                                        {{o.tstatus}}
                                    </span>

                                    <span v-if="o.for_trigger_date" ng-cloak>
                                        Trigger For: {{o.for_trigger_date}}
                                    </span>

                                </td>
                                <!-- <td>--</td> -->
                            </tr>
                        </table>

                        <div v-if="checkIfSelected(successArrayFiltered)" ng-cloak>


                            <ul class="list-group" style="font-size: 12px;">
                                <li class="list-group-item" v-if="successArrayFiltered[0].otxn_type_for_search == 'STP'" ng-cloak><input type="radio" name="switch_setting" value="equity_income_to_equity" v-model="x.switch_setting"> Equity Income to Equity</li>
                                <li class="list-group-item" v-if="successArrayFiltered[0].otxn_type_for_search == 'STP'" ng-cloak><input type="radio" name="switch_setting" value="equity_income_to_dynamicaa" v-model="x.switch_setting"> Equity Income To Asset Allocation</li>


                                <li class="list-group-item" v-if="successArrayFiltered[0].otxn_type_for_search == 'Purchase/SIP'" ng-cloak><input type="radio" name="switch_setting" value="equity" v-model="x.switch_setting"> SIP to Equity</li>
                                <li class="list-group-item" v-if="successArrayFiltered[0].otxn_type_for_search == 'Purchase/SIP'" ng-cloak><input type="radio" name="switch_setting" value="dynamicaa" v-model="x.switch_setting"> SIP to Asset Allocation</li>

                                <li class="list-group-item" v-if="successArrayFiltered[0].otxn_type_for_search == 'Smart Investing Switch' || successArrayFiltered[0].otxn_type_for_search == 'Value STP Switch'" ng-cloak><input type="radio" name="switch_setting" value="dynamicaa_to_equity" v-model="x.switch_setting"> Asset Allocation to Equity</li>
                                <li class="list-group-item" v-if="successArrayFiltered[0].otxn_type_for_search == 'Smart Investing Switch' || successArrayFiltered[0].otxn_type_for_search == 'Value STP Switch'" ng-cloak><input type="radio" name="switch_setting" value="equity_to_dynamicaa" v-model="x.switch_setting"> Equity To Asset Allocation</li>


                            </ul>
                            <div class="row mt-2" style="max-width: 200px;" v-if="successArrayFiltered[0].otxn_type_for_search == 'STP'" ng-cloak>
                                <div class="col-auto">STP X =</div>
                                <div class="col">
                                    <select class="form-control" v-model="equityIncomeX">
                                        <option value="1">1</option>  
                                        <option value="3">3</option>  
                                        <option value="5">5</option>   
                                        <!-- <option value="1000">All Units</option>  -->
                                    </select>
                                </div>
                            </div>
                            <br />
                            <button type="button" class="btn btn-primary" :disabled="!x.switch_setting" @click="showConfirmBox('successful_txn', successArrayFiltered)">
                                Re-Trigger
                            </button>
                        </div>
                    </div>


                    <div v-if="tabfor=='paused-triggers'" ng-cloak>
                        <table class="table table-striped table-bordered" style="font-size: 11px;">
                            <tr>
                                <th>
                                    <input type="checkbox" v-model="checkall.paused_triggers" @click="checkallFunc('paused_triggers', pausedArrayFiltered)" value="1" :disabled="usis_search_by_can || usis_search_by_folio || usis_search_by_name || usis_search_by_si_type" ng-cloak>
                                </th>
                                <th>CAN No.</th>
                                <th>Folio No.</th>
                                <th>CAN Name</th>
                                <th>Smart Investing Type</th>
                                <th>Scheme</th> 
                            </tr>
                            <tr>
                                <th></th>
                                <th><input type="text" class="form-control" v-model="usis_search_by_can" placeholder="Enter CAN No." style="padding: 5px; font-size: 10px;"></th>
                                <th><input type="text" class="form-control" v-model="usis_search_by_folio" placeholder="Enter Folio No." style="padding: 5px; font-size: 10px;"></th>
                                <th><input type="text" class="form-control" v-model="usis_search_by_name" placeholder="Enter Name" style="padding: 5px; font-size: 10px;"></th>
                                <th>
                                    <select class="form-control" style="font-size: 10px;" v-model="usis_search_by_si_type">
                                        <option value="">-- Select --</option>
                                        <option value="SIP/LUMPSUM">SIP/LUMPSUM</option>
                                        <option value="Value STP">Value STP</option>
                                    </select>
                                </th>
                                <th></th> 
                            </tr>
                            
                            <tr v-for="o in pausedArrayFiltered" :key="o"  :style="{'background-color': (o.selected ? 'grey':'')}">
                                <td><input type="checkbox" v-model="o.selected" value="1"></td>
                                <td>{{o.can}}</td>
                                <td>{{o.folio_no}}</td>
                                <td>{{o.can_name}}
                                    <div>
                                        <span v-for="(value_inn, key_inn) in o.other_holders" :key="key_inn" style="font-size: 10px; color: orange;">&bull; {{value_inn}}<br /></span> 
                                    </div>
                                </td> 
                                <td>{{o.smart_investing_type}}</td>
                                <td>
                                    
                                    <div v-if="o.is_smart_investing == 1">
                                        <span style="font-size: 10px; color: orange;">SIP/Lumpsum Into Scheme:</span> <br />{{o.equity_scheme_name}} <br />
                                        <span style="font-size: 10px; color: orange;">Smart Investing Switch Into Scheme:</span> <br />{{o.dynamic_asset_allocation_scheme_name}} <br />   
                                        
                                        <div v-if="o.investment_type == 'SIP'"> 
                                            <span style="font-size: 10px; color: orange;">SIP Amount:</span> <br />{{Math.round(o.initial_amount)}} <br /> 
                                            <span style="font-size: 10px; color: orange;">SIP Day:</span> <br />{{o.sip_installment_date}} <br />   
                                        </div>
                                    </div>

                                    <div v-if="o.is_value_stp == 1">
                                        <span style="font-size: 10px; color: orange;">Core Scheme:</span> <br />{{o.equity_income_scheme_name}} <br />
                                        <span style="font-size: 10px; color: orange;">STP Into Scheme:</span> <br />{{o.equity_scheme_name}} <br />
                                        <span style="font-size: 10px; color: orange;">Smart Investing Switch Into Scheme:</span> <br />{{o.dynamic_asset_allocation_scheme_name}} <br />   
                                        
                                        <span style="font-size: 10px; color: orange;">STP Amount (1X):</span> <br />{{Math.round(o.monthly_stp_amount)}}  <br /> 
                                        <span style="font-size: 10px; color: orange;">STP Day:</span> <br />{{o.stpfund_day}} <br />
                                    </div>
                                    

                                </td>  
                            </tr>
                        </table>
                        <CSSLoader :showLoader="btnLoader" />
                        <button type="button" class="btn btn-primary" @click="unpauseTriggers(pausedArrayFiltered)" v-if="pausedArrayFiltered.length > 0 && !btnLoader" ng-cloak>Unpause</button>
                    </div>

                    <div v-if="tabfor=='unpaused-triggers'" ng-cloak>
                        <table class="table table-striped table-bordered" style="font-size: 11px;">
                            <tr>
                                <th>
                                    <input type="checkbox" v-model="checkall.unpaused_triggers" @click="checkallFunc('unpaused_triggers', unpausedArrayFiltered)" value="1" :disabled="usis_search_by_can || usis_search_by_folio || usis_search_by_name || usis_search_by_si_type" ng-cloak>
                                </th>
                                <th>CAN No.</th>
                                <th>Folio No.</th>
                                <th>CAN Name</th>
                                <th>Smart Investing Type</th>
                                <th>Scheme</th> 
                            </tr>
                            <tr>
                                <th></th>
                                <th><input type="text" class="form-control" v-model="usis_search_by_can" placeholder="Enter CAN No." style="padding: 5px; font-size: 10px;"></th>
                                <th><input type="text" class="form-control" v-model="usis_search_by_folio" placeholder="Enter Folio No." style="padding: 5px; font-size: 10px;"></th>
                                <th><input type="text" class="form-control" v-model="usis_search_by_name" placeholder="Enter Name" style="padding: 5px; font-size: 10px;"></th>
                                <th>
                                    <select class="form-control" style="font-size: 10px;" v-model="usis_search_by_si_type">
                                        <option value="">-- Select --</option>
                                        <option value="SIP/LUMPSUM">SIP/LUMPSUM</option>
                                        <option value="Value STP">Value STP</option>
                                    </select>
                                </th>
                                <th></th> 
                            </tr>
                            
                            <tr v-for="o in unpausedArrayFiltered" :key="o"  :style="{'background-color': (o.selected ? 'grey':'')}">
                                <td><input type="checkbox" v-model="o.selected" value="1"></td>
                                <td>{{o.can}}</td>
                                <td>{{o.folio_no}}</td>
                                <td>{{o.can_name}}
                                    <div>
                                        <span v-for="(value_inn, key_inn) in o.other_holders" :key="key_inn" style="font-size: 10px; color: orange;">&bull; {{value_inn}}<br /></span> 
                                    </div>
                                </td> 
                                <td>{{o.smart_investing_type}}</td>
                                <td>
                                    
                                    <div v-if="o.is_smart_investing == 1">
                                        <span style="font-size: 10px; color: orange;">SIP/Lumpsum Into Scheme:</span> <br />{{o.equity_scheme_name}} <br />
                                        <span style="font-size: 10px; color: orange;">Smart Investing Switch Into Scheme:</span> <br />{{o.dynamic_asset_allocation_scheme_name}} <br />   
                                        
                                        <div v-if="o.investment_type == 'SIP'"> 
                                            <span style="font-size: 10px; color: orange;">SIP Amount:</span> <br />{{Math.round(o.initial_amount)}} <br /> 
                                            <span style="font-size: 10px; color: orange;">SIP Day:</span> <br />{{o.sip_installment_date}} <br />   
                                        </div>
                                    </div>

                                    <div v-if="o.is_value_stp == 1">
                                        <span style="font-size: 10px; color: orange;">Core Scheme:</span> <br />{{o.equity_income_scheme_name}} <br />
                                        <span style="font-size: 10px; color: orange;">STP Into Scheme:</span> <br />{{o.equity_scheme_name}} <br />
                                        <span style="font-size: 10px; color: orange;">Smart Investing Switch Into Scheme:</span> <br />{{o.dynamic_asset_allocation_scheme_name}} <br />   
                                        
                                        <span style="font-size: 10px; color: orange;">STP Amount (1X):</span> <br />{{Math.round(o.monthly_stp_amount)}}  <br /> 
                                        <span style="font-size: 10px; color: orange;">STP Day:</span> <br />{{o.stpfund_day}} <br /> 
                                    </div>
                                    

                                </td>  
                            </tr>
                        </table>
                        <CSSLoader :showLoader="btnLoader" />
                        <button type="button" class="btn btn-primary" @click="pauseTriggers(unpausedArrayFiltered)" v-if="unpausedArrayFiltered.length > 0 && !btnLoader" ng-cloak>Pause</button>
                    </div>


                    <div v-if="tabfor=='folio-unmapped-triggers'" ng-cloak>
                        <table class="table table-striped table-bordered" style="font-size: 11px;">
                            <tr>
                                <th>
                                    <input type="checkbox" v-model="checkall.folio_unmapped_triggers" @click="checkallFunc('folio_unmapped_triggers', folioUnmappedArrayFiltered)" value="1" :disabled="usis_search_by_can || usis_search_by_folio || usis_search_by_name" ng-cloak>
                                </th>
                                <th>CAN No.</th>
                                <th>Folio No.</th>
                                <th>CAN Name</th>
                                <th>Smart Investing Type</th>
                                <th>Scheme</th> 
                                <th>Response</th> 
                            </tr>
                            <tr>
                                <th></th>
                                <th><input type="text" class="form-control" v-model="usis_search_by_can" placeholder="Enter CAN No." style="padding: 5px; font-size: 10px;"></th>
                                <th><input type="text" class="form-control" v-model="usis_search_by_folio" placeholder="Enter Folio No." style="padding: 5px; font-size: 10px;"></th>
                                <th><input type="text" class="form-control" v-model="usis_search_by_name" placeholder="Enter Name" style="padding: 5px; font-size: 10px;"></th>
                                <th></th>
                                <th></th> 
                                <th></th>
                            </tr>
                            
                            <tr v-for="o in folioUnmappedArrayFiltered" :key="o"  :style="{'background-color': (o.selected ? 'grey':'')}">
                                <td><input type="checkbox" v-model="o.selected" value="1"></td>
                                <td>{{o.can}}</td>
                                <td>{{o.folio_no}}</td>
                                <td>{{o.can_name}}
                                    <div>
                                        <span v-for="(value_inn, key_inn) in o.other_holders" :key="key_inn" style="font-size: 10px; color: orange;">&bull; {{value_inn}}<br /></span> 
                                    </div>
                                </td> 
                                <td>{{o.smart_investing_type}}</td>
                                <td>
                                    
                                    <div v-if="o.is_smart_investing == 1">
                                        <span style="font-size: 10px; color: orange;">SIP/Lumpsum Into Scheme:</span> <br />{{o.equity_scheme_name}} <br />
                                        <span style="font-size: 10px; color: orange;">Smart Investing Switch Into Scheme:</span> <br />{{o.dynamic_asset_allocation_scheme_name}} <br />   
                                    </div>

                                    <div v-if="o.is_value_stp == 1">
                                        <span style="font-size: 10px; color: orange;">Core Scheme:</span> <br />{{o.equity_income_scheme_name}} <br />
                                        <span style="font-size: 10px; color: orange;">STP Into Scheme:</span> <br />{{o.equity_scheme_name}} <br />
                                        <span style="font-size: 10px; color: orange;">Smart Investing Switch Into Scheme:</span> <br />{{o.dynamic_asset_allocation_scheme_name}} <br />   
                                    </div>
                                    

                                </td> 
                                <td>
                                    <span v-if="!o.gateway_response" ng-cloak>--</span>
                                    <span v-if="o.gateway_response" ng-cloak style="color: {{o.RES == 1 ? 'green':'red'}}">{{o.gateway_response.MSG}}</span>
                                </td> 
                            </tr>
                        </table>
                        <CSSLoader :showLoader="btnLoader" />
                        <button type="button" class="btn btn-primary" @click="unpauseUnmappedTriggers(folioUnmappedArrayFiltered)" v-if="folioUnmappedArrayFiltered.length > 0 && !btnLoader" ng-cloak>Unpause</button>
                    </div>


                    <div v-if="tabfor=='success-otp-pending-txn'" ng-cloak>
                        <select class="form-control" v-model="otxn_type_for_search" @change="resetAllSuccessfullSelected(otxn_type_for_search, arr)">
                            <option value="">All (To Re-trigger Select)</option>
                            <option value="Purchase/SIP">Purchase / SIP CAST</option>
                            <option value="Smart Investing Switch">Smart Investing Switch (15th)</option>
                            <option value="Value STP Switch">Value STP Switch (15th)</option>
                            <option value="STP">Value STP (7th)</option>
                        </select>
                        <table class="table table-striped table-bordered" style="font-size: 11px;">
                            <tr>
                                <th v-if="allowRetrigger() && otxn_type_for_search && otxn_type_for_search != ''" ng-cloak>
                                    <input type="checkbox" v-model="checkall.successful_txn" @click="checkallFunc('successful_txn', successArrayFiltered)" value="1" :disabled="!otxn_type_for_search || usis_search_by_can || usis_search_by_folio || usis_search_by_name" ng-cloak>
                                </th>
                                <th>Txn. Type</th>
                                <th>CAN No.</th>
                                <th>Folio No.</th>
                                <th>CAN Name/Email</th>
                                <th>Scheme</th>
                                <th>Amount/Units</th>
                                <th>Status</th>
                                <!-- <th>Action</th> -->
                            </tr>
                            <tr v-if="allowRetrigger() && otxn_type_for_search && otxn_type_for_search != ''" ng-cloak>
                                <th></th>
                                <th></th>
                                <th><input type="text" class="form-control" v-model="usis_search_by_can" placeholder="Enter CAN No." style="padding: 5px; font-size: 10px;"></th>
                                <th><input type="text" class="form-control" v-model="usis_search_by_folio" placeholder="Enter Folio No." style="padding: 5px; font-size: 10px;"></th>
                                <th><input type="text" class="form-control" v-model="usis_search_by_name" placeholder="Enter Name" style="padding: 5px; font-size: 10px;"></th>
                                <th></th>
                                <th></th>
                                <th></th>
                            </tr>
                            <tr v-for="o in successArrayFiltered" :key="o" :style="{'background-color': (o.selected ? 'grey':'')}">
                                <td v-if="allowRetrigger() && otxn_type_for_search && otxn_type_for_search != ''" ng-cloak><input type="checkbox" v-model="o.selected" value="1" :disabled="o.is_paused == 1"></td>
                                <td>{{o.otxn_type}}</td>
                                <td>{{o.can}}</td>
                                <td>{{o.folio_no}}</td>
                                <td>{{o.can_name}}
                                    <div>
                                        <span v-for="(value_inn, key_inn) in o.other_holders" :key="key_inn" style="font-size: 10px; color: orange;">&bull; {{value_inn}}<br /></span> 
                                    </div>
                                </td>
                                <td>
                                    <span v-if="o.otrigger_type == 'purchase'" ng-cloak>{{o.scheme_to_purchase}}</span>

                                    <span v-if="o.otrigger_type == 'switchfund'" ng-cloak>
                                        <strong>Switch From:</strong> {{o.scheme_from}}<br />
                                        <strong>Switch To:</strong> {{o.scheme_to}}
                                    </span>
                                    <span v-if="o.otrigger_type == 'stpfund'" ng-cloak>
                                        <strong>STP From:</strong> {{o.scheme_from}}<br />
                                        <strong>STP To:</strong> {{o.scheme_to}}
                                    </span>
                                </td>
                                <td>
                                    {{Math.round(o.purchase_amount)}}
                                </td>
                                <td>
                                    <span v-if="o.mfu_response && o.mfu_response.length > 0" ng-cloak>
                                        {{o.mfu_response}}
                                    </span>
                                    <span v-if="!o.mfu_response" ng-cloak>
                                        {{o.tstatus}}
                                    </span>

                                    <span v-if="o.for_trigger_date" ng-cloak>
                                        Trigger For: {{o.for_trigger_date}}
                                    </span>

                                </td>
                                <!-- <td>--</td> -->
                            </tr>
                        </table>

                        <div v-if="checkIfSelected(successArrayFiltered)" ng-cloak>


                            <ul class="list-group" style="font-size: 12px;">
                                <li class="list-group-item" v-if="successArrayFiltered[0].otxn_type_for_search == 'STP'" ng-cloak><input type="radio" name="switch_setting" value="equity_income_to_equity" v-model="x.switch_setting"> Equity Income to Equity</li>
                                <li class="list-group-item" v-if="successArrayFiltered[0].otxn_type_for_search == 'STP'" ng-cloak><input type="radio" name="switch_setting" value="equity_income_to_dynamicaa" v-model="x.switch_setting"> Equity Income To Asset Allocation</li>


                                <li class="list-group-item" v-if="successArrayFiltered[0].otxn_type_for_search == 'Purchase/SIP'" ng-cloak><input type="radio" name="switch_setting" value="equity" v-model="x.switch_setting"> SIP to Equity</li>
                                <li class="list-group-item" v-if="successArrayFiltered[0].otxn_type_for_search == 'Purchase/SIP'" ng-cloak><input type="radio" name="switch_setting" value="dynamicaa" v-model="x.switch_setting"> SIP to Asset Allocation</li>

                                <li class="list-group-item" v-if="successArrayFiltered[0].otxn_type_for_search == 'Smart Investing Switch' || successArrayFiltered[0].otxn_type_for_search == 'Value STP Switch'" ng-cloak><input type="radio" name="switch_setting" value="dynamicaa_to_equity" v-model="x.switch_setting"> Asset Allocation to Equity</li>
                                <li class="list-group-item" v-if="successArrayFiltered[0].otxn_type_for_search == 'Smart Investing Switch' || successArrayFiltered[0].otxn_type_for_search == 'Value STP Switch'" ng-cloak><input type="radio" name="switch_setting" value="equity_to_dynamicaa" v-model="x.switch_setting"> Equity To Asset Allocation</li>


                            </ul>
                            <div class="row mt-2" style="max-width: 200px;" v-if="successArrayFiltered[0].otxn_type_for_search == 'STP'" ng-cloak>
                                <div class="col-auto">STP X =</div>
                                <div class="col">
                                    <select class="form-control" v-model="equityIncomeX">
                                        <option value="1">1</option>  
                                        <option value="3">3</option>  
                                        <option value="5">5</option>   
                                        <!-- <option value="1000">All Units</option>  -->
                                    </select>
                                </div>
                            </div>
                            <br />
                            <button type="button" class="btn btn-primary" :disabled="!x.switch_setting" @click="showConfirmBox('successful_txn', successArrayFiltered)">
                                Re-Trigger
                            </button>
                        </div>
                    </div>
                    <div v-if="tabfor=='success-otp-submitted-txn'" ng-cloak>
                        <select class="form-control" v-model="otxn_type_for_search" @change="resetAllSuccessfullSelected(otxn_type_for_search, arr)">
                            <option value="">All (To Re-trigger Select)</option>
                            <option value="Purchase/SIP">Purchase / SIP CAST</option>
                            <option value="Smart Investing Switch">Smart Investing Switch (15th)</option>
                            <option value="Value STP Switch">Value STP Switch (15th)</option>
                            <option value="STP">Value STP (7th)</option>
                        </select>
                        <table class="table table-striped table-bordered" style="font-size: 11px;">
                            <tr>
                                <th v-if="allowRetrigger() && otxn_type_for_search && otxn_type_for_search != ''" ng-cloak>
                                    <input type="checkbox" v-model="checkall.successful_txn" @click="checkallFunc('successful_txn', successArrayFiltered)" value="1" :disabled="!otxn_type_for_search || usis_search_by_can || usis_search_by_folio || usis_search_by_name" ng-cloak>
                                </th>
                                <th>Txn. Type</th>
                                <th>CAN No.</th>
                                <th>Folio No.</th>
                                <th>CAN Name/Email</th>
                                <th>Scheme</th>
                                <th>Amount/Units</th>
                                <th>Status</th>
                                <!-- <th>Action</th> -->
                            </tr>
                            <tr v-if="allowRetrigger() && otxn_type_for_search && otxn_type_for_search != ''" ng-cloak>
                                <th></th>
                                <th></th>
                                <th><input type="text" class="form-control" v-model="usis_search_by_can" placeholder="Enter CAN No." style="padding: 5px; font-size: 10px;"></th>
                                <th><input type="text" class="form-control" v-model="usis_search_by_folio" placeholder="Enter Folio No." style="padding: 5px; font-size: 10px;"></th>
                                <th><input type="text" class="form-control" v-model="usis_search_by_name" placeholder="Enter Name" style="padding: 5px; font-size: 10px;"></th>
                                <th></th>
                                <th></th>
                                <th></th>
                            </tr>
                            <tr v-for="o in successArrayFiltered" :key="o" :style="{'background-color': (o.selected ? 'grey':'')}">
                                <td v-if="allowRetrigger() && otxn_type_for_search && otxn_type_for_search != ''" ng-cloak><input type="checkbox" v-model="o.selected" value="1" :disabled="o.is_paused == 1"></td>
                                <td>{{o.otxn_type}}</td>
                                <td>{{o.can}}</td>
                                <td>{{o.folio_no}}</td>
                                <td>{{o.can_name}}
                                    <div>
                                        <span v-for="(value_inn, key_inn) in o.other_holders" :key="key_inn" style="font-size: 10px; color: orange;">&bull; {{value_inn}}<br /></span> 
                                    </div>
                                </td>
                                <td>
                                    <span v-if="o.otrigger_type == 'purchase'" ng-cloak>{{o.scheme_to_purchase}}</span>

                                    <span v-if="o.otrigger_type == 'switchfund'" ng-cloak>
                                        <strong>Switch From:</strong> {{o.scheme_from}}<br />
                                        <strong>Switch To:</strong> {{o.scheme_to}}
                                    </span>
                                    <span v-if="o.otrigger_type == 'stpfund'" ng-cloak>
                                        <strong>STP From:</strong> {{o.scheme_from}}<br />
                                        <strong>STP To:</strong> {{o.scheme_to}}
                                    </span>
                                </td>
                                <td>
                                    {{Math.round(o.purchase_amount)}}
                                </td>
                                <td>
                                    <span v-if="o.mfu_response && o.mfu_response.length > 0" ng-cloak>
                                        {{o.mfu_response}}
                                    </span>
                                    <span v-if="!o.mfu_response" ng-cloak>
                                        {{o.tstatus}}
                                    </span>

                                    <span v-if="o.for_trigger_date" ng-cloak>
                                        Trigger For: {{o.for_trigger_date}}
                                    </span>

                                </td>
                                <!-- <td>--</td> -->
                            </tr>
                        </table>

                        <div v-if="checkIfSelected(successArrayFiltered)" ng-cloak>


                            <ul class="list-group" style="font-size: 12px;">
                                <li class="list-group-item" v-if="successArrayFiltered[0].otxn_type_for_search == 'STP'" ng-cloak><input type="radio" name="switch_setting" value="equity_income_to_equity" v-model="x.switch_setting"> Equity Income to Equity</li>
                                <li class="list-group-item" v-if="successArrayFiltered[0].otxn_type_for_search == 'STP'" ng-cloak><input type="radio" name="switch_setting" value="equity_income_to_dynamicaa" v-model="x.switch_setting"> Equity Income To Asset Allocation</li>


                                <li class="list-group-item" v-if="successArrayFiltered[0].otxn_type_for_search == 'Purchase/SIP'" ng-cloak><input type="radio" name="switch_setting" value="equity" v-model="x.switch_setting"> SIP to Equity</li>
                                <li class="list-group-item" v-if="successArrayFiltered[0].otxn_type_for_search == 'Purchase/SIP'" ng-cloak><input type="radio" name="switch_setting" value="dynamicaa" v-model="x.switch_setting"> SIP to Asset Allocation</li>

                                <li class="list-group-item" v-if="successArrayFiltered[0].otxn_type_for_search == 'Smart Investing Switch' || successArrayFiltered[0].otxn_type_for_search == 'Value STP Switch'" ng-cloak><input type="radio" name="switch_setting" value="dynamicaa_to_equity" v-model="x.switch_setting"> Asset Allocation to Equity</li>
                                <li class="list-group-item" v-if="successArrayFiltered[0].otxn_type_for_search == 'Smart Investing Switch' || successArrayFiltered[0].otxn_type_for_search == 'Value STP Switch'" ng-cloak><input type="radio" name="switch_setting" value="equity_to_dynamicaa" v-model="x.switch_setting"> Equity To Asset Allocation</li>


                            </ul>
                            <div class="row mt-2" style="max-width: 200px;" v-if="successArrayFiltered[0].otxn_type_for_search == 'STP'" ng-cloak>
                                <div class="col-auto">STP X =</div>
                                <div class="col">
                                    <select class="form-control" v-model="equityIncomeX">
                                        <option value="1">1</option>  
                                        <option value="3">3</option>  
                                        <option value="5">5</option>   
                                        <!-- <option value="1000">All Units</option>  -->
                                    </select>
                                </div>
                            </div>
                            <br />
                            <button type="button" class="btn btn-primary" :disabled="!x.switch_setting" @click="showConfirmBox('successful_txn', successArrayFiltered)">
                                Re-Trigger
                            </button>
                        </div>
                    </div>


                        
                </div>
            </div>
        </div>
    </section>


    <!-- Modal -->
<div class="modal fade show" id="confirm_popup" v-if="show_confirm_popup" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
  <div class="modal-dialog" role="document" style="width: 100% !important; max-width: 900px !important;">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">{{confirmation_popup_title}}</h5>
        <button type="button" class="close" @click="closePopup()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <table class="table table-striped table-bordered" style="font-size: 11px;">
            <tr>
                
                <th>CAN No.</th>
                <th>Folio No.</th>
                <th>CAN Name</th>
                <th v-if="opt == 'untriggered_si_switch' || opt == 'failed_si_switch' || opt == 'untriggered_vstp_switch' || opt == 'failed_vstp_switch'" ng-cloak>Switch From</th>
                <th v-if="opt == 'untriggered_si_switch' || opt == 'failed_si_switch' || opt == 'untriggered_vstp_switch' || opt == 'failed_vstp_switch'" ng-cloak>Switch To</th>
                <th v-if="opt == 'untriggered_si_switch' || opt == 'failed_si_switch' || opt == 'untriggered_vstp_switch' || opt == 'failed_vstp_switch'" ng-cloak>Units</th>
                <th v-if="opt == 'untriggered_vstp' || opt == 'failed_vstp'" ng-cloak>STP From</th>
                <th v-if="opt == 'untriggered_vstp' || opt == 'failed_vstp'">STP To</th>
                <th v-if="opt == 'untriggered_sip' || opt == 'failed_sip'">Scheme</th>
                <th v-if="opt == 'untriggered_vstp' || opt == 'failed_vstp' || opt == 'untriggered_sip' || opt == 'failed_sip'" ng-cloak>Amount</th>
                <th>Status</th>
                <th>Warning</th>
            </tr>
            
            <tr v-for="o in confirmationArrayFiltered" :key="o" :style="{'background-color': (o.total_executed_this_month > 0 ? '#c13e3b':'')}">
                <td>
                    {{o.can}}
                    <br />{{o.auto_trigger_id}}
                </td>
                <td>{{o.folio_no}}</td>
                <td>{{o.can_name}}
                    <div>
                        <span v-for="(value_inn, key_inn) in o.other_holders" :key="key_inn" style="font-size: 10px; color: orange;">&bull; {{value_inn}}<br /></span> 
                    </div>
                </td>
                <td  v-if="opt == 'untriggered_sip' || opt == 'failed_sip'" ng-cloak>
                    <span v-if="x.switch_setting == 'dynamicaa'" ng-cloak>{{o.dynamic_asset_allocation_scheme_name}}</span>
                    <span v-if="x.switch_setting == 'equity'" ng-cloak>{{o.equity_scheme_name}}</span>
                </td>
                <td  v-if="opt != 'untriggered_sip' && opt != 'failed_sip'" ng-cloak>
                    <span v-if="x.switch_setting == 'dynamicaa_to_equity'" ng-cloak>{{o.dynamic_asset_allocation_scheme_name}}</span>
                    <span v-if="x.switch_setting == 'equity_to_dynamicaa'" ng-cloak>{{o.equity_scheme_name}}</span>
                    <span v-if="x.switch_setting == 'equity_income_to_equity' || x.switch_setting == 'equity_income_to_dynamicaa'" ng-cloak>
                        {{o.equity_income_scheme_name}}
                    </span>
                </td>
                <td  v-if="opt != 'untriggered_sip' && opt != 'failed_sip'" ng-cloak>
                    <span v-if="x.switch_setting == 'dynamicaa_to_equity'" ng-cloak>{{o.equity_scheme_name}}</span>
                    <span v-if="x.switch_setting == 'equity_to_dynamicaa'" ng-cloak>{{o.dynamic_asset_allocation_scheme_name}}</span>

                    <span v-if="x.switch_setting == 'equity_income_to_equity'" ng-cloak>{{o.equity_scheme_name}}</span>
                    <span v-if="x.switch_setting == 'equity_income_to_dynamicaa'" ng-cloak>{{o.dynamic_asset_allocation_scheme_name}}</span>
                </td>
                <td v-if="opt == 'untriggered_si_switch' || opt == 'failed_si_switch' || opt == 'untriggered_vstp_switch' || opt == 'failed_vstp_switch'" ng-cloak>All Units</td>
                <td v-if="opt == 'untriggered_sip' || opt == 'failed_sip'" ng-cloak>
                    {{Math.round(o.initial_amount)}}
                </td>
                <td v-if="opt == 'untriggered_vstp' || opt == 'failed_vstp'" ng-cloak>
                    <!-- {{equityIncomeX}}X =  -->
                    <span v-if="equityIncomeX == 1000">All Units</span>
                    <span v-else>{{(Math.round((o.monthly_stp_amount*equityIncomeX)))}}</span>        
                    
                </td>
                <td class="text-center">
                    <i class="fa fa-spinner fa-spin" v-if="o.showLoader"></i>   
                    <span style="color: {{o.ostatus == 1 ? 'green':'red';}}; font-weight: bold;" v-html="o.omsg"></span>
                </td>
                <td>
                    <span v-if="o.total_executed_this_month > 0" ng-cloak>Already Executed This Month ({{o.total_executed_this_month}}) time(s)</span>

                    <a href="" @click.prevent="removeFromConfirmationBox(o)"><i class="fa fa-minus-circle fa-2x"></i></a>
 
                </td>
            </tr>
        </table>


      </div> 
      <div class="modal-footer">
        <div v-if="popup_loader && !popup_all_done" ng-cloak><i class="fa fa-spinner fa-spin fa-2x"></i></div>
        <div v-if="!popup_loader && !popup_all_done" ng-cloak>
            <button type="button" class="btn btn-secondary mr-4" @click="closePopup()">Close</button>
            <button type="button" class="btn btn-primary" @click="retriggerFunc(opt, confirmationArrayFiltered, 0)" :disabled="confirmationArrayFiltered.length == 0">Trigger</button>
        </div>
        <div v-if="popup_all_done" ng-cloak style="font-size: 15px; font-weight: bold;">
            <button type="button" class="btn btn-secondary" @click="closePopup()">Close</button>
            All Done
        </div>
      </div>
    </div>
  </div>
</div>
    
</template>

<script>  
import moment from 'moment'
import { notify } from '@kyvg/vue3-notification' 
import EventService from '@/services/EventService.js'
import { ref, reactive, computed } from 'vue'
import { useRouter, useRoute } from 'vue-router'

import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

export default {
    name: 'ListUnprocessedTransactions',
    components: { Datepicker },
    setup() {
        const router = useRouter()
        const route = useRoute() 

        const showFullPageLoader = ref(false)
        const for_date = ref(route.query.for_date || '')

        if(for_date.value == '') {
            console.log(moment().format('YYYY-MM-DD'))
            location.href = '#/list-unprocessed-transactions?for_date=' + (moment().format('YYYY-MM-DD'))
        }

        const tabfor = ref(route.query.tabfor || '')
        const tab_counter = ref([])
        const listLoaderTab = ref(false)

        const selectedMonth = ref(null)
        const selectedYear = ref(null)

        if(for_date.value) {
            var dtObj = new Date(for_date.value);
            selectedMonth.value = dtObj.getMonth() + 1;
            selectedYear.value = dtObj.getFullYear();
        }

        const allArray = ref([]);
        const untriggeredSIPsArray = ref([]);
        const failedSIPsArray = ref([]);

        const untriggeredSISwitchArray = ref([]);
        const failedSISwitchArray = ref([]);

        const untriggeredVSTPSwitchArray = ref([]);
        const failedVSTPSwitchArray = ref([]);

        const untriggeredVSTPArray = ref([]);
        const failedVSTPArray = ref([]);

        const successArray = ref([]);

        const pausedArray = ref([]);
        const unpausedArray = ref([]);
        const folioUnmappedArray = ref([]);

        const WHAT_TO_BUY = ref('');
        const WHERE_TO_SWITCH = ref('');

        const equityIncomeX = ref(0)

        const popup_loader = ref(false)
        const popup_all_done = ref(false)
        const opt = ref('')

        const confirmationArray = ref([]);
        const confirmation_popup_title = ref('');

        if(for_date.value) {
            if(!tabfor.value) {
                tabfor.value = 'all';
            }

            if(tabfor.value == "all") {
                showFullPageLoader.value = true

                EventService.getPostData("post", "mfu/unprocessed_order/getActivityCount", {
                    for_date: for_date.value,
                    month: selectedMonth.value,
                    year: selectedYear.value
                }).then(function(response){
                    showFullPageLoader.value = false
                    // console.log(response.data.ALL_DATES_ARRAY_WITH_COUNT)
                    tab_counter.value = response.data.ALL_DATES_ARRAY_WITH_COUNT;
                })

                /*
                var callAjaxChain = function(stype){ 
                    return EventService.getPostData("post", "mfu/unprocessed_order/" + stype, {for_date: for_date.value})
                };

                callAjaxChain('getUntriggeredSIPTriggers').then(function(response){
                    console.log(response.data.LIST);
                    allArray.value = allArray.value.concat(response.data.LIST);

                    return callAjaxChain('getFailedSIPTriggers');
                }).then(function(response){
                    // console.log(response.data.LIST);
                    allArray.value = allArray.value.concat(response.data.LIST);
                    return callAjaxChain('getUntriggeredSISwitchTriggers');
                }).then(function(response){
                    // console.log(response.data.LIST);
                    allArray.value = allArray.value.concat(response.data.LIST);
                    return callAjaxChain('getFailedSISwitchTriggers');
                }).then(function(response){
                    // console.log(response.data.LIST);
                    allArray.value = allArray.value.concat(response.data.LIST);
                    return callAjaxChain('getUntriggeredVSTPSwitchTriggers');
                }).then(function(response){
                    // console.log(response.data.LIST);
                    allArray.value = allArray.value.concat(response.data.LIST);
                    return callAjaxChain('getFailedVSTPSwitchTriggers');
                }).then(function(response){
                    // console.log(response.data.LIST);
                    allArray.value = allArray.value.concat(response.data.LIST);
                    return callAjaxChain('getUntriggeredVSTPTriggers');
                }).then(function(response){
                    // console.log(response.data.LIST);
                    allArray.value = allArray.value.concat(response.data.LIST);
                    return callAjaxChain('getFailedVSTPTriggers');
                }).then(function(response){
                    // console.log(response.data.LIST);
                    allArray.value = allArray.value.concat(response.data.LIST);
                    return callAjaxChain('getSuccessfulTriggers');
                }).then(function(response){
                    // console.log(response.data.LIST);
                    allArray.value = allArray.value.concat(response.data.LIST);

                    // return $http({method: 'POST', url: myConfig.ajax_url, data: {
                    //     stype: 'getActivityCount',
                    //     call: 'unprocessed_order',
                    //     month: selectedMonth,
                    //     year: selectedYear,
                    //     for_date: for_date
                    // }});
                    
                    return EventService.getPostData("post", "mfu/unprocessed_order/getActivityCount", {
                        for_date: for_date.value,
                        month: selectedMonth.value,
                        year: selectedYear.value
                    })
 
                }).then(function(response){
                    showFullPageLoader.value = false
                    // console.log(response.data.ALL_DATES_ARRAY_WITH_COUNT)
                    tab_counter.value = response.data.ALL_DATES_ARRAY_WITH_COUNT;
                    // console.log(tab_counter.value.UNTRIGGERED_SI_SWITCH_TXN_DATES)
                     
                    // for (var key of Object.keys(tab_counter.value)) {
                    //     // console.log(key + " -> " + tab_counter.value[key][for_date.value])
                    //     tab_counter.value[key]['d_'+for_date.value] = tab_counter.value[key][for_date.value] || 0;
                    // }

                    // console.log(tab_counter);

                    // _.each(tab_counter, function(value, key, obj) { 
                    //     tab_counter[key][for_date] = tab_counter[key][for_date] || 0;
                    // });

                });*/
                
            } else { 
                let stype = ''

                if(tabfor.value == "untriggered-sip") {
                    stype = 'getUntriggeredSIPTriggers';    
                } else if(tabfor.value == "failed-sip") {
                    stype = 'getFailedSIPTriggers';    
                } else if(tabfor.value == "untriggered-si-switch") {
                    stype = 'getUntriggeredSISwitchTriggers';    
                } else if(tabfor.value == "failed-si-switch") {
                    stype = 'getFailedSISwitchTriggers';    
                } else if(tabfor.value == "untriggered-vstp-switch") {
                    stype = 'getUntriggeredVSTPSwitchTriggers';    
                } else if(tabfor.value == "failed-vstp-switch") {
                    stype = 'getFailedVSTPSwitchTriggers';    
                } else if(tabfor.value == "untriggered-vstp") {
                    stype = 'getUntriggeredVSTPTriggers';    
                } else if(tabfor.value == "failed-vstp") {
                    stype = 'getFailedVSTPTriggers';    
                } else if(tabfor.value == "success-txn") {
                    stype = 'getSuccessfulTriggers';    
                } else if(tabfor.value == "paused-triggers") {
                    stype = 'getPausedTriggers';    
                } else if(tabfor.value == "unpaused-triggers") {
                    stype = 'getUnpausedTriggers';    
                } else if(tabfor.value == "folio-unmapped-triggers") {
                    stype = 'getFolioUnmappedTriggers';    
                } else if(tabfor.value == "success-otp-pending-txn") {
                    stype = 'getSuccessfulOtpPendingTriggers';    
                }  else if(tabfor.value == "success-otp-submitted-txn") {
                    stype = 'getSuccessfulOtpSubmittedTriggers';    
                } 

                showFullPageLoader.value = true


                EventService.getPostData("post", "mfu/unprocessed_order/" + stype, {
                    for_date: for_date.value
                }).then(function(response){
                
                    if(tabfor.value == "untriggered-sip") {
                        untriggeredSIPsArray.value = response.data.LIST;
                    } else if(tabfor.value == "failed-sip") {
                        failedSIPsArray.value = response.data.LIST;
                    } else if(tabfor.value == "untriggered-si-switch") {
                        untriggeredSISwitchArray.value = response.data.LIST;
                    } else if(tabfor.value == "failed-si-switch") {
                        failedSISwitchArray.value = response.data.LIST;
                    } else if(tabfor.value == "untriggered-vstp-switch") {
                        untriggeredVSTPSwitchArray.value = response.data.LIST;
                    } else if(tabfor.value == "failed-vstp-switch") {
                        failedVSTPSwitchArray.value = response.data.LIST;
                    } else if(tabfor.value == "untriggered-vstp") {
                        untriggeredVSTPArray.value = response.data.LIST;
                        if(untriggeredVSTPArray.value.length > 0) {
                            equityIncomeX.value = parseInt(untriggeredVSTPArray.value[0].equityIncomeX);    
                        } else {
                            equityIncomeX.value = 0;
                        }
                        
                    } else if(tabfor.value == "failed-vstp") {
                        failedVSTPArray.value = response.data.LIST;

                        if(failedVSTPArray.value.length > 0) {
                            equityIncomeX.value = parseInt(failedVSTPArray.value[0].equityIncomeX );
                        } else {
                            equityIncomeX.value = 0;
                        }
                        
                    } else if(tabfor.value == "success-txn") {
                        successArray.value = response.data.LIST;

                    } else if(tabfor.value == "paused-triggers") {
                        pausedArray.value = response.data.LIST;

                    }  else if(tabfor.value == "unpaused-triggers") {
                        unpausedArray.value = response.data.LIST;

                    } else if(tabfor.value == "folio-unmapped-triggers") {
                        folioUnmappedArray.value = response.data.LIST;

                    } else if(tabfor.value == "success-otp-pending-txn") {
                        successArray.value = response.data.LIST;

                    }  else if(tabfor.value == "success-otp-submitted-txn") {
                        successArray.value = response.data.LIST;

                    } 
    
                    return EventService.getPostData("post", "mfu/unprocessed_order/getActivityCount", {
                        for_date: for_date.value,
                        month: selectedMonth.value,
                        year: selectedYear.value
                    })
                }).then(function(response){
                    // alert(1234)
                    showFullPageLoader.value = false
                    tab_counter.value = response.data.ALL_DATES_ARRAY_WITH_COUNT;
                    // _.each(tab_counter, function(value, key, obj) { 
                    //     tab_counter[key][for_date] = tab_counter[key][for_date] || 0;
                    // });

                    // alert(response.data.WHERE_TO_SWITCH);
                    WHAT_TO_BUY.value = response.data.WHAT_TO_BUY;
                    WHERE_TO_SWITCH.value = response.data.WHERE_TO_SWITCH;


                });  
            }
        }

        const getHumanDate = function (date) {
            if(date) {
                return moment(date, 'YYYY-MM-DD').format('DD/MM/yyyy')
            } else {
                return ''
            }
            
        }

        function setForDate() {
            // alert(for_date.value)
            location.href = '#/list-unprocessed-transactions?for_date=' + for_date.value
        }

        const checkIfFutureDate = function(dt){
            var d1 = new Date();
            var d2 = new Date(dt);
            var gt = d1.getTime() < d2.getTime();

            return gt; 
        };

        function getAllCount(){
            if(tabfor.value == "all") {
                return allArray.value.length;
            } else {
                // console.log("cc")
                var x = 0;
               
                for (var key of Object.keys(tab_counter.value)) {
                    // console.log(key + " -> " + tab_counter.value[key][for_date.value]) 
                    x = x + parseInt(tab_counter.value[key][for_date.value] || 0);
                }

                return x;
            }
        }

        function allowRetrigger() {
            // var d1 = new Date(for_date.value);
            // var d2 = Date.today().addDays(-45); // -29
            // var gt = d1.getTime() > d2.getTime();
            return true;

            // return gt; 
        }


        ////////////////////////////////////////////RE-TRIGGER =================================
        const checkall = reactive({
            untriggered_sip: false,
            failed_sip: false, 
            untriggered_si_switch: false,
            failed_si_switch: false,
            untriggered_vstp_switch: false,
            failed_vstp_switch: false,
            untriggered_vstp: false,
            failed_vstp: false,
            successful_txn: false,
            paused_triggers: false,
            unpaused_triggers: false,
            folio_unmapped_triggers: false
        });

        const usis_search_by_can = ref(null)
        const usis_search_by_folio = ref(null)
        const usis_search_by_name = ref(null)
        const otxn_type_for_search = ref(null)
        const usis_search_by_si_type = ref(null)

        const x = reactive({
            switch_setting: ''
        }); 
  
 

        const checkallFunc = function(opt, selArray) {
             
            if(!checkall[opt]) {
                selArray.forEach(function(v, i){
                    if(parseInt(v.is_paused) == parseInt(0)) {
                        v.selected = true;    
                    } else if(opt == 'paused_triggers' || opt == 'unpaused_triggers') {
                        v.selected = true;    
                    } else if(opt == 'folio_unmapped_triggers') {
                        v.selected = true;    
                    }
                })

                    
            } else {
                selArray.forEach(function(v, i){
                    v.selected = false;
                });   
            }
            
            
        };

        const checkIfSelected = function(selArray) {

            return selArray.filter(function(v, i){
                return v.selected == true
            }).length > 0

            // return ($filter('filter')(selArray, {selected: true}, true)).length > 0;
        };

        const untriggeredSIPsArrayFiltered = computed(() => {
            return untriggeredSIPsArray.value.filter(function(v, k){
                // return {can: usis_search_by_can, folio_no: usis_search_by_folio, can_name: usis_search_by_name}
                let s1 = true
                let s2 = true
                let s3 = true
                if(usis_search_by_can.value) {
                    s1 = v.can.toLowerCase().indexOf(usis_search_by_can.value.toLowerCase()) > -1
                }

                if(usis_search_by_folio.value) {
                    s2 = v.folio_no.indexOf(usis_search_by_folio.value) > -1
                }

                if(usis_search_by_name.value) {
                    s3 = s3 = v.can_name.toLowerCase().indexOf(usis_search_by_name.value.toLowerCase()) > -1
                }

                return s1 && s2 && s3
            });
        })

        const failedSIPsArrayFiltered = computed(() => {
            return failedSIPsArray.value.filter(function(v, k){
                // return {can: usis_search_by_can, folio_no: usis_search_by_folio, can_name: usis_search_by_name}
                let s1 = true
                let s2 = true
                let s3 = true
                if(usis_search_by_can.value) {
                    s1 = v.can.toLowerCase().indexOf(usis_search_by_can.value.toLowerCase()) > -1
                }

                if(usis_search_by_folio.value) {
                    s2 = v.folio_no.indexOf(usis_search_by_folio.value) > -1
                }

                if(usis_search_by_name.value) {
                    s3 = s3 = v.can_name.toLowerCase().indexOf(usis_search_by_name.value.toLowerCase()) > -1
                }

                return s1 && s2 && s3
            });
        })

        const untriggeredSISwitchArrayFiltered = computed(() => {
            return untriggeredSISwitchArray.value.filter(function(v, k){
                // return {can: usis_search_by_can, folio_no: usis_search_by_folio, can_name: usis_search_by_name}
                let s1 = true
                let s2 = true
                let s3 = true
                if(usis_search_by_can.value) {
                    s1 = v.can.toLowerCase().indexOf(usis_search_by_can.value.toLowerCase()) > -1
                }

                if(usis_search_by_folio.value) {
                    s2 = v.folio_no.indexOf(usis_search_by_folio.value) > -1
                }

                if(usis_search_by_name.value) {
                    s3 = s3 = v.can_name.toLowerCase().indexOf(usis_search_by_name.value.toLowerCase()) > -1
                }

                return s1 && s2 && s3
            });
        })

        const failedSISwitchArrayFiltered = computed(() => {
            return failedSISwitchArray.value.filter(function(v, k){
                // return {can: usis_search_by_can, folio_no: usis_search_by_folio, can_name: usis_search_by_name}
                let s1 = true
                let s2 = true
                let s3 = true
                if(usis_search_by_can.value) {
                    s1 = v.can.toLowerCase().indexOf(usis_search_by_can.value.toLowerCase()) > -1
                }

                if(usis_search_by_folio.value) {
                    s2 = v.folio_no.indexOf(usis_search_by_folio.value) > -1
                }

                if(usis_search_by_name.value) {
                    s3 = s3 = v.can_name.toLowerCase().indexOf(usis_search_by_name.value.toLowerCase()) > -1
                }

                return s1 && s2 && s3
            });
        })

        const untriggeredVSTPSwitchArrayFiltered = computed(() => {
            return untriggeredVSTPSwitchArray.value.filter(function(v, k){
                // return {can: usis_search_by_can, folio_no: usis_search_by_folio, can_name: usis_search_by_name}
                let s1 = true
                let s2 = true
                let s3 = true
                if(usis_search_by_can.value) {
                    s1 = v.can.toLowerCase().indexOf(usis_search_by_can.value.toLowerCase()) > -1
                }

                if(usis_search_by_folio.value) {
                    s2 = v.folio_no.indexOf(usis_search_by_folio.value) > -1
                }

                if(usis_search_by_name.value) {
                    s3 = s3 = v.can_name.toLowerCase().indexOf(usis_search_by_name.value.toLowerCase()) > -1
                }

                return s1 && s2 && s3
            });
        })

        const failedVSTPSwitchArrayFiltered = computed(() => {
            return failedVSTPSwitchArray.value.filter(function(v, k){
                // return {can: usis_search_by_can, folio_no: usis_search_by_folio, can_name: usis_search_by_name}
                let s1 = true
                let s2 = true
                let s3 = true
                if(usis_search_by_can.value) {
                    s1 = v.can.toLowerCase().indexOf(usis_search_by_can.value.toLowerCase()) > -1
                }

                if(usis_search_by_folio.value) {
                    s2 = v.folio_no.indexOf(usis_search_by_folio.value) > -1
                }

                if(usis_search_by_name.value) { 
                    s3 = v.can_name.toLowerCase().indexOf(usis_search_by_name.value.toLowerCase()) > -1
                }

                return s1 && s2 && s3
            });
        })

        const untriggeredVSTPArrayFiltered = computed(() => {
            return untriggeredVSTPArray.value.filter(function(v, k){
                // return {can: usis_search_by_can, folio_no: usis_search_by_folio, can_name: usis_search_by_name}
                let s1 = true
                let s2 = true
                let s3 = true
                if(usis_search_by_can.value) {
                    s1 = v.can.toLowerCase().indexOf(usis_search_by_can.value.toLowerCase()) > -1
                }

                if(usis_search_by_folio.value) {
                    s2 = v.folio_no.indexOf(usis_search_by_folio.value) > -1
                }

                if(usis_search_by_name.value) {
                    s3 = s3 = v.can_name.toLowerCase().indexOf(usis_search_by_name.value.toLowerCase()) > -1
                }

                return s1 && s2 && s3
            });
        })

        const failedVSTPArrayFiltered = computed(() => {
            return failedVSTPArray.value.filter(function(v, k){
                // return {can: usis_search_by_can, folio_no: usis_search_by_folio, can_name: usis_search_by_name}
                let s1 = true
                let s2 = true
                let s3 = true
                if(usis_search_by_can.value) {
                    s1 = v.can.toLowerCase().indexOf(usis_search_by_can.value.toLowerCase()) > -1
                }

                if(usis_search_by_folio.value) {
                    s2 = v.folio_no.indexOf(usis_search_by_folio.value) > -1
                }

                if(usis_search_by_name.value) {
                    s3 = s3 = v.can_name.toLowerCase().indexOf(usis_search_by_name.value.toLowerCase()) > -1
                }

                return s1 && s2 && s3
            });
        })

        const successArrayFiltered = computed(() => {
            return successArray.value.filter(function(v, k){
                // return {can: usis_search_by_can, folio_no: usis_search_by_folio, can_name: usis_search_by_name}
                let s1 = true
                let s2 = true
                let s3 = true
                let s4 = true
                if(usis_search_by_can.value) {
                    s1 = v.can.toLowerCase().indexOf(usis_search_by_can.value.toLowerCase()) > -1
                }

                if(usis_search_by_folio.value) {
                    s2 = v.folio_no.indexOf(usis_search_by_folio.value) > -1
                }

                if(usis_search_by_name.value) {
                    s3 = s3 = v.can_name.toLowerCase().indexOf(usis_search_by_name.value.toLowerCase()) > -1
                }

                if(otxn_type_for_search.value) {
                    s4 = v.otxn_type_for_search.indexOf(otxn_type_for_search.value) > -1
                }

                return s1 && s2 && s3 && s4
            });
        })

        const pausedArrayFiltered = computed(() => {
            return pausedArray.value.filter(function(v, k){
                // return {can: usis_search_by_can, folio_no: usis_search_by_folio, can_name: usis_search_by_name}
                let s1 = true
                let s2 = true
                let s3 = true
                let s4 = true
                if(usis_search_by_can.value) {
                    s1 = v.can.toLowerCase().indexOf(usis_search_by_can.value.toLowerCase()) > -1
                }

                if(usis_search_by_folio.value) {
                    s2 = v.folio_no.indexOf(usis_search_by_folio.value) > -1
                }

                if(usis_search_by_name.value) {
                    s3 = s3 = v.can_name.toLowerCase().indexOf(usis_search_by_name.value.toLowerCase()) > -1
                }

                if(usis_search_by_si_type.value) {
                    s4 = v.smart_investing_type.indexOf(usis_search_by_si_type.value) > -1
                }

                return s1 && s2 && s3 && s4
            });
        })

        const unpausedArrayFiltered = computed(() => {
            return unpausedArray.value.filter(function(v, k){
                // return {can: usis_search_by_can, folio_no: usis_search_by_folio, can_name: usis_search_by_name}
                let s1 = true
                let s2 = true
                let s3 = true
                let s4 = true
                if(usis_search_by_can.value) {
                    s1 = v.can.toLowerCase().indexOf(usis_search_by_can.value.toLowerCase()) > -1
                }

                if(usis_search_by_folio.value) {
                    s2 = v.folio_no.indexOf(usis_search_by_folio.value) > -1
                }

                if(usis_search_by_name.value) {
                    s3 = s3 = v.can_name.toLowerCase().indexOf(usis_search_by_name.value.toLowerCase()) > -1
                }

                if(usis_search_by_si_type.value) {
                    s4 = v.smart_investing_type.indexOf(usis_search_by_si_type.value) > -1
                }

                return s1 && s2 && s3 && s4
            });
        })

        const folioUnmappedArrayFiltered = computed(() => {
            return folioUnmappedArray.value.filter(function(v, k){
                // return {can: usis_search_by_can, folio_no: usis_search_by_folio, can_name: usis_search_by_name}
                let s1 = true
                let s2 = true
                let s3 = true 
                if(usis_search_by_can.value) {
                    s1 = v.can.toLowerCase().indexOf(usis_search_by_can.value.toLowerCase()) > -1
                }

                if(usis_search_by_folio.value) {
                    s2 = v.folio_no.indexOf(usis_search_by_folio.value) > -1
                }

                if(usis_search_by_name.value) {
                    s3 = s3 = v.can_name.toLowerCase().indexOf(usis_search_by_name.value.toLowerCase()) > -1
                }
 

                return s1 && s2 && s3
            });
        })

        const confirmationArrayFiltered = computed(() => {
            return confirmationArray.value.filter(function(v){
                return v.selected == true
            })
        })

        const btnLoader = ref(false)
        const show_confirm_popup = ref(false)
        //// unpause folio unmapped triggers
        const unpauseUnmappedTriggers = function(arr) {
            // var fArray = $filter('filter')(arr, {selected: true});
            let fArray = arr.filter(function(v){
                return v.selected == true
            })
            // console.log("arr", arr, fArray);
            if(fArray.length == 0) {
                alert("Please select atleast one smart investing to recheck folio mapping and unpause");
                return false;
            }

            var c = confirm("Are you sure you wish to recheck folio mapping and unpause " + fArray.length + " smart investing?");
            if(c) {
                btnLoader.value = true
                EventService.getPostData("post", "mfu/unprocessed_order/unpauseUnmappedTriggers", {selected_triggers: fArray})
                .then(response => {
                    btnLoader.value = false
                     
                    if(parseInt(response.data.SUCCESS) == parseInt(1)) {

                        // angular.forEach(response.data.LOG, function(v, i){
                        //     console.log(v, i);
                        //     var f = $filter('filter')(arr, {auto_trigger_id: i})[0];
                        //     if(f) {
                        //         var idx = arr.indexOf(f);
                        //         arr[idx].gateway_response = v;
                        //     }
                        // });

                    } else {
                        notify({
                            title: "Error",
                            text: response.data.ERR_MSG,
                            type: 'error'
                        });
                    
                    }
                    
                })
                .catch(error => {  
                    console.log("This iwas an error!", error)
                    btnLoader.value = false
                    notify({
                        title: "Error",
                        text: error,
                        type: 'error'
                    });
                })
 
            }
        };

        const removeFromConfirmationBox = function(o) {
            o.selected = false;
        }


         /////////////// pause / unpause triggers
        const unpauseTriggers = function(arr) {
            // var fArray = $filter('filter')(arr, {selected: true});
            let fArray = arr.filter(function(v){
                return v.selected == true
            })
            // console.log("arr", arr, fArray);
            if(fArray.length == 0) {
                alert("Please select atleast one smart investing to unpause");
                return false;
            }

            var c = confirm("Are you sure you wish to unpause " + fArray.length + " smart investing?");
            if(c) {
                btnLoader.value = true;
                /////////////// get all unprocessed triggers that hasn't run for some reason ======================

                EventService.getPostData("post", "mfu/unprocessed_order/unpauseTriggers", {selected_triggers: fArray})
                .then(response => {
                    btnLoader.value = false
                     
                    if(parseInt(response.data.SUCCESS) == parseInt(1)) {
                        notify({
                            title: "Success",
                            text: response.data.ERR_MSG,
                            type: 'success'
                        });

                        setTimeout(() => {
                            window.location.reload();
                        }, 3000)

                    } else {
                        notify({
                            title: "Error",
                            text: response.data.ERR_MSG,
                            type: 'error'
                        });
                    
                    }
                    
                })
                .catch(error => {  
                    console.log("This iwas an error!", error)
                    btnLoader.value = false
                    notify({
                        title: "Error",
                        text: error,
                        type: 'error'
                    });
                })
 
            }
        };

        const pauseTriggers = function(arr) {
            // var fArray = $filter('filter')(arr, {selected: true});
            let fArray = arr.filter(function(v){
                return v.selected == true
            })
            // console.log("arr", arr, fArray);
            if(fArray.length == 0) {
                alert("Please select atleast one smart investing to pause");
                return false;
            }

            var c = confirm("Are you sure you wish to pause " + fArray.length + " smart investing?");
            if(c) {
                btnLoader.value = true;
                /////////////// get all unprocessed triggers that hasn't run for some reason ======================
                EventService.getPostData("post", "mfu/unprocessed_order/pauseTriggers", {selected_triggers: fArray})
                .then(response => {
                    btnLoader.value = false
                     
                    if(parseInt(response.data.SUCCESS) == parseInt(1)) {
                        notify({
                            title: "Success",
                            text: response.data.ERR_MSG,
                            type: 'success'
                        });

                        setTimeout(() => {
                            window.location.reload();
                        }, 3000)

                    } else {
                        notify({
                            title: "Error",
                            text: response.data.ERR_MSG,
                            type: 'error'
                        });
                    
                    }
                    
                })
                .catch(error => {  
                    console.log("This iwas an error!", error)
                    btnLoader.value = false
                    notify({
                        title: "Error",
                        text: error,
                        type: 'error'
                    });
                }) 
            }
        };

        const resetAllSuccessfullSelected = function(otxn_type_for_search, arr) {

            successArray.value.forEach(function(v){
                v.selected = false;
            })
 
            // alert(otxn_type_for_search);

            checkall.successful_txn = false;
            if(otxn_type_for_search == 'STP') {
                console.log(arr[0]);
                equityIncomeX.value = arr[0].equityIncomeX || null;
            }
        };


        const showConfirmBox = function(selopt, selArray) {
            if(selopt == 'successful_txn') {
                if(selArray[0].otxn_type_for_search == 'Purchase/SIP') {
                    selopt = "untriggered_sip";
                } else if(selArray[0].otxn_type_for_search == 'Smart Investing Switch') {
                    selopt = "untriggered_si_switch";
                } else if(selArray[0].otxn_type_for_search == 'Value STP Switch') {
                    selopt = "untriggered_vstp_switch";
                } else if(selArray[0].otxn_type_for_search == 'STP') {
                    selopt = "untriggered_vstp";
                }
            }

            opt.value = selopt;
            popup_loader.value = false;
            popup_all_done.value = false;

            confirmationArray.value = selArray;
            if(selopt == "untriggered_sip" || selopt == "failed_sip") {
                confirmation_popup_title.value = "Confirm SIP";
            } else if(selopt == "untriggered_si_switch" || selopt == "failed_si_switch") {
                confirmation_popup_title.value = "Confirm SI Switch";
            } else if(selopt == "untriggered_vstp_switch" || selopt == "failed_vstp_switch") {
                confirmation_popup_title.value = "Confirm Value STP Switch";
            } else if(selopt == "untriggered_vstp" || selopt == "failed_vstp") {
                confirmation_popup_title.value = "Confirm Value STP";

            }
 
            show_confirm_popup.value = true
        };

        const closePopup = function() {
            if(popup_all_done.value) {
                window.location.reload();    
            } else {
                show_confirm_popup.value = false
            }
            
        };


        
        const retriggerFunc = function(opt, selArray, idx) {
            popup_loader.value = true

            if(!selArray[idx]) {
                popup_loader.value = false

                return false
            }
 
            selArray[idx].showLoader = true 
            

            let objToSend = {}; 
            let stype = '';
            if(opt == "untriggered_sip" || opt == "failed_sip") {
                stype = "exec_sip";
            } else if(opt == "untriggered_si_switch" || opt == "failed_si_switch") {
                stype = "exec_si_switch";
            } else if(opt == "untriggered_vstp_switch" || opt == "failed_vstp_switch") {
                stype = "exec_vstp_switch";
            } else if(opt == "untriggered_vstp" || opt == "failed_vstp") {
                stype = "exec_vstp";
                objToSend.equityIncomeX = equityIncomeX.value;
            }


            objToSend.for_date = for_date.value;
            objToSend.auto_triggers = [selArray[idx].auto_trigger_id];
            objToSend.switch_setting = x.switch_setting;
            objToSend.WHAT_TO_BUY = WHAT_TO_BUY.value;
            objToSend.WHERE_TO_SWITCH = WHERE_TO_SWITCH.value;
           
            
            // console.log((selArray.length-1), idx, selArray[idx].auto_trigger_id)
            if((selArray.length) <= idx) {
                popup_loader.value = false
            } else { 
                // console.log("idx", idx, selArray[idx].auto_trigger_id, objToSend)

                EventService.getPostData("post", "mfu/retrigger_si/" + stype, objToSend)
                .then(response => {
                    selArray[idx].showLoader = false

                    selArray[idx].ostatus = response.data.DATA[0].ostatus;
                    selArray[idx].omsg = response.data.DATA[0].msg;

                    retriggerFunc(opt, selArray, ++idx)
                })
                .catch(error => {   
                    selArray[idx].showLoader = false
                    // notify({
                    //     title: "Error",
                    //     text: error,
                    //     type: 'error'
                    // });

                    selArray[idx].ostatus = 0;
                    selArray[idx].omsg = error;

                    retriggerFunc(opt, selArray, ++idx)
                })

            }




            // if((selArray.length-1) <= idx) {
            //     popup_loader.value = false
            // } else {
            //     setTimeout(() => {
            //         retriggerFunc(opt, selArray, ++idx)
            //     }, 1000)
            // }
            
        }
 


        return {
            for_date, getHumanDate, setForDate, listLoaderTab,
            tabfor, tab_counter, allArray, untriggeredSIPsArray, failedSIPsArray, untriggeredSISwitchArray,
            failedSISwitchArray, untriggeredVSTPSwitchArray, failedVSTPSwitchArray, untriggeredVSTPArray,
            failedVSTPArray, successArray, pausedArray, unpausedArray, folioUnmappedArray, WHAT_TO_BUY, WHERE_TO_SWITCH,
            checkIfFutureDate, showFullPageLoader, selectedMonth, selectedYear, equityIncomeX, getAllCount,
            allowRetrigger, checkallFunc, usis_search_by_can, usis_search_by_folio, usis_search_by_name, checkall, checkIfSelected, x,
            untriggeredSIPsArrayFiltered, failedSIPsArrayFiltered, untriggeredSISwitchArrayFiltered, failedSISwitchArrayFiltered,
            untriggeredVSTPSwitchArrayFiltered, failedVSTPSwitchArrayFiltered, untriggeredVSTPArrayFiltered,
            failedVSTPArrayFiltered, otxn_type_for_search, successArrayFiltered, usis_search_by_si_type,
            pausedArrayFiltered, unpausedArrayFiltered, folioUnmappedArrayFiltered,
            popup_loader, popup_all_done, opt, confirmationArray, confirmationArrayFiltered, confirmation_popup_title,

            removeFromConfirmationBox, unpauseUnmappedTriggers, btnLoader, pauseTriggers, unpauseTriggers,
            resetAllSuccessfullSelected, showConfirmBox, closePopup, show_confirm_popup,
            retriggerFunc
        }
    }
      
}
</script>  

<style scoped> 
.modal {
    display: block;
}

.modal-content {
    text-align: left; 
}
    

code {
    font-size: 11px;
}
 
.modal{
    display: block !important; /* I added this to see the modal, you don't need this */
}

/* Important part */
.modal-dialog{
    overflow-y: initial !important
}
.modal-body{
    height: 70vh;
    overflow-y: auto;
}
    
</style>