<template>
<FullPageLoader :showLoader="showFullPageLoader" />
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0">eCANs</h1>
          </div><!-- /.col -->
          <div class="col-sm-6"> 
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <section class="content text-sm">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                <!-- general form elements -->
                    <div class="card card-primary">
                        <div class="card-header">
                            <h3 class="card-title">Search</h3>
                        </div>
                        <form method="post" @submit.prevent="submitSearchForm()">
                            <div class="card-body">
                                <div class="row"> 
                                    <div class="col-md-2">
                                        <div class="form-group">
                                            <label for="">CAN NO.</label>
                                            <input type="text" class="form-control form-control-sm" v-model="searchFrm.can_no">
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="form-group">
                                            <label for="">ANY HOLDER'S PAN</label>
                                            <input type="text" class="form-control form-control-sm" v-model="searchFrm.pan_no">
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="form-group">
                                            <label for="">ANY HOLDER'S NAME</label>
                                            <input type="text" class="form-control form-control-sm" v-model="searchFrm.holders_name">
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="form-group">
                                            <label for="">ANY HOLDER'S EMAIL</label>
                                            <input type="text" class="form-control form-control-sm" v-model="searchFrm.holders_email">
                                        </div>
                                    </div>
                                     
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <a href="" @click.prevent="filterByBatch('ALL')" class="badge badge-info">
                                            <span v-if="searchFrm.filter_by == 'ALL'" class=""><i class="fa fa-check"></i></span>
                                            All Data <strong class="">({{ecan_count['all'] ?? 0}})</strong>
                                        </a> | 
                                        <a href="" @click.prevent="filterByBatch('approved')" class="badge badge-primary">
                                            <span v-if="searchFrm.filter_by == 'approved'" class=""><i class="fa fa-check"></i></span>
                                            Approved <strong class="">({{ecan_count['approved']?? 0}})</strong>
                                        </a> | 
                                        <a href="" @click.prevent="filterByBatch('pending-approval')" class="badge badge-success">
                                            <span v-if="searchFrm.filter_by == 'pending-approval'" class=""><i class="fa fa-check"></i></span>
                                            Pending Approval <strong class="">({{ecan_count['pending-approval']?? 0}})</strong>
                                        </a> | 
                                        <a href="" @click.prevent="filterByBatch('agreement-accepted')" class="badge badge-success">
                                            <span v-if="searchFrm.filter_by == 'agreement-accepted'" class=""><i class="fa fa-check"></i></span>
                                            Agreement Accepted <strong class="">({{ecan_count['agreement-accepted']?? 0}})</strong>
                                        </a> | 
                                        <a href="" @click.prevent="filterByBatch('agreement-not-accepted')" class="badge badge-warning">
                                            <span v-if="searchFrm.filter_by == 'agreement-not-accepted'" class=""><i class="fa fa-check"></i></span>
                                            Agreement Not Accepted<strong class="">({{ecan_count['agreement-not-accepted']?? 0}})</strong>
                                        </a> |
                                        <a href="" @click.prevent="filterByBatch('saved-for-later')" class="badge badge-danger">
                                            <span v-if="searchFrm.filter_by == 'saved-for-later'" class=""><i class="fa fa-check"></i></span>
                                            Saved For Later<strong class="">({{ecan_count['saved-for-later']?? 0}})</strong>
                                        </a> |
                                         

                                        <a href="" @click.prevent="filterByBatch('agreement-not-sent')" class="badge badge-warning">
                                            <span v-if="searchFrm.filter_by == 'agreement-not-sent'" class=""><i class="fa fa-check"></i></span>
                                            Agreement Not Sent<strong class="">({{ecan_count['agreement-not-sent']?? 0}})</strong>
                                        </a>
                                        
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer">
                                <button class="btn btn-primary btn-sm mr-2">Search</button>
                                <button class="btn btn-warning btn-sm" type="button" @click="resetsearch()">Reset</button>
                            </div>
                        </form>
                        
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <button class="btn btn-warning btn-sm" type="button" :disabled="excelExportLoader" @click="exportToExcel()">
                        <i class="fa fa-spinner fa-spin" v-if="excelExportLoader"></i>
                        Export To Excel
                    </button>
                    <div class="card card-primary">
                        <div class="card-header">
                            <h3 class="card-title">
                                List 
                            </h3>
                            <div class="float-right">Total: {{totalItems}}</div>

                        </div>
                        <CSSLoader :showLoader="showLoader" />


                        <div class="table-responsive">
                            <table class="table table-bordered table-striped" v-if="!showLoader">
                                <tr>   	
                                    <th class="text-center">
                                        <input type="checkbox" v-model="selectAllCAN" />
                                    </th>
                                    <th class="text-center">CAN</th>	 
                                    <th class="left-center">Holders Name</th>	
                                    <th class="left-center">Holders Email</th>	 
                                    <th class="text-center">Category</th>
                                    <th class="text-center">Holders PAN</th>
                                    <th class="text-center">Last Modified</th>
                                    <th class="text-center">Registration Status</th>
                                    <th class="text-center">Options</th>
                                </tr>
                                <tr v-if="ecans.length == 0 && !showLoader">
                                    <td colspan="9" class="text-center">
                                        <h4>No eCAN Found</h4>
                                    </td>
                                </tr>
                                
                                <tbody v-for="(o, index) in ecans" :key="o.id" :class="{'odd': (index%2==0), 'even': (index%2!=0)}">
                                     
                                    <tr> 
                                        <td class="text-center">
                                            <input type="checkbox" v-model="o.checked" :disabled="o.can_submitted == 0 || o.can_no == '' || o.agreement_signed == 1" />
                                            <br />
                                            <code>{{(o.agreement_sent == 1 && o.agreement_signed == 0) ? 'Already Sent':''}}</code>
                                        </td>
                                        <td class="text-center">
                                            {{o.can_no}}<br />
                                            <u><code>Consent</code></u><br />
                                            {{o.allow_all_transactions_desc}}
                                        </td>	
                                        <td class="left-center"> 
                                            <div v-for="(oi, oindex) in getAllCANName(o)" :key="oindex">
                                                <span v-if="oindex == 0">{{oi}}</span>
                                                <code v-if="oindex > 0">
                                                    {{o.inv_category == 'M' ? 'Guardian: ': ''}}
                                                    {{oi}}
                                                </code>
                                                
                                            </div>

                                        </td>	
                                        <td class="left-center"> 
                                            <div v-for="(oi, oindex) in getAllCANEmail(o)" :key="oindex">
                                                <span v-if="oindex == 0">{{oi}}</span>
                                                <code v-if="oindex > 0">{{oi}}</code>
                                            </div>
                                        </td>
                                        <td class="text-center">{{o.inv_category_desc}}</td>
                                        <td class="text-center"> 
                                            <div v-for="(oi, oindex) in getAllPan(o)" :key="oindex">
                                                <span v-if="oindex == 0">{{oi}}</span>
                                                <code v-if="oindex > 0">{{oi}}</code>
                                            </div>
                                        </td>
                                        <td class="text-center">{{o.last_modified_time}}</td>
                                        <td class="text-center">
                                            <div class="mb-2">{{o.can_reg_status_display}}</div> 
                                            
                                            <div class="mb-2" v-if="o.agreement_status">**{{o.agreement_status}}</div> 

                                            <!-- <a title="Modity" class="btn btn-primary btn-circle btn-circle-sm m-1" v-if="o.can_submitted == 0 || o.can_status == 0"><i class="fas fa-edit"></i></a> -->

                                            <router-link class="btn btn-primary btn-circle btn-circle-sm m-1" :to="{ name: 'ModifyECan', params: { id: o.id }}" v-if="o.can_submitted == 0 || o.can_status == 0"><i class="fas fa-edit"></i></router-link>

                                            <a title="Send Agreement" class="btn btn-warning btn-circle btn-circle-sm m-1" v-if="o.can_submitted == 1 && o.can_no != '' && o.agreement_signed == 0" @click="sendInvestorApprovalMail(o.id)"><i class="fas fa-envelope"></i></a>
                                            <a title="Download Signed Agreement" target="_blank" :href="o.agreement_link" class="btn btn-danger btn-circle btn-circle-sm m-1" v-if="o.agreement_signed == 1"><i class="fas fa-download"></i></a>
                                            
                                            <button class="btn btn-danger btn-sm m-2"  @click="o.showNomineeUpload = !o.showNomineeUpload" v-if="o.show_nominee_upload"><i class="fas fa-upload"></i> Nominee Upload</button>
                                            
                                        </td>
                                        <td class="text-center">

                                            <div class="mb-2"  v-if="o.can_status == 1 && o.agreement_signed == 1">
                                                <!-- <a title="Smart Investing" class="btn btn-success btn-circle btn-circle-sm m-1">i</a> -->
                                                <router-link class="btn btn-success btn-circle btn-circle-sm m-1" title="Smart Investing SIP/Lumpsum" active-class="active" :to="{ path: '/smart-investing', query: { can: o.can_no }}">i</router-link>
                                                <!-- <a title="Value STP" class="btn btn-primary btn-circle btn-circle-sm m-1">V</a> -->
                                                <router-link class="btn btn-primary btn-circle btn-circle-sm m-1" title="Value STP" active-class="active" :to="{ path: '/value-stp', query: { can: o.can_no }}">V</router-link>
                                                <!-- <a title="Purchase" class="btn btn-info btn-circle btn-circle-sm m-1"><i class="fas fa-cash-register"></i></a> -->
                                                <router-link class="btn btn-info btn-circle btn-circle-sm m-1" title="Purchase" active-class="active" :to="{ path: '/purchase', query: { can: o.can_no }}">
                                                    <i class="fas fa-cash-register"></i>
                                                </router-link>
                                                
                                                <!-- <a title="Redeem" class="btn btn-warning btn-circle btn-circle-sm m-1"><i class="fas fa-gift"></i></a> -->
                                                <router-link class="btn btn-warning btn-circle btn-circle-sm m-1" title="Redeem" active-class="active" :to="{ path: '/redeem', query: { can: o.can_no }}">
                                                    <i class="fas fa-gift"></i>
                                                </router-link>
                                                <!-- <a title="Switch" class="btn btn-danger btn-circle btn-circle-sm m-1"><i class="fas fa-exchange-alt"></i></a> -->
                                                <router-link class="btn btn-danger btn-circle btn-circle-sm m-1" title="Switch" active-class="active" :to="{ path: '/switch', query: { can: o.can_no }}">
                                                    <i class="fas fa-exchange-alt"></i>
                                                </router-link>
                                                <!-- <a title="SIP" class="btn btn-dark btn-circle btn-circle-sm m-1"><i class="far fa-dot-circle"></i></a> -->
                                                <router-link class="btn btn-dark btn-circle btn-circle-sm m-1" title="SIP" active-class="active" :to="{ path: '/sip', query: { can: o.can_no }}">
                                                    <i class="fas fa-dot-circle"></i>
                                                </router-link>
                                                <!-- <a title="STP" class="btn btn-secondary btn-circle btn-circle-sm m-1"><i class="fas fa-coins"></i></a> -->
                                                <router-link class="btn btn-secondary btn-circle btn-circle-sm m-1" title="STP" active-class="active" :to="{ path: '/stp', query: { can: o.can_no }}">
                                                    <i class="fas fa-coins"></i>
                                                </router-link>
                                                <!-- <a title="SWP" class="btn btn-light btn-circle btn-circle-sm m-1"><i class="fas fa-rupee-sign"></i></a> -->
                                                <router-link class="btn btn-light btn-circle btn-circle-sm m-1" title="SWP" active-class="active" :to="{ path: '/swp', query: { can: o.can_no }}">
                                                    <i class="fas fa-rupee-sign"></i>
                                                </router-link>
                                            </div>

                                            <button type="button" class="btn btn-info mb-2" v-if="o.can_submitted == 1 && o.inv_category == 'I'" @click="o.showNomineeVerification = !o.showNomineeVerification">
                                                CAN/Nominee/Contact Verification
                                            </button>
                                                
                                            <div class="btn-group" v-if="o.can_status == 1">
                                                <button type="button" class="btn btn-primary" @click="o.showCanContact = !o.showCanContact">Contact</button>
                                                <button type="button" class="btn btn-secondary" @click="o.showCanBank = !o.showCanBank">Bank</button>
                                                <!-- <a type="button" class="btn btn-warning" href="">PayEezz</a> -->

                                                <router-link class="btn btn-warning" :to="{ name: 'ListEpayeezz', params: { canid: o.id }}">PayEezz</router-link>
                                            </div>

                                            <CanBank v-if="o.showCanBank" :canid="o.id" @closePopup="o.showCanBank = false" :canno="o.can_no" :panno="o.pan_no"/>
                                            <CanContact v-if="o.showCanContact" :canid="o.id"  @reloadCan="search" @closePopup="o.showCanContact = false"  />
                                            
                                            <NomineeVerification v-if="o.showNomineeVerification" :canid="o.id" :canno="o.can_no" @closePopup="o.showNomineeVerification = false"  />
 
                                            <NomineeUpload  v-if="o.showNomineeUpload" :canid="o.id" :canno="o.can_no" @closePopup="o.showNomineeUpload = false" />
                                        </td>	
                                    </tr>
                                    
                                </tbody>
                                <tr>
                                    <td colspan="9">
                                        <button type="button" class="btn btn-info btn-sm" :disabled="totalSelected.length == 0" @click="sendInvestorApprovalMailBulk()">({{totalSelected.length}}) Send Agreement</button>
                                    </td>
                                </tr> 
                                 
                                
                            </table>
                        </div>
                        <div class="card-footer"> 
 

                            <pagination v-model="page" v-bind:records="totalItems" :per-page="perPage" @paginate="pgCallback"/>
                        </div> 
                    </div>
                    <strong>Transaction Approval Consent</strong><br /><br />

                    Allow All - Smart Investing Switches, Smart Investing Purchases, Purchase, SIP, Switch, Redemption, STP, SWP, etc<br />
                    Allow Only Smart Investing - Smart Investing Switches, Smart Investing Purchases<br />
                    Allow Only Normal Purchases - Purchase, SIP, Switch, Redemption, STP, SWP, etc<br />
                    Approval Required for All - Smart Investing Switches, Smart Investing Purchases, Purchase, SIP, Switch, Redemption, STP, SWP, etc
                    <br /><br /><br /><br />
                </div>
                
            </div>
            
        </div>
        
    </section>

    



    
</template>

<script>
import { notify } from '@kyvg/vue3-notification'
import Pagination from 'v-pagination-3'
import EventService from '@/services/EventService.js'
import { ref, reactive, watch, computed } from 'vue'
import { useRouter, useRoute } from 'vue-router'

import CanContact from '@/components/modals/CanContact.vue'
import CanBank from '@/components/modals/CanBank.vue'
import NomineeVerification from '@/components/modals/NomineeVerification.vue'

import NomineeUpload from '@/components/modals/NomineeUpload.vue'

export default {
    name: 'ListECan',
    components: {
        Pagination,
        CanBank,
        CanContact,
        NomineeVerification,
        NomineeUpload
    },
    setup() {
        const router = useRouter()
        const route = useRoute() 
 
        const page = ref(1)
        page.value = Number.parseInt(route.params.page)
        const totalItems = ref(0)
        const perPage = ref(20) 

        const showFullPageLoader = ref(false)
        const selectAllCAN = ref(false)
        
        
        const showLoader = ref(false)
        const searchFrm = reactive({  
            can_no: (route.query.can_no || ''),
            pan_no: (route.query.pan_no || ''),
            holders_name: (route.query.holders_name || ''),
            holders_email: (route.query.holders_email || ''),  
            filter_by: (route.query.filter_by || 'all') 
        })

        const ecan_count = ref({

        })

        const ecans = ref([])

        const excelExportLoader = ref(false)
        function exportToExcel() {
            excelExportLoader.value = true
 
            EventService.getPostData("post", "mfu/exportECanExcel", searchFrm)
            .then(response => {
                console.log(response.data)
                excelExportLoader.value = false  
                    
                if(response.data.success == 1) {
                    window.open(response.data.file_url);
                } else {
                    notify({
                        title: "Error",
                        text: response.data.msg,
                        type: 'error'
                    });
                }
            })
            .catch(error => { 
                excelExportLoader.value = false
                console.log("This iwas an error!", error)

                notify({
                    title: "Error",
                    text: error,
                    type: 'error'
                });
            })


        }

        function submitSearchForm(pgno) { 
            router.push({ name: 'ListECan', params: { page: (pgno || 1) }, query: searchFrm })            
        }
 
        function search() { 
            let dataToSend = Object.assign(searchFrm, {page: page.value})
            
            showLoader.value = true
            EventService.getPostData("post", "mfu/listECAN", dataToSend)
            .then(response => {
                console.log(response.data)
                showLoader.value = false
                ecans.value = response.data.data
                totalItems.value = response.data.totalItems 

                ecan_count.value = response.data.ecan_count
            })
            .catch(error => { 
                showLoader.value = false
                console.log("This iwas an error!", error)
            })
        }

        function resetsearch() {
            searchFrm.can_no = ''
            searchFrm.pan_no = ''
            searchFrm.holders_name = ''
            searchFrm.holders_email = ''  
            searchFrm.filter_by = '' 
 
            submitSearchForm(1)
        }

        function pgCallback() {
            // console.log("Inside callback ===" + page.value)

            submitSearchForm(page.value)
        }

        function getAllCANName({ primary_holders_name, second_holders_name, third_holders_name }) {
            let name = [primary_holders_name, second_holders_name, third_holders_name] 
 
            var filtered = name.filter(function (el) {
                return el != null && el != '';
            });

             return ([...filtered]);
        }

        function getAllCANEmail({ primary_holders_email, second_holders_email, third_holders_email }) {
            let name = [primary_holders_email, second_holders_email, third_holders_email] 
 
            var filtered = name.filter(function (el) {
                return el != null && el != '';
            });

             return ([...filtered]);
        }

        function getAllPan({ pan_no, pan_no_2, pan_no_3 }) {
            let pan = [pan_no, pan_no_2, pan_no_3] 
 
            var filtered = pan.filter(function (el) {
                return el != null && el != '';
            });

             return ([...filtered]);
        }

        function filterByBatch(opt) {
            router.push({ name: 'ListECan', params: { page: 1 }, query: {filter_by: opt} })
        }

        search()

        function sendInvestorApprovalMail(id) { 
            showFullPageLoader.value = true

            EventService.getPostData("post", "mfu/sendInvestorApprovalMail", {id: id})
            .then(response => {
                console.log(response.data)
                showFullPageLoader.value = false  
                    
                if(response.data.success == 1) {
                    notify({
                        title: "Success",
                        text: response.data.msg,
                        type: 'success'
                    });
                } else {
                    notify({
                        title: "Error",
                        text: response.data.msg,
                        type: 'error'
                    });
                }
            })
            .catch(error => { 
                showFullPageLoader.value = false
                console.log("This iwas an error!", error)

                notify({
                    title: "Error",
                    text: error,
                    type: 'error'
                });
            })

        }

        watch(selectAllCAN, (currentValue, oldValue) => {
            // console.log(currentValue);
            // console.log(oldValue); 
            ecans.value.forEach(function(item, key){ 

                if(item.can_submitted == 0 || item.can_no == '' || item.agreement_signed == 1) {

                } else {
                    item.checked = currentValue
                }

                
            })
        });

        const totalSelected = computed(() => {
            return ecans.value.filter((item) => {
                return item.checked
            }).map((item) => {
                return item.id
            })
        })

        function sendInvestorApprovalMailBulk() {
 
            showFullPageLoader.value = true

            EventService.getPostData("post", "mfu/sendInvestorApprovalMailBulk", {ids: totalSelected.value})
            .then(response => {
                console.log(response.data)
                showFullPageLoader.value = false  
                    
                if(response.data.success == 1) {
                    notify({
                        title: "Success",
                        text: response.data.msg,
                        type: 'success'
                    });
                } else {
                    notify({
                        title: "Error",
                        text: response.data.msg,
                        type: 'error'
                    });
                }
            })
            .catch(error => { 
                showFullPageLoader.value = false
                console.log("This iwas an error!", error)

                notify({
                    title: "Error",
                    text: error,
                    type: 'error'
                });
            })
        }


 
        return {  
            searchFrm, page, perPage, totalItems, showLoader, ecans, search,
            pgCallback, resetsearch, submitSearchForm, getAllCANName, getAllCANEmail, getAllPan, filterByBatch, 
            ecan_count, excelExportLoader, exportToExcel, sendInvestorApprovalMail, sendInvestorApprovalMailBulk, showFullPageLoader,
            selectAllCAN, totalSelected
             
        }
    }
}
</script>

<style>
.btn-circle {
  width: 45px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  padding: 0;
  border-radius: 50%;
}

.btn-circle i {
  position: relative;
  top: -1px;
}

.btn-circle-xs {
  width: 25px;
  height: 25px;
  line-height: 25px; 
}

.btn-circle-xs i{ 
  font-size: 10px !important;
}

.btn-circle-sm {
  width: 35px;
  height: 35px;
  line-height: 35px;
  font-size: 0.9rem;
}

.btn-circle-lg {
  width: 55px;
  height: 55px;
  line-height: 55px;
  font-size: 1.1rem;
}

.btn-circle-xl {
  width: 70px;
  height: 70px;
  line-height: 70px;
  font-size: 1.3rem;
}



</style>