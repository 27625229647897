<template>
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0">{{pagetitle}}</h1>
          </div><!-- /.col -->
          <div class="col-sm-6"> 
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <section class="content text-sm">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                <!-- general form elements -->
                    <div class="card card-primary">
                        <div class="card-header">
                            <h3 class="card-title">Search</h3>
                        </div>
                        <form method="post" @submit.prevent="submitSearchForm()">
                            <div class="card-body">
                                <div class="row">
                                    
                                    <div class="col-md-2">
                                        <div class="form-group">
                                            <label for="">FOLIO NO.</label>
                                            <input type="text" class="form-control form-control-sm" v-model="searchFrm.folio_no">
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="form-group">
                                            <label for="">CAN NO.</label>
                                            <input type="text" class="form-control form-control-sm" v-model="searchFrm.can_no">
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="form-group">
                                            <label for="">ANY HOLDER'S PAN</label>
                                            <input type="text" class="form-control form-control-sm" v-model="searchFrm.pan_no">
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="form-group">
                                            <label for="">ANY HOLDER'S NAME</label>
                                            <input type="text" class="form-control form-control-sm" v-model="searchFrm.primary_holders_name">
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="form-group">
                                            <label for="">ANY HOLDER'S EMAIL</label>
                                            <input type="text" class="form-control form-control-sm" v-model="searchFrm.primary_holders_email">
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="form-group">
                                            <label for="">SI END DATE</label>
                                            <input type="date" class="form-control form-control-sm" v-model="searchFrm.si_end_date">
                                        </div>
                                    </div>
                                     
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <a href="" @click.prevent="filterByBatch('ALL')" class="badge badge-info">
                                            <span v-if="searchFrm.filter_by == 'ALL'" class=""><i class="fa fa-check"></i></span>
                                            All Data <strong class="">({{si_count['all']}})</strong>
                                        </a> | 
                                        <!-- <a href="" @click.prevent="filterByBatch('active')" class="badge badge-primary">
                                            <span v-if="searchFrm.filter_by == 'active'" class=""><i class="fa fa-check"></i></span>
                                            Active <strong class="">({{si_count['active']}})</strong>
                                        </a> |  -->
                                        <a href="" @click.prevent="filterByBatch('otp-approved')" class="badge badge-warning">
                                            <span v-if="searchFrm.filter_by == 'otp-approved'" class=""><i class="fa fa-check"></i></span>
                                            Active OTP Approved <strong class="">({{si_count['otp-approved']}})</strong>
                                        </a> | 
                                        <a href="" @click.prevent="filterByBatch('otp-pending')" class="badge badge-danger">
                                            <span v-if="searchFrm.filter_by == 'otp-pending'" class=""><i class="fa fa-check"></i></span>
                                            Active OTP Pending <strong class="">({{si_count['otp-pending']}})</strong>
                                        </a> | 
                                        <a href="" @click.prevent="filterByBatch('paused')" class="badge badge-success">
                                            <span v-if="searchFrm.filter_by == 'paused'" class=""><i class="fa fa-check"></i></span>
                                            Paused <strong class="">({{si_count['paused']}})</strong>
                                        </a> | 
                                        <a href="" @click.prevent="filterByBatch('paused-by-admin')" class="badge badge-success">
                                            <span v-if="searchFrm.filter_by == 'paused-by-admin'" class=""><i class="fa fa-check"></i></span>
                                            Paused By Admin <strong class="">({{si_count['paused-by-admin']}})</strong>
                                        </a> | 
                                        <a href="" @click.prevent="filterByBatch('cancelled')" class="badge badge-warning">
                                            <span v-if="searchFrm.filter_by == 'cancelled'" class=""><i class="fa fa-check"></i></span>
                                            Cancelled<strong class="">({{si_count['cancelled']}})</strong>
                                        </a> |
                                        <a href="" @click.prevent="filterByBatch('expired')" class="badge badge-danger">
                                            <span v-if="searchFrm.filter_by == 'expired'" class=""><i class="fa fa-check"></i></span>
                                            Expired<strong class="">({{si_count['expired']}})</strong>
                                        </a> |
                                        
                                        <span v-if="sitype == 'si'">
                                            <!-- Show only for smart investing -->
                                            <a href="" @click.prevent="filterByBatch('sip')" class="badge badge-info">
                                                <span v-if="searchFrm.filter_by == 'sip'" class=""><i class="fa fa-check"></i></span>
                                                SIP<strong class="">({{si_count['sip']}})</strong>
                                            </a> |
                                            
                                            <a href="" @click.prevent="filterByBatch('lumpsum')" class="badge badge-success">
                                                <span v-if="searchFrm.filter_by == 'lumpsum'" class=""><i class="fa fa-check"></i></span>
                                                Lumpsum<strong class="">({{si_count['lumpsum']}})</strong>
                                            </a> |
                                        </span>

                                        <a href="" @click.prevent="filterByBatch('approval-awaited')" class="badge badge-warning">
                                            <span v-if="searchFrm.filter_by == 'approval-awaited'" class=""><i class="fa fa-check"></i></span>
                                            Approval Awaited<strong class="">({{si_count['approval-awaited']}})</strong>
                                        </a>
                                        
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer">
                                <button class="btn btn-primary btn-sm mr-2">Search</button>
                                <button class="btn btn-warning btn-sm" type="button" @click="resetsearch()">Reset</button>
                            </div>
                        </form>
                        
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <button class="btn btn-warning btn-sm" type="button" :disabled="excelExportLoader" @click="exportToExcel()">
                        <i class="fa fa-spinner fa-spin" v-if="excelExportLoader"></i>
                        Export To Excel
                    </button>
                    <div class="card card-primary">
                        <div class="card-header">
                            <h3 class="card-title">
                                List 
                            </h3>
                            <div class="float-right">Total: {{totalItems}}</div>

                        </div>
                        <CSSLoader :showLoader="showLoader" />


                        <div class="table-responsive">
                            <table class="table table-bordered table-striped" v-if="!showLoader">
                                <tr>   	
                                    <th class="text-center">Folio No.</th>	
                                    <th class="text-center" v-if="sitype == 'vstp'">Core Scheme</th>
                                    <th class="text-center">
                                        {{sitype == 'vstp' ? 'STP Into Scheme':'SIP/Lumpsum Into Scheme'}}
                                    </th>	
                                    <th class="text-center">Smart Investing Switch Into Scheme</th>	
                                    <th class="text-center">Amount / Units</th>	
                                    <th class="text-center" v-if="sitype == 'si'">SIP Detail</th>
                                    <th class="text-center">Last Order Status</th>
                                    <th class="text-center">Options</th>
                                </tr>
                                <tr v-if="siorders.length == 0 && !showLoader">
                                    <td colspan="7" class="text-center">
                                        <h4 v-if="sitype=='vstp'">No Value STP Found</h4>
                                        <h4 v-else>No Smart Investing Found</h4>
                                    </td>
                                </tr>
                                
                                <tbody v-for="(o, index) in siorders" :key="o.auto_trigger_id" :class="{'odd': (index%2==0), 'even': (index%2!=0)}">
                                    <tr>
                                        <td class="text-left" colspan="7">

                                            Mode: {{o.investment_mode}}, 
                                            Type: <code>{{o.investment_type}}<span v-if="o.investment_type == 'SIP'">/{{ o.sip_installment_date }}/{{ o.sip_frequency }}<span v-if="o.initial_sip_amount > 0">/{{ o.initial_sip_amount }}</span><span v-else>/NA</span></span></code>, 
                                            CAN: {{o.can}}, 
                                            CAN Name: {{getAllCAN(o)}}, PAN: {{getAllPan(o)}}, 
                                            TS: {{o.ts}}, EUIN Code: {{o.euin_code}}, 
                                            <span v-if="o.si_end_date_disp">End Date: {{o.si_end_date_disp}} , </span>
                                            Status: {{o.current_status}},
                                            OTP Approval: {{ o.is_otp_approved == 1 ? 'Approved':'Pending' }}
 
                                        </td>
                                    </tr>
                                    <tr> 
                                        <td class="text-center">{{o.folio_no}}</td>	
                                        <td class="text-center" v-if="sitype == 'vstp'">{{o.equity_income_scheme_name}}</td>
                                        <td class="text-center">{{o.equity_scheme_name}}</td>	
                                        <td class="text-center">{{o.dynamic_asset_allocation_scheme_name}}</td>	
                                        <td class="text-center">
                                            {{sitype == 'vstp' ? o.monthly_stp_amount_disp : o.initial_amount_disp}}
                                        </td>	
                                        <td class="text-center" v-if="sitype == 'si'">
                                            <div v-if="o.investment_type == 'SIP'"> 
                                                Frequency: {{o.sip_frequency}}<br />
                                                Start Month/Year: {{o.sip_start_month}}/{{o.sip_start_year}}<br />
                                                Installment Date: {{o.sip_installment_date}}<br />
                                                <code v-if="o.initial_sip_amount">Initial Amount: {{o.initial_sip_amount}}</code>
                                            </div>
                                            
                                        </td>	
                                        <td class="text-center"> 
                                            <span v-html="o.last_order_status"></span>
                                        </td>
                                        <td class="text-center">
                                            <router-link v-if="sitype=='vstp'" :to="{ name: 'ValueStpModify', params: { id: o.auto_trigger_id }, query: {can: o.can}}" class="mr-2">
                                                <i class="fas fa-edit"></i> 
                                            </router-link> 
                                            <router-link v-else :to="{ name: 'SmartInvestingModify', params: { id: o.auto_trigger_id }, query: {can: o.can}}" class="mr-2">
                                                <i class="fas fa-edit"></i> 
                                            </router-link> 
                                             
                                            <a href="#" @click.prevent="o.showTriggersLog = true"><i class="fa fa-calendar" aria-hidden="true"></i></a>

                                            <div class="mt-3">
                                                <router-link v-if="sitype=='vstp'" :to="{ name: 'ValueStp', query: {can: o.can, dupid: o.auto_trigger_id}}" class="btn btn-warning btn-sm text-small">
                                                    Duplicate Smart Investing
                                                </router-link> 
                                                <router-link v-else :to="{ name: 'SmartInvesting', query: {can: o.can, dupid: o.auto_trigger_id}}" class="btn btn-warning btn-sm text-small">
                                                    Duplicate Smart Investing 
                                                </router-link> 
                                            </div> 
       
                                            <SiTriggersLog v-if="o.showTriggersLog" :auto_trigger_id="o.auto_trigger_id" @closePopup="o.showTriggersLog = false" :sitype="sitype"></SiTriggersLog>
                                        </td> 
                                    </tr>
                                </tbody>
                                 
                                 
                                
                            </table>
                        </div>
                        <div class="card-footer"> 
                            <pagination v-model="page" v-bind:records="totalItems" :per-page="perPage" @paginate="pgCallback"/>
                        </div> 
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
 
<script>
import { notify } from '@kyvg/vue3-notification'
import Pagination from 'v-pagination-3'
import EventService from '@/services/EventService.js'
import { ref, reactive } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import SiTriggersLog from '@/components/modals/SiTriggersLog.vue';
export default {
    name: 'ListSmartInvesting',
    components: {
        Pagination,
        SiTriggersLog
    },
    props: ["sitype", "pagetitle"],
    setup(props) {
        const router = useRouter()
        const route = useRoute() 

        const pagetitle = ref('')
        pagetitle.value = props.pagetitle ? props.pagetitle: 'Manage Smart Investing'

        const sitype = ref('')
        sitype.value = props.sitype ? props.sitype: 'si'

        const page = ref(route.params.page)
        const totalItems = ref(0)
        const perPage = ref(5)
        
        
        const showLoader = ref(false)
        const searchFrm = reactive({
            sitype: sitype.value,
            folio_no: (route.query.folio_no || ''),
            can_no: (route.query.can_no || ''),
            pan_no: (route.query.pan_no || ''),
            primary_holders_name: (route.query.primary_holders_name || ''),
            primary_holders_email: (route.query.primary_holders_email || ''), 
            si_end_date: (route.query.si_end_date || ''),
            filter_by: (route.query.filter_by || 'otp-approved') 
        })

        const si_count = ref({

        })

        const siorders = ref([])

        const excelExportLoader = ref(false)
        function exportToExcel() {
            excelExportLoader.value = true
 
            EventService.getPostData("post", "mfu/exportSmartInvesting", searchFrm)
            .then(response => {
                console.log(response.data)
                excelExportLoader.value = false  
                    
                if(response.data.success == 1) {
                    window.open(response.data.file_url);
                } else {
                    notify({
                        title: "Error",
                        text: response.data.msg,
                        type: 'error'
                    });
                }
            })
            .catch(error => { 
                excelExportLoader.value = false
                console.log("This iwas an error!", error)

                notify({
                    title: "Error",
                    text: error,
                    type: 'error'
                });
            })


        }

        function submitSearchForm(pgno) {
            if(sitype.value == 'si') {
                router.push({ name: 'ListSmartInvesting', params: { page: (pgno || 1) }, query: searchFrm })
            } else {
                router.push({ name: 'ListValueStp', params: { page: (pgno || 1) }, query: searchFrm })
            }
            
        }



        function search() { 
            let dataToSend = Object.assign(searchFrm, {page: page.value})
            
            showLoader.value = true
            EventService.getPostData("post", "mfu/listSmartInvesting", dataToSend)
            .then(response => {
                console.log(response.data)
                showLoader.value = false
                siorders.value = response.data.data
                totalItems.value = response.data.totalItems 

                si_count.value = response.data.si_count
            })
            .catch(error => { 
                showLoader.value = false
                console.log("This iwas an error!", error)
            })
        }

        function resetsearch() {
            searchFrm.can_no = ''
            searchFrm.pan_no = ''
            searchFrm.primary_holders_name = ''
            searchFrm.primary_holders_email = '' 
            searchFrm.folio_no = '' 
            searchFrm.si_end_date = ''
            searchFrm.filter_by = '' 
 
            submitSearchForm(1)
        }

        function pgCallback() {
            // console.log("Inside callback ===" + page.value)

            submitSearchForm(page.value)
        }

        function getAllCAN({ primary_holders_name, second_holders_name, third_holders_name }) {
            let name = [primary_holders_name, second_holders_name, third_holders_name] 
 
            var filtered = name.filter(function (el) {
                return el != null && el != '';
            });

             return ([...filtered]).join(", ");
        }

        function getAllPan({ pan_no, pan_no_2, pan_no_3 }) {
            let pan = [pan_no, pan_no_2, pan_no_3] 
 
            var filtered = pan.filter(function (el) {
                return el != null && el != '';
            });

             return ([...filtered]).join(", ");
        }

        function filterByBatch(opt) {
            // router.push({ name: 'ListSmartInvesting', params: { page: 1 }, query: {filter_by: opt} })

            if(sitype.value == 'si') {
                router.push({ name: 'ListSmartInvesting', params: { page: 1 }, query: {filter_by: opt} })
            } else {
                router.push({ name: 'ListValueStp', params: { page: 1 }, query: {filter_by: opt} }) 
            }
        }

        search()

        return { 
            // search, resetsearch, pgCallback, submitSearchForm,
            // showLoader, page, perPage, totalItems 
            pagetitle,
            searchFrm, page, perPage, totalItems, showLoader, siorders, 
            pgCallback, resetsearch, submitSearchForm, getAllCAN, getAllPan, filterByBatch, si_count, excelExportLoader, exportToExcel
        }
    }
}
</script>

<style scoped>
.text-small {
    padding: 2px 5px 2px 5px;
    font-size: 10px !important;
    line-height: normal;
    font-weight: bold;
    width: 100%;
    color: #ffffff;
}
</style>