<template>
    <footer class="main-footer">
        <strong>Copyright &copy; {{copyrightYear}}.</strong>
        All rights reserved.
        <div class="float-right d-none d-sm-inline-block">
            <code>ARN: {{registration_arn}}, EUIN: {{euin_code}}</code>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'Footer',
    props: ['registration_arn', "euin_code"],
    data() {
        return {
            copyrightYear: (new Date()).getFullYear()
        }
    }
}
</script>