<template>
  <!-- Navbar -->
  <nav class="main-header navbar navbar-expand navbar-dark">
    <!-- Left navbar links -->
    <ul class="navbar-nav">
      <li class="nav-item">
        <a
          class="nav-link"
          data-widget="pushmenu"
          href="#"
          role="button"
          @click.prevent="showHideSidebar()"
          ><i class="fas fa-bars"></i
        ></a>
      </li>
      <li class="nav-item d-none d-sm-inline-block">
        <a href="#" @click.prevent="" class="nav-link">
          {{isdesktop ? 'Your Computer Clock:': ''}} {{ current_time }}
        </a>
      </li>
    </ul>

    <!-- Right navbar links -->
    <!-- Right navbar links -->
    <ul class="navbar-nav ml-auto">
      <!-- Navbar Search -->

      <li class="nav-item">
        <a
          class="nav-link"
          data-widget="control-sidebar"
          data-slide="true"
          href="#"
          role="button"
        >
          <code style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">{{ userDetail.name }}</code>
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          data-widget="control-sidebar"
          data-slide="true"
          href="#"
          role="button"
          @click.prevent="logout()"
        >
          <i class="fas fa-sign-out-alt"></i>
        </a>
      </li>
    </ul>
  </nav>
  <!-- /.navbar -->

  <!-- Main Sidebar Container -->
  <aside class="main-sidebar sidebar-dark-primary elevation-4">
    <!-- Brand Logo -->
    <a href="" class="brand-link" @click.prevent="">
      <img
        src="../assets/img/favicon.png"
        alt=""
        class="brand-image elevation-3"
      />
      <span class="brand-text font-weight-light">MFU</span>
    </a>

    <!-- Sidebar -->
    <div class="sidebar">
      <!-- Sidebar user panel (optional) -->
      <!-- <div class="user-panel mt-3 pb-3 mb-3 d-flex">
        
        <div class="info" style="white-space: pre-wrap;">
            <code>
              <strong>ARN:</strong> {{userDetail.registration_arn}}<br /><strong>EUIN:</strong> {{userDetail.euin_code}}<br />
            </code>
        </div>
        
      </div> -->

      <!-- Sidebar Menu -->
      <nav class="mt-2">
        <ul
          class="nav nav-pills nav-sidebar flex-column nav-flat nav-child-indent"
          data-widget="treeview"
          role="menu"
          data-accordion="false"
        >
          <!-- Add icons to the links using the .nav-icon class
               with font-awesome or any other icon font library -->
          <li class="nav-item menu-open">
            <a href="#" class="nav-link">
              <i class="nav-icon fas fa-tachometer-alt"></i>
              <p>
                Dashboard
                <!-- <i class="right fas fa-angle-left"></i> -->
              </p>
            </a>
          </li>
          <li class="nav-item menu-open">
            <router-link class="nav-link" to="/list-unprocessed-transactions">
                <i class="nav-icon far fa-calendar"></i>
                <p>Transaction Trigger </p>
            </router-link>
            
          </li>
          <!-- <li class="nav-item">
            <a href="pages/calendar.html" class="nav-link active">
              <i class="nav-icon fas fa-calendar-alt"></i>
              <p>Transaction Trigger</p>
            </a>
          </li> -->

          <li class="nav-item" :class="{ 'menu-is-opening menu-open': canOpt }">
            <a href="#" class="nav-link" @click.prevent="canOpt = !canOpt">
              <i class="nav-icon fa fa-users"></i>
              <p>
                CAN
                <i class="fas fa-angle-left right"></i>
              </p>
            </a>
            <ul class="nav nav-treeview" :style="{ 'display: block': canOpt }">
              <li class="nav-item"> 
                <router-link class="nav-link" active-class="active" to="/add-ecan">
                    <i class="far fa-circle nav-icon"></i>
                    <p>Open CAN</p>
                </router-link>
              </li>
              <li class="nav-item"> 
                <router-link class="nav-link" active-class="active" to="/list-ecan/1">
                    <i class="far fa-circle nav-icon"></i>
                    <p>Manage CAN</p>
                </router-link>


              </li>
            </ul>
          </li>
          <li class="nav-item" :class="{ 'menu-is-opening menu-open': siOpt }">
            <a href="#" class="nav-link" @click.prevent="siOpt = !siOpt">
              <i class="nav-icon fas fa-chart-pie"></i>
              <p>
                Smart Investing
                <i class="right fas fa-angle-left"></i>
              </p>
            </a>
            <ul class="nav nav-treeview" :style="{ 'display: block': siOpt }">
              <li class="nav-item">
                <!-- <a href="pages/charts/chartjs.html" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>SIP/Lumpsum</p>
                </a> -->
                <router-link class="nav-link" active-class="active" to="/smart-investing">
                    <i class="far fa-circle nav-icon"></i>
                    <p>SIP/Lumpsum</p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" active-class="active" to="/list-smart-investing/1">
                    <i class="far fa-circle nav-icon"></i>
                    <p>Manage SIP/Lumpsum</p>
                </router-link>
                 
              </li>
            </ul>
          </li>
          <li
            class="nav-item"
            :class="{ 'menu-is-opening menu-open': vstpOpt }"
          >
            <a href="#" class="nav-link" @click.prevent="vstpOpt = !vstpOpt">
              <i class="nav-icon fas fa-chart-pie"></i>
              <p>
                Value STP
                <i class="fas fa-angle-left right"></i>
              </p>
            </a>
            <ul class="nav nav-treeview" :style="{ 'display: block': vstpOpt }">
              <li class="nav-item">
                <router-link class="nav-link" active-class="active" to="/value-stp">
                    <i class="far fa-circle nav-icon"></i>
                    <p>Value STP</p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" active-class="active" to="/list-value-stp/1">
                    <i class="far fa-circle nav-icon"></i>
                    <p>Manage Value STP</p>
                </router-link>
              </li>
            </ul>
          </li>

          <li
            class="nav-item menu-open menu-is-opening"
             
          >
            <a href="#" class="nav-link" @click.prevent="" style="background-color: maroon;">
              <i class="nav-icon fas fa-gavel"></i>
              <p> 
                Smart Investing Approvals
                <i class="fas fa-angle-left right"></i>
              </p>
            </a>
            <ul class="nav nav-treeview" >
              <li class="nav-item">
                <router-link class="nav-link" to="/smart-investing-approvals/1" style="background-color: rgb(192, 60, 60);">
                    <i class="far fa-circle nav-icon"></i>
                    <p>Without Initial Purchase</p>
                </router-link>
                
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/smart-investing-approvals-with-purchase/1" style="background-color: rgb(192, 60, 60);">
                    <i class="far fa-circle nav-icon"></i>
                    <p>With Initial Purchase</p>
                </router-link>
                
              </li>
            </ul>
          </li>



           
          <li
            class="nav-item"
            :class="{ 'menu-is-opening menu-open': normalTxnOpt }"
          >
            <a
              href="#"
              class="nav-link"
              @click.prevent="normalTxnOpt = !normalTxnOpt"
            >
              <i class="nav-icon fas fa-chart-pie"></i>
              <p>
                Normal Transactions
                <i class="fas fa-angle-left right"></i>
              </p>
            </a>
            <ul class="nav nav-treeview" :style="{ 'display: block': vstpOpt }">
              <li class="nav-item">
                <router-link class="nav-link" active-class="active" to="/purchase">
                    <i class="far fa-circle nav-icon"></i>
                    <p>Purchase</p>
                </router-link>
 
              </li>
              <li class="nav-item">
                <router-link class="nav-link" active-class="active" to="/redeem">
                    <i class="far fa-circle nav-icon"></i>
                    <p>Redeem</p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" active-class="active" to="/switch">
                    <i class="far fa-circle nav-icon"></i>
                    <p>Switch</p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" active-class="active" to="/sip">
                    <i class="far fa-circle nav-icon"></i>
                    <p>SIP</p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" active-class="active" to="/stp">
                    <i class="far fa-circle nav-icon"></i>
                    <p>STP</p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" active-class="active" to="/swp">
                    <i class="far fa-circle nav-icon"></i>
                    <p>SWP</p>
                </router-link>
              </li>
            </ul>
          </li>
          <!-- <li
            class="nav-item"
            :class="{ 'menu-is-opening menu-open': bulkOpt }"
          >
            <a href="#" class="nav-link" @click.prevent="bulkOpt = !bulkOpt">
              <i class="nav-icon fas fa-columns"></i>
              <p>
                Bulk Options
                <i class="fas fa-angle-left right"></i>
              </p>
            </a>
            <ul class="nav nav-treeview" :style="{ 'display: block': bulkOpt }">
              <li class="nav-item">
                <a href="pages/tables/simple.html" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Upload File</p>
                </a>
              </li>
              <li class="nav-item">
                <a href="pages/tables/data.html" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Manage Users</p>
                </a>
              </li>
              <li class="nav-item">
                <a href="pages/tables/jsgrid.html" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>SI Switch</p>
                </a>
              </li>
            </ul>
          </li> -->
          <li
            class="nav-item"
            :class="{ 'menu-is-opening menu-open': txnStatusOpt }"
          >
            <a
              href="#"
              class="nav-link"
              @click.prevent="txnStatusOpt = !txnStatusOpt"
            >
              <i class="nav-icon fas fa-exchange-alt"></i>
              <p>
                Txn. Status
                <i class="fas fa-angle-left right"></i>
              </p>
            </a>
            <ul
              class="nav nav-treeview"
              :style="{ 'display: block': txnStatusOpt }"
            >
              <li class="nav-item">
                <router-link class="nav-link" active-class="active" to="/orders/1">
                    <i class="far fa-circle nav-icon"></i>
                    <p>Orders</p>
                </router-link>
              </li>

              <li class="nav-item">
                <router-link class="nav-link" active-class="active" to="/list-payeezz-reg/1">
                    <i class="far fa-circle nav-icon"></i>
                    <p>Payeezz</p>
                </router-link>
              </li>
              
              <!-- <router-link  tag="li" to="/list-payeezz-reg/1">
                <span class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Payeezz</p>
                </span>
              </router-link> -->
              
               
            </ul>
          </li>

          <!-- <li class="nav-item">
            <a href="pages/gallery.html" class="nav-link">
              <i class="nav-icon fa fa-ban"></i>
              <p>MFU Sys. Cancellation</p>
            </a>
          </li> -->
          <li
            class="nav-item"
            :class="{ 'menu-is-opening menu-open': subuserOpt }"
          >
            <a
              href="#"
              class="nav-link"
              @click.prevent="subuserOpt = !subuserOpt"
            >
              <i class="nav-icon fas fa-columns"></i>
              <p>
                Manage Sub Users
                <i class="fas fa-angle-left right"></i>
              </p>
            </a>
            <ul
              class="nav nav-treeview"
              :style="{ 'display: block': subuserOpt }"
            >
              <li class="nav-item">
                <router-link  class="nav-link" active-class="active" to="/addSubUser">
                
                    <i class="far fa-circle nav-icon"></i>
                    <p>Add Sub User</p>
                   
                </router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" active-class="active" to="/manageSubUsers/1">
                 
                    <i class="far fa-circle nav-icon"></i>
                    <p>Manage Sub Users</p>
                   
                </router-link>
              </li>
            </ul>
          </li>
          <li class="nav-item" :class="{ 'menu-is-opening menu-open': bulkOpt }">
            <a
              href="#"
              class="nav-link"
              @click.prevent="bulkOpt = !bulkOpt"
            >
              <i class="nav-icon fas fa-upload"></i> 
              <p>
                Bulk Options
                <i class="fas fa-angle-left right"></i>
              </p>
            </a>
            <ul class="nav nav-treeview" :style="{ 'display: block': bulkOpt }">
              <li class="nav-item">
                <router-link class="nav-link" active-class="active" to="/batch-upload">
                 
                    <i class="far fa-circle nav-icon"></i>
                    <p>CAN - Batch Upload</p>
                   
                </router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" active-class="active" to="/list-ecan-batch/1">
                 
                    <i class="far fa-circle nav-icon"></i>
                    <p>eCANs Batch</p>
                   
                </router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" active-class="active" to="/convert-vstp-to-normal-stp">
                 
                    <i class="far fa-circle nav-icon"></i>
                    <p>Convert VSTP to Normal STP</p>
                   
                </router-link>
              </li>

            </ul>
 
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/systematic-cancellation">
                <i class="nav-icon fas fa-ban"></i>
                <p>MFU Systematic Cancellation</p>
            </router-link>
            
          </li> 
           
          <li class="nav-item"><a href="https://www.mfuonline.com/onlineMfuPage?reqPageType=eEntity" class="nav-link" target="_blank"><i class="fas fa-link nav-icon"></i> <p>Register with MFU</p></a></li>
            <li class="nav-item"><a href="https://www.mfuonline.com/onlineCencom?reqComplFlag=C" class="nav-link" target="_blank"><i class="fas fa-link nav-icon"></i> <p>Complaint with MFU</p></a></li>
            <li class="nav-item"><a href="https://www.mfuindia.com/EventCalendar" class="nav-link" target="_blank"><i class="fas fa-link nav-icon"></i> <p>Register for MFU Webinar</p></a></li>
        </ul>
      </nav>
      <!-- /.sidebar-menu -->
    </div>
    <!-- /.sidebar -->
  </aside>
  <div id="sidebar-overlay2" v-if="sidebar && !isdesktop" @click="showHideSidebar()"></div>
</template>

<script>
import moment from "moment";
export default {
  name: "LeftMenu",
  props: ["sidebar", "userDetail","isdesktop"],
  emits: ["updateSidebar", "logout"],
  data() {
    return {
      canOpt: false,
      siOpt: false,
      vstpOpt: false,
      normalTxnOpt: false,
      bulkOpt: false,
      txnStatusOpt: false,
      subuserOpt: false,
      bulkOpt: false,
      current_time: null,
    };
  },
  methods: {
    showHideSidebar() {
      if(this.isdesktop) {
        return false
      }
      this.$emit("updateSidebar");
      this.sidebar != this.sidebar;
    },
    logout() {
      this.$emit("logout");
    },
    currtime() {
      setInterval(() => {
        // console.log(moment().format("MM ddd, YYYY HH:mm:ss a"));
        this.current_time = moment().format("HH:mm:ss a");
      }, 1000);
    },
  },
  created() {
    this.currtime();
  },
};
</script>

<style scoped>
#sidebar-overlay2 {
    background-color: rgba(0,0,0,.1);
    bottom: 0;
    display: block;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1037;
}
</style>